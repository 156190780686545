const revokePageStyle = (theme) => ({
  headline: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: "2rem",
  },
  container: {
    textAlign: "center",
    padding: "3rem 1rem",
    [theme.breakpoints.up("sm")]: {
      padding: "3rem",
    },
  },
  checkboxText: {
    textAlign: "left",
  },
});

export default revokePageStyle;

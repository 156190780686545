import React from "react";
import { FormControl, FormLabel } from "@mui/material";
import formLegendStyle from "./formLegendStyle";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(formLegendStyle);
export default function FormLegend({children, ...rest}) {
  const classes = useStyles();
  return (
    <FormControl className={classes.fieldset} component="fieldset" {...rest}>
      <FormLabel className={classes.label} component="legend">{children}</FormLabel>
    </FormControl>
  );
}
FormLegend.defaultProps = {
  margin: "normal",
}
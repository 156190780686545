import React from "react";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import ContentBox from "../../Box/ContentBox/ContentBox";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import iconAssistent from "../../../assets/icons/serviceOverview/icon_assistant_150px.png";
import callIcon from "../../../assets/img/generic/call_clock_100px.png";

type InquiryToCustomerCaseBoxProps = {
  product: AbstractCase;
  inquiryToCustomer: InquiryToCustomer;
};

export default function InquiryToCustomerCaseBox({ product, inquiryToCustomer }: InquiryToCustomerCaseBoxProps) {
  if (!inquiryToCustomer) {
    return null;
  }

  const isAppointmentInquiry = inquiryToCustomer.inquiryType === "appointment";

  const headline = isAppointmentInquiry ? "Termin vereinbaren" : "Rückmeldung geben";
  const icon = isAppointmentInquiry ? callIcon : iconAssistent;
  const text = isAppointmentInquiry
    ? "In Ihrem Fall ist eine telefonische Rücksprache notwendig. Bitte vereinbaren Sie zeitnah einen Beratungstermin."
    : "Bitte geben Sie zeitnah Rückmeldung zur offenen Anfrage an Sie, damit Ihr Fall bearbeitet werden kann.";
  const buttonText = headline;

  return (
    <ContentBox headline={headline} icon={icon}>
      <Typography>{text}</Typography>
      <Button sx={{ marginTop: "2rem" }} fullWidth variant={"contained"} component={Link} to={getCaseLink(product) + "/anfrage"}>
        {buttonText}
      </Button>
    </ContentBox>
  );
}

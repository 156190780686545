import React, { ChangeEvent, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import _get from "lodash/get";
import { getProductData } from "../../../services/productService";
import { InfoOutlined } from "@mui/icons-material";
import { MediaObject } from "../../../types/Entities/MediaObject/MediaObject";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import MessagesAttachmentUploadSection from "../../Form/Common/FileUploadSection/MessagesAttachmentUploadSection";
import {isAllowMultiplePagesFileType} from "../../../services/mediaObjects";

type UploadBoxFormStepProps = {
  product: AbstractCase;
  currentFileIndex?: number;
  description: string;
  setDescription: Function;
  messagePath: string;
};

//mostly duplicate code. only ok because the whole file upload handling needs to be reworked
export default function UploadBox({ product, description, setDescription, messagePath, currentFileIndex = 0 }: UploadBoxFormStepProps) {
  const [isLoading, setIsLoading] = useState(false);
  const productApiUri = getProductData(product.name, "apiUri");
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  const handleNoteChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 50) {
      return;
    }
    setDescription(target.value);
  };

  const updateAction = async (
    id: number,
    data: {
      temporaryFilesToCombineData: {
        messages: { messageId: Array<{ files: Array<MediaObject>; description: string; fieldName: string }> };
      };
    }
  ) => {
    await updateMutation.mutateAsync({
      uri: productApiUri,
      id: id,
      data: data,
    });
  };

  const firstMediaObject = _get(product, [...messagePath.split("."), currentFileIndex, "files", 0]);

  const descriptionTooShort = description.length < 5;

  return (
    <Stack spacing={2} sx={{ maxWidth: 650, margin: "0 auto" }}>
      <Typography variant={"h5"}>{"Datei " + (currentFileIndex + 1) + ":"}</Typography>
      <Typography>1. Wählen Sie hier die Datei aus, die an dem Fall hochgeladen werden soll</Typography>
      <MessagesAttachmentUploadSection
        fileArrayName={"temporaryFilesToCombineData"}
        description={description}
        productEntity={product}
        productEntityId={product.id}
        product={product.name}
        updateAction={updateAction}
        setIsBlocked={setIsLoading}
        isBlocked={isLoading}
        fieldName={"uploadedDocument" + currentFileIndex}
        fileIndex={currentFileIndex}
        noSection={true}
        uploadFileLabel={"Datei auswählen"}
        addPageLabel={"Bild als neue Seite hinzufügen"}
        withUploadNotification={false}
        messagePath={messagePath}
      />
      {firstMediaObject && isAllowMultiplePagesFileType(firstMediaObject.originalName) && (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <InfoOutlined />
          <Typography variant={"body2"}>
            Wenn Sie mehrere Bilder zu einem Dokument zusammenfügen möchten, können Sie hierfür einfach den Button "Bild als neue Seite hinzufügen" drücken.
            Wenn Sie weitere separate Dokumente hochladen möchten, klicken Sie bitte auf “Weiter”.
          </Typography>
        </Stack>
      )}
      <Typography>2. Bitte geben Sie hier für die ausgewählte Datei eine kurze Beschreibung/einen Namen an</Typography>
      <TextField
        value={description}
        onChange={handleNoteChange}
        disabled={isLoading}
        label={"Dokumentenname / Kurzbeschreibung"}
        error={descriptionTooShort}
        helperText={"[" + description.length + "/50]" + (descriptionTooShort ? " Bitte geben Sie mindestens 5 Buchstaben ein" : "")}
        focused
      />
    </Stack>
  );
}

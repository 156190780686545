import { scrollToAnchor } from "../../../../theme/commonStyle";

const myAccountPageStyle = (theme) => ({
  scrollToAnchor: {
    ...scrollToAnchor,
    marginBottom: "1rem",
  },
  paper: {
    padding: "1rem",
    margin: "25px 0",
  },
  headline: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "2rem",
  },
  verticalSpace: {
    marginBottom: "1rem",
  },
  container: {
    maxWidth: 500,
    padding: 25,
    margin: "0 auto",
  },
});

export default myAccountPageStyle;

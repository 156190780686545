import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import SignaturePad from "../../../SignaturePad/SignaturePad";
import ButtonLoading from "../../../Button/ButtonLoading";
import Button from "@mui/material/Button";
import { Replay as ReplayIcon } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { API_HOST, API_PATH } from "../../../../config/_entrypoint";
import { urltoFile } from "../../../../services/mediaObjects";
import { getProductData } from "../../../../services/productService";
import signatureStyle from "./signatureStyle";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";

const useStyles = makeStyles(signatureStyle);
export default function Signature({ product }) {
  const classes = useStyles();
  const [signatureImage, setSignatureImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const signaturePadRef = useRef();
  const createMediaObjectMutation = useMutation(createMediaObject);

  const loadSignature = useCallback(async () => {
    if (!product.signature) {
      return;
    }
    const downloadLink = API_HOST + API_PATH + "/download/" + product.signature.id + "?token=" + localStorage.getItem("token") + "&base64=1";
    const signatureImageResponse = await fetch(downloadLink);
    const imageSrc = await signatureImageResponse.text();
    setSignatureImage(imageSrc);
  }, [product.signature]);

  useEffect(() => {
    loadSignature();
  }, [loadSignature]);

  const clear = () => {
    setSignatureImage("");
    signaturePadRef.current.signaturePadInstance.clear();
  };

  const queryClient = useQueryClient();

  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  const saveSignature = async () => {
    if (!signaturePadRef.current || signaturePadRef.current.signaturePadInstance.isEmpty()) {
      return;
    }
    setIsLoading(true);
    const file = await urltoFile(signaturePadRef.current.signaturePadInstance.toDataURL("image/svg+xml"), "signature.svg", "image/svg+xml");

    const result = await createMediaObjectMutation.mutateAsync({
      data: {
        file: file,
        description: "Unterschrift",
        fieldname: "signature",
        product: product.name,
        productId: product.id,
      },
    });

    await updateMutation.mutateAsync({
      uri: getProductData(product.name, "apiUri"),
      id: product.id,
      data: {
        signature: `media_objects/${result.id}`,
      },
    });
    setIsLoading(false);
  };

  const removeSignature = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: getProductData(product.name, "apiUri"),
      id: product.id,
      data: {
        signature: null,
      },
    });
    clear();
    setIsLoading(false);
  };
  return (
    <Grid container justifyContent={"center"} alignItems={"baseline"} spacing={2} className={classes.signatureContainer}>
      <Grid item xs={12} className={classes.signatureCaption}>
        <Hidden smUp>
          <p className={classes.primary}>
            <strong>TIPP:</strong> Drehen Sie Ihr Handy, damit Sie mehr Platz zum Unterzeichnen haben.
          </p>
        </Hidden>
      </Grid>
      <Grid item>
        <Typography variant={"h4"}>Ihre Unterschrift</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={4}>
          {signatureImage !== "" ? (
            <img src={signatureImage} alt={"Unterschrift"} className={classes.signatureImage} />
          ) : (
            <SignaturePad height={200} ref={signaturePadRef} />
          )}
        </Paper>
      </Grid>
      {product.signature ? (
        <Grid item xs={12}>
          <ButtonLoading color="primary" fullWidth={true} isLoading={isLoading} onClick={removeSignature}>
            Neu unterschreiben
          </ButtonLoading>
        </Grid>
      ) : (
        <>
          <Grid item>
            <ButtonLoading isLoading={isLoading} className={classes.signatureButton} variant={"contained"} color={"primary"} onClick={saveSignature}>
              Unterschrift speichern
            </ButtonLoading>
          </Grid>
          <Grid item container xs={12} justifyContent={"center"}>
            <Button color={"primary"} onClick={() => clear()}>
              <ReplayIcon className={classes.leftIcon} /> Erneut versuchen
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import classNames from "classnames";
import { Grid, Hidden } from "@mui/material";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Address from "../../Common/Address/Address";
import { emailDefault, isPhoneNumberDefault } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";

const useStyles = makeStyles(wizardStyle);
export default function DebtCollection({ submitProps }) {
  const classes = useStyles();
  const { handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Angaben zum Inkassounternehmen</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>
                Sie haben angegeben, dass Sie von einem Inkassounternehmen zur Zahlung aufgefordert wurden. Bitte prüfen
                Sie das Schreiben vom Inkassounternehmen und befüllen Sie entsprechend die folgenden Angaben so
                vollständig wie möglich.
              </FormLegend>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridSpaceMd}>
              <ValidatorTextField name="debtCollectionAgency.name" label="Name Inkassobüro" />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatorTextField
                name="debtCollectionAgencyReference"
                label="Aktenzeichen Inkassobüro"
                helperText="Dieses Aktenzeichen finden Sie auf dem Schreiben des Inkassobüros. Meist rechts oben im Briefkopf oder in der Betreffzeile."
              />
            </Grid>
            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>
                Bitte geben Sie möglichst alle weiteren Daten zum Inkassounternehmen an, die Ihnen vorliegen.
              </FormLegend>
            </Grid>
            <Address path="debtCollectionAgency.address" headline="" fieldsAreRequired={false} />
            <Grid item xs={12} sm={6} className={classes.gridSpaceMd}>
              <ValidatorTextField
                name="debtCollectionAgency.email"
                label="E-Mail-Adresse"
                validators={[emailDefault]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorTextField
                name="debtCollectionAgency.telephone"
                label="Telefonnummer"
                validators={isPhoneNumberDefault}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}

import React from "react";
import ServiceWorld from "../../../components/Serviceworld/ServiceWorld";
import Layout from "../../Layouts/Layout";

import { Helmet } from "react-helmet-async";
import { gaSet } from "../../../services/cookieService";

const ServiceWorldPage = () => {
  const title = "Servicewelt | Legalbird";
  gaSet({ title: title });

  return (
    <Layout active={"serviceworld"} displayBackgroundImage={true} backgroundType={"serviceworld"}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ServiceWorld />
    </Layout>
  );
};

export default ServiceWorldPage;

/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import { myAccountBoxStyle } from "./myAccountBoxStyle";
import { SERVICE_EMAIL } from "../../services/globalVars";
import Grid from "@mui/material/Grid";
import ContentBoxHead from "../Box/ContentBox/ContentBoxHead";
import icon from "../../assets/icons/generic/211835_trash_icon.svg";
import Typography from "@mui/material/Typography";
import { apiGet } from "../../services/apiClient";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import * as messagesActions from "../../store/messages/actions";
import ButtonLoading from "../Button/ButtonLoading";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/reactQuery/reactQueryService";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function AccountDelete() {
  const [isDeletable, setIsDeletable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deletionToken, setDeletionToken] = useState("");
  const [deletionEmailSent, setDeletionEmailSent] = useState(false);
  const [isRequestingDeletion, setIsRequestingDeletion] = useState(false);

  const { data: minimalCases, isLoading: isLoadingMinimalCases } = useQuery(queryKeys.collection("/collection/cases"), () =>
    fetchCollection("/collection/cases")
  );

  async function checkDeletable() {
    const result = await apiGet("/account_deletion/check_deletion_possible_from_token");
    setIsDeletable(result.deletable);
    setDeletionToken(result.token);
    setIsLoading(false);
  }

  async function requestDeletion() {
    setIsRequestingDeletion(true);
    try {
      await apiGet(`/account_deletion/request/${encodeURIComponent(deletionToken)}}`);
      setDeletionEmailSent(true);
      messagesActions.addMessage({
        type: "success",
        text: "Wir haben Ihnen eine E-Mail zur Bestätigung Ihrer Kontolöschung geschickt.",
      });
    } catch (e) {
      messagesActions.addMessage({
        type: "error",
        text: "Es ist ein Fehler beim Verschicken der E-Mail aufgetreten!",
      });
    } finally {
      setIsRequestingDeletion(false);
    }
  }

  useEffect(() => {
    checkDeletable();
  }, [minimalCases]);
  return (
    <Paper elevation={4} sx={myAccountBoxStyle.accountDataPageBox}>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <ContentBoxHead headline={"Konto löschen"} icon={icon} altText={"Konto löschen"} />
        </Grid>
        <Grid item xs={12} sx={myAccountBoxStyle.verticalSpace} />
        <Grid item>
          <Typography textAlign={"center"}>
            {(isLoading || isLoadingMinimalCases) && <LegalbirdLoader />}
            {isDeletable === false && minimalCases && minimalCases.length === 0 && (
              <>
                Aufgrund von Aufbewahrungspflichten können Ihre Daten leider nicht automatisch gelöscht werden! Bitte schicken Sie eine E-Mail an
                <a href={"mailto:" + SERVICE_EMAIL}> {SERVICE_EMAIL}</a> mit Ihrer Löschanfrage, damit wir diese prüfen und zeitnah weiter bearbeiten können.
              </>
            )}
            {isDeletable === false && minimalCases && minimalCases.length > 0 && (
              <>
                <Typography textAlign={"center"} marginBottom={"2rem"}>
                  Aufgrund von Aufbewahrungspflichten können Ihre Daten leider nicht automatisch gelöscht werden! Bitte schicken Sie uns eine Löschanfrage über
                  Ihren Nachrichtenbereich, damit wir diese prüfen und zeitnah weiter bearbeiten können.
                </Typography>
                <Button fullWidth component={Link} to={"/servicewelt/nachrichten"} variant={"contained"}>
                  Zum Nachrichtenbereich
                </Button>
              </>
            )}
            {isDeletable === true && (
              <>
                <Typography textAlign={"center"} marginBottom={"2rem"}>
                  Bitte klicken Sie hier, um eine Löschung ihres Kontos zu beantragen. Sie erhalten dann eine E-Mail mit einem Bestätigungslink.
                </Typography>
                <ButtonLoading
                  isLoading={isRequestingDeletion}
                  variant={"contained"}
                  disabled={deletionEmailSent}
                  onClick={() => requestDeletion()}
                  fullWidth={true}
                >
                  {deletionEmailSent ? "E-Mail wurde versendet" : "Löschung beantragen"}
                </ButtonLoading>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

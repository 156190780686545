import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./calendlyStyle";
import callIcon from "../../assets/icons/common/inactive/fragezeichen.svg";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import ContentBox from "../Box/ContentBox/ContentBox";
import {getCaseLink} from "../../services/productService";

const CalendlyLimitReached = ({product}) => {

  const classes = useStyles({});

  if (!product) {
    return null;
  }

  return (
    <div className={classes.contentContainer}>
      <ContentBox headline={"Weitere Hilfe benötigt?"} icon={callIcon}>
        <div>
          Es haben in diesem Fall bereits zwei Beratungen stattgefunden. Um Kosten für Sie zu vermeiden, ist es Ihnen
          hier
          nicht möglich, einen weiteren Beratungstermin zu buchen. Sollten Sie weitere Hilfe benötigen, wenden Sie sich
          bitte kurz per E-Mail an service@legalbird.de, um zu klären, wie wir Ihnen behilflich sein können und ob die
          Kosten von Ihrer Rechtsschutzversicherung gedeckt sind.
        </div>
        <Button
          component={Link}
          to={getCaseLink(product)}
          className={classes.backButton}
          color={"primary"}
          fullWidth
        >
          Zurück zum Fall
        </Button>
      </ContentBox>
    </div>
  );
};

CalendlyLimitReached.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CalendlyLimitReached;

import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./divorcePlannerBoxStyle";
import { Button, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import ContentBoxHead from "../../Box/ContentBox/ContentBoxHead";
import iconProgress from "../../../assets/icons/serviceOverview/icon_progress_128px.png";

const DivorcePlannerBox = ({ product }) => {
  const classes = useStyles({});

  if (product.isInitialProxy) {
    return null;
  }

  return (
    <Paper elevation={4} className={classes.paper}>
      <Grid container justifyContent={"center"} spacing={3}>
        <Grid item>
          <ContentBoxHead headline={"Scheidungsplaner"} altText={"Ablauf"} icon={iconProgress} />
        </Grid>
        <Grid item>
          Ihr persönlicher Scheidungsplaner passt sich über den gesamten Zeitraum der Scheidung immer an Ihre aktuelle
          Situation an. So haben Sie die nächsten Schritte im Blick und können Ihre Checklisten erledigen.
        </Grid>
        <Grid item>
          <Button color={"primary"} fullWidth={false} component={Link} to={getCaseLink(product) + "/scheidungsplaner"}>
            Zum Scheidungsplaner
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

DivorcePlannerBox.propTypes = {
  product: PropTypes.object.isRequired,
};

export default DivorcePlannerBox;

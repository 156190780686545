import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./pageNavigationStyle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { HashLink as Link } from "react-router-hash-link";
import _map from "lodash/map";
import _upperCase from "lodash/toUpper";
import { Divider, List, ListItem } from "@mui/material";
import classNames from "classnames";
import Typography from "@mui/material/Typography";

const PageNavigation = ({ navigationLinks, headerIcon, headline, subHeadline }) => {
  const classes = useStyles({});

  return (
    <Paper elevation={4} className={classes.navigationPaper}>
      <List disablePadding>
        <ListItem className={classes.headlineListItem}>
          <Grid container justifyContent={"center"} alignItems={"center"} wrap={"nowrap"}>
            <Grid item>
              <div className={classes.imageContainer}>
                <img src={headerIcon} className={classes.headerIcon} alt={""} />
              </div>
            </Grid>
            <Grid item className={classes.headlineContainer}>
              <Typography variant={"h5"}>{headline}</Typography>
              <div className={classes.subHeadline}>{subHeadline}</div>
            </Grid>
          </Grid>
        </ListItem>
        {_map(navigationLinks, (navigationLink) => (
          <React.Fragment key={navigationLink.label}>
            <Divider />
            <ListItem
              className={classNames(classes.listItem, classes.navigationLink)}
              to={navigationLink.target}
              button
              component={Link}
              secondaryAction={navigationLink.icon ?? null}
            >
              {_upperCase(navigationLink.label)}
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

PageNavigation.propTypes = {
  navigationLinks: PropTypes.array.isRequired,
  headerIcon: PropTypes.string.isRequired,
  subHeadline: PropTypes.string,
};

export default PageNavigation;

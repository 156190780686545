import React from "react";
import { nl2br } from "../../../../../services/formServiceFunctions";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import feedbackFormStyle from "../feedbackFormStyle";
import { useForm } from "../../../../../provider/Form/FormProvider";

const useStyles = makeStyles(feedbackFormStyle);
const Recommendation = ({ product, hasClaimStatusClient }) => {
  const classes = useStyles();
  const { values } = useForm();
  if (
    values.clientAgrees === "yes" ||
    values.finalClaimStatusClient === "fullyAgreed" ||
    (hasClaimStatusClient && values.finalClaimStatusClient === "")
  ) {
    return null;
  }

  if (product.extrajudicialRecommendation === "individualText") {
    return (
      <Grid item xs={12}>
        <div className={classes.recommendation}>
          {product.extrajudicialRecommendationText && (
            <p dangerouslySetInnerHTML={{ __html: nl2br(product.extrajudicialRecommendationText) }} />
          )}
        </div>
      </Grid>
    );
  }

  let recommendationText;
  switch (product.extrajudicialRecommendation) {
    case "lawsuit":
      if (product.caseCategory === "defendClaim") {
        recommendationText = (
          <p>
            Wir empfehlen Ihnen, die <strong>Forderung zunächst nicht zu erfüllen und eine Klage oder einen Mahnbescheid abzuwarten.</strong> Es kann durchaus
            sein, dass die Gegenseite überhaupt keine weiteren rechtlichen Schritte gegen Sie einleitet. Sollte die Gegenseite aber Ihre Forderung gegen Sie
            durchsetzen wollen, müsste diese letztendlich eine Klage einreichen oder einen Mahnbescheid erwirken, was für die Gegenseite auch mit einem
            Kostenrisiko verbunden ist. Unabhängig von dem, was die Gegenseite machen wird, sollten Sie aus unserer Sicht erst einmal die Forderung nicht
            erfüllen, sondern abwarten.{" "}
            <strong>Sollte die Gegenseite weitere rechtliche Schritte gegen Sie einleiten, werden wir selbstverständlich wieder für Sie tätig!</strong>
          </p>
        );
      }
      break;
    case "anonymousOpponent":
      recommendationText = (
        <p>
          In Ihrem Fall ist die Wahrscheinlichkeit sehr hoch, dass die von Ihnen genannte{" "}
          <strong>Gegenseite in der Form nicht existiert.</strong> Es müsste von Ihnen persönlich demzufolge (wenn noch
          nicht geschehen) zunächst Strafanzeige bei der Polizei erstattet werden, um überhaupt die richtige Person
          hinter diesem Geschäft zu ermitteln. Erst wenn die Gegenseite (inkl. zustellfähiger Anschrift) polizeilich
          ermittelt wurde, kann überhaupt gerichtlich weiter gegen diese vorgegangen werden. Da diese Ermittlungen
          oftmals nicht erfolgreich sind, lange Zeit in Anspruch nehmen können und für Sie mit Aufwand verbunden sind,
          sollten Sie aus unserer Sicht weiteren <strong>Aufwand vermeiden.</strong>
        </p>
      );
      break;
    case "opponentUnableToPay":
      recommendationText = (
        <p>
          In Ihrem Fall schätzen wir einen{" "}
          <strong>wirtschaftlichen Erfolg einer Klage als sehr unwahrscheinlich ein.</strong> Zwar haben Sie aus
          juristischer Sicht gegebenenfalls Erfolg vor Gericht, wir halten es aber für äußerst{" "}
          <strong>
            fraglich, ob die Gegenseite Ihre Forderung dann überhaupt begleichen kann, da diese gegebenenfalls
            zahlungsunfähig ist.
          </strong>{" "}
          Letztendlich wäre es für Sie dementsprechend unbefriedigend, wenn Ihre Forderungen nicht erfüllt werden
          können, obwohl Sie das Verfahren vielleicht sogar gewinnen.
        </p>
      );
      break;
    case "insufficientEvidence":
      recommendationText = (
        <p>
          In Ihrem Fall <strong>sind Sie vor Gericht in der Beweispflicht</strong> (nicht die Gegenseite). Die
          Beweislage ist in Ihrem Fall leider sehr ungünstig und es ist unwahrscheinlich, dass wir vor diesem
          Hintergrund vor Gericht einen Erfolg erzielen werden.
        </p>
      );
      break;
    case "insufficientChancesOfSuccess":
      recommendationText = (
        <p>
          In der Gesamtbetrachtung Ihres Falles sehen wir die{" "}
          <strong>Erfolgsaussichten für ein Obsiegen vor Gericht als eher gering.</strong> Ihre rechtliche Position ist
          nicht besonders gut und wir halten es für wahrscheinlich, dass Sie das Verfahren daher verlieren könnten.
        </p>
      );
      break;
    case "costBenefitConsiderations":
      recommendationText = (
        <p>
          In der Gesamtbetrachtung Ihres Falles ist bei einem gerichtlichen Verfahren das Verhältnis von Kosten und
          Nutzen als eher schlecht zu bewerten. Selbst wenn Sie einen Erfolg vor Gericht erzielen sollten, steht dieser
          Erfolg in einem sehr ungünstigen Verhältnis zum Aufwand, den Sie hierdurch haben.
        </p>
      );
      break;
    default:
      return null;
  }

  return (
    <Grid item xs={12}>
      <div className={classes.recommendation}>
        {recommendationText}
        {product.caseCategory === "defendClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
          <p>
            <strong>Wir empfehlen Ihnen daher, die Forderung zu erfüllen,</strong> um eine Klage gegen Sie zu vermeiden.
          </p>
        )}
        {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
          <p>
            Wir empfehlen Ihnen daher, Ihre Forderungen{" "}
            <strong>nicht vor Gericht durchzusetzen und den Fall an dieser Stelle abzuschließen.</strong>
          </p>
        )}
        {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation === "lawsuit" && (
          <p>Um Ihre Forderungen durchzusetzen, müssten Sie nun Klage einreichen.</p>
        )}
        {product.extrajudicialRecommendationText && (
          <p dangerouslySetInnerHTML={{ __html: nl2br(product.extrajudicialRecommendationText) }} />
        )}
      </div>
    </Grid>
  );
};
export default Recommendation;

import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../Fader/FormFader";
import classNames from "classnames";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import {formValue, getIsSpecialWbsSchufaCase} from "../../../../services/formServiceFunctions";
import MenuItem from "@mui/material/MenuItem";
import _map from "lodash/map";
import { sortedInsurances } from "../../../../services/insurancesList";
import FormSubmit from "../FormSubmit/FormSubmit";
import { getCaseReferenceNumberRegEx, getInsuranceNumberRegEx } from "../../../../services/insurerService";
import Iconize from "../../../SvgIcon/Iconize";
import PersonSvg from "../../../../assets/icons/provision/inactive/person.svg";
import PersonSvgActive from "../../../../assets/icons/provision/active/person.svg";
import FamilySvg from "../../../../assets/icons/provision/inactive/familie.svg";
import FamilySvgActive from "../../../../assets/icons/provision/active/familie.svg";
import Person from "../Person/Person";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { isRequired, matchRegexp } from "../../../../services/validationRules";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Group from "../../../Wizard/Group";
import CheckSvg from "../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../assets/icons/common/active/kreuz.svg";
import { getProductData, isInsurancePartnered } from "../../../../services/productService";
import Typography from "@mui/material/Typography";
import FormLegend from "../FormLegend/FormLegend";
import { translate } from "../../../../services/translations/translations";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";

const useStyles = makeStyles(wizardStyle);
export default function Insurance({ submitProps, product, withDeductible = false, insuranceSubArea = "" }) {
  const classes = useStyles();
  const { values, handleSubmit, registerValidators } = useForm();
  const insuranceNumberRegex = getInsuranceNumberRegEx(values.insurance.insurance);
  const caseReferenceNumberRegex = getCaseReferenceNumberRegEx(values.insurance.insurance);
  const [insuranceNumberRequired, setInsuranceNumberRequired] = useState(true);

  useEffect(() => {
    if (product.insurance.insurancePolicyNumber) {
      // field is hidden if it's already filled
      // a required validator on a hidden field makes submitting the form impossible
      setInsuranceNumberRequired(false);
      return;
    }
    if (product.productCategory === "contractLaw") {
      setInsuranceNumberRequired(true);
      return;
    }
    setInsuranceNumberRequired(values.legalExpenseInsurance === "yes" && !isInsurancePartnered(product));
  }, [values.legalExpenseInsurance]);

  useEffect(() => {
    if (!insuranceNumberRequired) {
      registerValidators("insurance.insurancePolicyNumber", []);
      return;
    }

    registerValidators("insurance.insurancePolicyNumber", [
      { validator: isRequired, message: "Bitte geben Sie Ihre Versichertennummer an." },
      {
        validator: matchRegexp,
        params: [insuranceNumberRegex],
        message: "Bitte prüfen Sie die Nummer. Sie finden diese in Ihren Versicherungsunterlagen. Beispiel: 22.6.123.456.8",
      },
    ]);
  }, [insuranceNumberRegex, insuranceNumberRequired]);

  useEffect(() => {
    if (values.legalExpenseInsurance !== "yes") {
      registerValidators("insurance.caseReferenceNumber", []);
      return;
    }

    if (!isInsurancePartnered(product)) {
      registerValidators("insurance.caseReferenceNumber", [
        {
          validator: matchRegexp,
          params: [caseReferenceNumberRegex],
          message: "Ihre Schadens-/Leistungsnummer hat das falsche Format",
        },
      ]);
    }
  }, [caseReferenceNumberRegex, values.legalExpenseInsurance]);

  const isSpecialWbsSchufaCase = getIsSpecialWbsSchufaCase(product);

  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Versicherung
            </Typography>
          </Hidden>
          <Grid container justifyContent={"center"}>
            {!isInsurancePartnered(product) && !isSpecialWbsSchufaCase && (
              <Group
                type="radio"
                name="legalExpenseInsurance"
                question={`Sind Sie im Bereich ${getProductData(product.name, "productCategory").label} rechtsschutzversichert?`}
                options={[
                  {
                    labelText: translate("legalExpenseInsurance.options.yes.label"),
                    labelIcon: <Iconize src={CheckSvg} alt={translate("legalExpenseInsurance.options.yes.label")} />,
                    labelIconActive: <Iconize src={CheckSvgActive} alt={translate("legalExpenseInsurance.options.yes.label")} />,
                    value: "yes",
                  },
                  {
                    labelText: translate("legalExpenseInsurance.options.no.label"),
                    labelIcon: <Iconize src={CrossSvg} alt={translate("legalExpenseInsurance.options.no.label")} />,
                    labelIconActive: <Iconize src={CrossSvgActive} alt={translate("legalExpenseInsurance.options.no.label")} />,
                    value: "no",
                  },
                ]}
                validators={[{ validator: isRequired, message: "Bitte wählen Sie eine der angezeigten Antworten aus" }]}
              />
            )}
            {(values.legalExpenseInsurance === "yes" || isInsurancePartnered(product)) && (
              <>
                {!isInsurancePartnered(product) && (
                  <>
                    <Grid item xs={12} className={classes.gridTopSpaced}>
                      <FormLegend>{translate("insurance.insurance.question")}</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorSelectField
                        label={translate("insurance.insurance.label")}
                        name="insurance.insurance"
                        validators={[
                          {
                            validator: isRequired,
                            message: "Bitte geben Sie an, wo Sie rechtsschutzversichert sind.",
                          },
                        ]}
                        helperText={"Bitte wählen Sie Ihren Rechtsschutzversicherer aus." + (insuranceSubArea ? ` Wichtig: Sie benötigen eine Versicherung im Bereich ${insuranceSubArea}.` : "")}
                        MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                      >
                        <MenuItem value={""}> </MenuItem>
                        {_map(sortedInsurances, (insurance) => (
                          <MenuItem key={insurance.id} value={"/insurances/" + insurance.id}>
                            {insurance.label}
                          </MenuItem>
                        ))}
                      </ValidatorSelectField>
                    </Grid>
                  </>
                )}
                {(!isInsurancePartnered(product) || !product.insurance.insurancePolicyNumber) && (
                  <>
                    <Grid item xs={12} className={classes.gridTopSpaced}>
                      <FormLegend>{translate("insurance.insurancePolicyNumber.question")}</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorTextField
                        name="insurance.insurancePolicyNumber"
                        label={translate("insurance.insurancePolicyNumber.label")}
                        helperText="Sie finden diese Nummer auf Ihrer Versichertenkarte."
                        validators={[
                          { validator: isRequired, message: "Bitte geben Sie Ihre Versichertennummer an." },
                          {
                            validator: matchRegexp,
                            params: [insuranceNumberRegex],
                            message: "Bitte prüfen Sie die Nummer. Sie finden diese in Ihren Versicherungsunterlagen. Beispiel: 22.6.123.456.8",
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}
                {!isInsurancePartnered(product) && !isSpecialWbsSchufaCase && (
                  <>
                    <Grid item xs={12} className={classes.gridTopSpaced}>
                      <FormLegend>{translate("insurance.caseReferenceNumber.question")}</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorTextField
                        name="insurance.caseReferenceNumber"
                        label={translate("insurance.caseReferenceNumber.label")}
                        helperText="Dies ist die Nummer, die sie zu Ihrem aktuellen Anliegen erhalten haben. Tragen Sie hier bitte NICHT Ihre Versichertennummer ein."
                        validators={[
                          {
                            validator: matchRegexp,
                            params: [caseReferenceNumberRegex],
                            message: "Ihre Schadens-/Leistungsnummer hat das falsche Format",
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}
                {withDeductible && <NumberFieldConfigured caseData={values} fieldPath={"insurance.deductible"} fieldType={"money"} numberType={"float"} />}
                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="insurance.policyHolderType"
                    question={translate("insurance.policyHolderType.question")}
                    options={[
                      {
                        labelText: translate("insurance.policyHolderType.options.me.label"),
                        labelIcon: <Iconize src={PersonSvg} alt={translate("insurance.policyHolderType.options.me.label")} />,
                        labelIconActive: <Iconize src={PersonSvgActive} alt={translate("insurance.policyHolderType.options.me.label")} />,
                        value: "me",
                      },
                      {
                        labelText: translate("insurance.policyHolderType.options.familyMember.label"),
                        labelIcon: <Iconize src={FamilySvg} alt={translate("insurance.policyHolderType.options.familyMember.label")} />,
                        labelIconActive: <Iconize src={FamilySvgActive} alt={translate("insurance.policyHolderType.options.familyMember.label")} />,
                        value: "familyMember",
                      },
                    ]}
                    validators={[
                      {
                        validator: isRequired,
                        message: "Bitte wählen Sie eine der angezeigten Antworten aus",
                      },
                    ]}
                  />
                </Grid>
                {formValue(values, "insurance.policyHolderType") === "familyMember" && (
                  <Person path={"insurance.policyHolder"} headline={"Wie heißt die Person, auf die die Rechtsschutz-Versicherung läuft?"} />
                )}
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}

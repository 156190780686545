import React, { useState } from "react";
import useStyles from "./divorceCostBoxStyle";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import classNames from "classnames";
import DialogModal from "../../../DialogModal/DialogModal";
import ReactGA from "react-ga4";
import CostOverviewContainer from "../CostOverviewContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateFees, calculateValueInDispute } from "../../../../services/costCalculation";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import DivorceCostCalculatorForm from "./DivorceCostCalculatorForm";

const DivorceCostCalculator = () => {
  const classes = useStyles({});
  const location = useLocation();
  const navigate = useNavigate();

  const [valueInDispute, setValueInDispute] = useState(null);
  const [fees, setFees] = useState(null);

  const initialValues = {
    netIncomePetitioner: "",
    netIncomePartner: "",
    numberPensions: "",
    numberPensionsPartner: "",
    pensionsExcluded: false,
  };

  const handleSubmit = ({ values }) => {
    const vid = calculateValueInDispute(values);
    const calculatedFees = calculateFees(vid.total);
    setValueInDispute(vid);
    setFees(calculatedFees);
  };

  const toRecalc = () => {
    ReactGA.event({
      category: "Cost Calculator",
      action: "Re-calculate",
    });
    setValueInDispute(null);
    setFees(null);
  };

  const costDetailModalClose = () => {
    navigate(-1);
  };

  if (valueInDispute && fees) {
    return (
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"}>
            <Grid item className={classNames(classes.costEstimateLabel, classes.primary)}>
              Kostenschätzung
            </Grid>
            <Grid item className={classNames(classes.costEstimateValue, classes.primary)}>
              {fees.total.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}*
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.costButton}
            variant="contained"
            onClick={() => navigate(location.pathname, { state: { costLayerModalOpen: true } })}
            fullWidth
          >
            Kosten im Detail
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={toRecalc} className={classes.costButton} variant="contained" fullWidth>
            Neu berechnen
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.costDisclaimerText}>
            *Dies ist eine Vorschusskosten-Rechnung. Die letztendlich anfallenden Kosten werden vom Gericht festgesetzt.
          </p>
          <hr />
        </Grid>
        <Grid item xs={12} className={classes.hintBox}>
          <Grid item xs={12} className={classes.paymentHint}>
            Fragen zur Finanzierung?
          </Grid>
          <Grid item xs={12}>
            Melden Sie sich dazu gerne direkt bei uns und wir finden gemeinsam das für Sie passende Modell!
          </Grid>
        </Grid>
        <DialogModal
          open={!!(location.state && location.state.costLayerModalOpen)}
          onClose={costDetailModalClose}
          title={"Kostenschätzung"}
        >
          <CostOverviewContainer fees={fees} valueInDispute={valueInDispute} handleClose={costDetailModalClose} />
        </DialogModal>
      </Grid>
    );
  }

  return (
    <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
      <DivorceCostCalculatorForm />
    </FormProvider>
  );
};

export default DivorceCostCalculator;

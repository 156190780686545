const loginActionStyles = {
  recoverPassword: {
    textAlign: "right",
    marginBottom: "1rem",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
  text: {
    textAlign: "center",
  },
  submitButton: {
    margin: "1rem 0",
  },
  termsOfServiceText: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 10,
  },
  sslLockIcon: {
    height: 12,
  },
};
export default loginActionStyles;

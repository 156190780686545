import React from "react";
import * as PropTypes from "prop-types";
import VehicleSpecificCaseDetails from "./ContractSpecificCaseDetails/VehicleSpecificCaseDetails";
import PurchaseSpecificCaseDetails from "./ContractSpecificCaseDetails/PurchaseSpecificCaseDetails";
import TravelSpecificCaseDetails from "./ContractSpecificCaseDetails/TravelSpecificCaseDetails";
import SubscriptionSpecificCaseDetails from "./ContractSpecificCaseDetails/SubscriptionSpecificCaseDetails";
import MembershipSpecificCaseDetails from "./ContractSpecificCaseDetails/MembershipSpecificCaseDetails";
import ServiceSpecificCaseDetails from "./ContractSpecificCaseDetails/ServiceSpecificCaseDetails";
import EventSpecificCaseDetails from "./ContractSpecificCaseDetails/EventSpecificCaseDetails";
import ProviderSpecificCaseDetails from "./ContractSpecificCaseDetails/ProviderSpecificCaseDetails";

const ContractSpecificDetailsSection = ({ contractLawCase }: any) => {
  switch (contractLawCase.name) {
    case "vehicleContract":
      return <VehicleSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "purchaseContract":
      return <PurchaseSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "travelContract":
      return <TravelSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "subscriptionContract":
      return <SubscriptionSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "membershipContract":
      return <MembershipSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "serviceContract":
      return <ServiceSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "eventContract":
      return <EventSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "providerContract":
      return <ProviderSpecificCaseDetails contractLawCase={contractLawCase} />;
    default:
      return null;
  }
};

ContractSpecificDetailsSection.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default ContractSpecificDetailsSection;

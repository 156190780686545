import wizardStyle from "../../wizardStyle";

const feedbackFormStyle = (theme) => ({
  ...wizardStyle(theme),
  recommendation: {
    marginTop: "2rem",
    textAlign: "center",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: 5,
    padding: "0.5rem",
  },
  lawsuitPoints: {
    marginTop: "2rem",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: 5,
    padding: "0.5rem",
  },
  button: {
    margin: "1rem 0",
  },
});
export default feedbackFormStyle;

import makeStyles from '@mui/styles/makeStyles';
import { commonJobsStyles } from "../../../Jobs/commonJobsStyles";

const useStyles = makeStyles((theme) => ({
  ...commonJobsStyles(theme),
  headline: {
    textAlign: "center",
  },
  carouselItemContainer: {
    padding: "2rem",
    [theme.breakpoints.down('sm')]: {
      padding: "2rem 1rem",
    },
  },
}));

export default useStyles;

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { getCaseLink, getProductData } from "../productService";
import { getMaxApplicationStatus } from "../applicationStatus";
import * as leadStatusDocument from "../document/leadStatus";
import moment from "moment";
import _get from "lodash/get";

const getContent = (alimony) => {
  if (alimony.leadStatus < leadStatusDocument.STATUS_COMPLETED) {
    return {
      header: "Jetzt Ihre Analyse fertig stellen",
      text: "Es fehlen nur noch wenige Angaben für Ihre Analyse. In der Analyse finden Sie zudem einen Link zum Vereinbaren eines Erstgesprächs.",
      buttonText: "Jetzt Fertigstellen",
    };
  }

  const appointmentStatus = _get(alimony, "advisoryAppointment.status");
  const appointmentDate =
    _get(alimony, "advisoryAppointment.appointment.date") &&
    moment(_get(alimony, "advisoryAppointment.appointment.date"));

  if (!appointmentDate) {
    return {
      header: "Ihre Analyse wurde erstellt",
      text: "Ihre Unterhaltsanalyse wurde erfolgreich erstellt. Sie finden die Analyse unter “Meine Dokumente”. Hier können Sie den Termin für eine Erstberatung vereinbaren:",
      buttonText: "Termin vereinbaren",
    };
  }

  if (appointmentStatus === "not_processed") {
    return {
      header: "Ihr Termin konnte nicht stattfinden",
      text:
        `Sie hatten einen Termin für ein Erstgespräch am ${
          appointmentDate && appointmentDate.format("DD.MM.YYYY")
        } gebucht. Der Termin konnte nicht stattfinden. Buchen Sie sich gerne einen neuen Termin.`,
      buttonText: "Neuen Termin buchen",
    };
  }

  if (appointmentDate.isAfter(moment())) {
    return {
      header: "Termin erfolgreich gebucht",
      text:
        "Sie haben einen Termin für ein Erstgespräch am " +
        appointmentDate.format("DD.MM.YYYY") +
        " gebucht. Ihr Rechtsanwalt wird Sie um " +
        appointmentDate.format("HH:mm") +
        " Uhr anrufen.",
    };
  }

  return {
    header: "Ihr Fall ist abgeschlossen",
    text: "Vielen Dank für Ihr Vertrauen und dafür, dass wir Sie unterstützen durften! Sie sind der Meinung, dass ihr Fall nicht abgeschlossen ist oder es sind im Nachgang doch noch Probleme aufgetreten? Dann schreiben Sie uns gerne jederzeit eine Nachricht und wir werden selbstverständlich umgehend für Sie tätig!",
  };
};

const getButton = (alimony) => {
  const content = getContent(alimony);
  if (!content.buttonText) {
    return null;
  }

  if (alimony.leadStatus === leadStatusDocument.STATUS_COMPLETED) {
    const appointmentStatus = _get(alimony, "advisoryAppointment.status");
    const appointment =
      _get(alimony, "advisoryAppointment.appointment.date") &&
      moment(_get(alimony, "advisoryAppointment.appointment.date"));

    if (appointment && appointmentStatus !== "not_processed") {
      return null;
    }
    return (
      <Button
        fullWidth
        variant={"contained"}
        color={"primary"}
        component={Link}
        to={getCaseLink(alimony) + alimony.calendlyLink}
      >
        {content.buttonText}
      </Button>
    );
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      component={Link}
      to={getCaseLink(alimony) + "/antrag"}
    >
      {content.buttonText}
    </Button>
  );
};

const getPercentage = (alimony) => {
  if (alimony.leadStatus === leadStatusDocument.STATUS_CREATED) {
    const maxApplicationStatus = getMaxApplicationStatus(alimony.name);
    return (
      (Math.max(1, alimony.applicationStatus) / (maxApplicationStatus + 10)) *
      50
    );
  }
  let appointment =
    _get(alimony, "advisoryAppointment.appointment.date") &&
    moment(_get(alimony, "advisoryAppointment.appointment.date"));

  if (!appointment) {
    return 75;
  }
  if (appointment.isAfter(moment())) {
    return 90;
  }
  return 100;
};

export const getStatusBoxConfigAlimony = (alimony) => {
  let statusBoxConfig = {};
  const ctaButton = getButton(alimony);
  const content = getContent(alimony);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(alimony.name, "label");
  statusBoxConfig.percentage = getPercentage(alimony);

  return statusBoxConfig;
};

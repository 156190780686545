import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import useStyles from "./familyLawConsultingCheckinStyle";
import * as loginActions from "../../../../store/login/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { getLpByLocation } from "../../../../services/landingPagesList";
import Typography from "@mui/material/Typography";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import FamilyLawCheckinForm from "../Alimony/FamilyLawCheckinForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";

export default function FamilyLawConsultingCheckin({ color, target, idPrefix, checkinHeading, partner }) {
  const classes = useStyles({});
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const initialValues = {
    telephone: "",
    email: "",
    gender: "",
    familyName: "",
    givenName: "",
  };
  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  const handleSubmit = async ({ values }) => {
    setIsLoading(true);

    const { from } = location.state || { from: { pathname: target } };
    const emailCheck = await loginActions.checkEmail(values.email.toLowerCase());
    if (emailCheck.hasError) {
      setIsLoading(false);
      setEmailErrorText(emailCheck.errorText);
      return;
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };
    const data = {
      email: values.email.toLowerCase(),
      gclid: localStorage.getItem("gclid"),
      telephone: values.telephone,
      person: {
        gender: values.gender,
        givenName: values.givenName,
        familyName: values.familyName,
      },
      prelead: {
        initialProduct: "familyLawCase",
        landingPage: landingPage.keyword,
        partner: partner,
      },
    };

    await loginActions.register(data);
    await createMutation.mutateAsync({
      uri: "/family_law_cases",
      data: {
        acquisitionPartner: partner,
      },
    });
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate(from.pathname);
  };

  return (
    <Paper elevation={4} className={classNames(classes.formPaper, classes[color])}>
      <div id={idPrefix + "-checkin"} className={classes.scrollToAnchor} />
      <Typography variant={"h4"} className={classes.heading}>
        {checkinHeading}
      </Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <FamilyLawCheckinForm isLoading={isLoading} emailErrorText={emailErrorText} setEmailErrorText={setEmailErrorText} />
      </FormProvider>
    </Paper>
  );
}

FamilyLawConsultingCheckin.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["default", "grey"]),
  checkinHeading: PropTypes.string.isRequired,
  target: PropTypes.string,
  partner: PropTypes.string.isRequired,
};

FamilyLawConsultingCheckin.defaultProps = {
  color: "default",
  checkinHeading: "Jetzt professionelle Erstberatung vereinbaren",
  target: "/servicewelt",
};

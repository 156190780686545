import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import UploadBox from "./UploadBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import _get from "lodash/get";
import { getProductData } from "../../../services/productService";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { handleDocumentUploadDescriptionSave } from "../../../services/documentUploadFormService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";

type MainUploadFormStepProps = {
  setCurrentStep: Function;
  product: AbstractCase;
  setIsLoading: Function;
};

export default function MainUploadFormStep({ product, setCurrentStep, setIsLoading }: MainUploadFormStepProps) {
  const [description, setDescription] = useState(_get(product, ["temporaryFilesToCombine", 0, "description"], ""));
  const queryClient = useQueryClient();
  const productApiUri = getProductData(product.name, "apiUri");
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  useEffect(() => {
    if (!product.temporaryFilesToCombine[0]) {
      updateMutation.mutate({
        uri: productApiUri,
        id: product.id,
        data: {
          temporaryFilesToCombine: [{ files: [], description: "", fieldName: "uploadedFile" }],
        },
      });
    }
  }, []);

  if (!product.temporaryFilesToCombine[0]) {
    return <LegalbirdLoader centered />;
  }

  const hasDocument = _get(product, ["temporaryFilesToCombine", 0, "files"], []).length > 0;

  const handleNextStepClick = async () => {
    setIsLoading(true);
    await handleDocumentUploadDescriptionSave(product, 0, description, updateMutation);
    setIsLoading(false);
    setCurrentStep((currentStep: number) => currentStep + 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          Datei auswählen
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          Laden Sie hier Dokumente und Dateien hoch, die uns helfen Ihren Fall zu bearbeiten.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UploadBox product={product} description={description} setDescription={setDescription} />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6}>
        <Button disabled={!hasDocument || description.length < 5} fullWidth variant={"contained"} onClick={handleNextStepClick}>
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
}

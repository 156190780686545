import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import Container from "../../Container/Container";
import { FormProvider } from "../../../provider/Form/FormProvider";
import TextContainer from "../../Container/TextContainer";
import ExternalLawyerApplicationForm from "./ExternalLawyerApplicationForm";
import * as messagesActions from "../../../store/messages/actions";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import ApiClient from "../../../services/apiClient";
import { postMediaObject } from "../../../services/mediaObjects";
import _head from "lodash/head";

export default function ExternalLawyerApplicationSection() {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshForm, setRefreshForm] = useState(0);
  const [lawyerCertificate, setLawyerCertificate] = useState([]);
  const [specialistLawyerCertificate, setSpecialistLawyerCertificate] = useState([]);

  const handleSubmit = async ({ values }: any) => {
    setIsLoading(true);
    let data = _cloneDeep(values);
    const lawyerCertificateResponse = await postMediaObject(_head(lawyerCertificate), "lawyerCertificate");
    data.lawyerCertificate = "/media_objects/" + lawyerCertificateResponse.id;
    if (!_isEmpty(specialistLawyerCertificate)) {
      const specialistLawyerCertificateResponse = await postMediaObject(_head(specialistLawyerCertificate), "lawyerCertificate");
      data.specialistLawyerCertificate = "/media_objects/" + specialistLawyerCertificateResponse.id;
    }
    data.weeklyHours = parseInt(data.weeklyHours, 10);
    data.hasSpecialistTitles = data.hasSpecialistTitles === "true";
    await ApiClient.post("/external_lawyer_applications", { body: JSON.stringify(data) });
    messagesActions.addMessage({
      type: "success",
      text: "Vielen Dank für Ihr Interesse an einer Zusammenarbeit mit uns! Wir prüfen nun Ihre Bewerbung.",
    });
    setLawyerCertificate([]);
    setSpecialistLawyerCertificate([]);
    setIsLoading(false);
    setRefreshForm(refreshForm + 1);
  };

  const initialValues = {
    gender: "",
    givenName: "",
    familyName: "",
    streetAddress: "",
    postalCode: "",
    addressLocality: "",
    email: "",
    telephone: "",
    yearsOfExperience: "",
    hasSpecialistTitles: "",
    specialistTitles: [],
    legalFieldExperience: [],
    weeklyHours: "",
    taskType: [],
    referenceSites: "",
  };

  return (
    <Container>
      <Typography sx={{ textAlign: "center", marginBottom: "2rem" }} variant={"subtitle1"}>
        Uns ist eine hohe Verlässlichkeit und Qualifikation bei der Auswahl unserer Partneranwälte sehr wichtig. Daher werden Sie und Ihre Unterlagen zunächst
        kurz geprüft, bevor Ihr Konto bei Legalbird freigeschaltet wird. Sobald Sie freigeschaltet wurden, können Sie sich ein Passwort vergeben und sofort
        loslegen!
      </Typography>
      <TextContainer>
        <Paper sx={{ padding: "2rem" }}>
          <FormProvider initialValues={initialValues} submitAction={handleSubmit} key={refreshForm}>
            <ExternalLawyerApplicationForm
              isLoading={isLoading}
              setLawyerCertificate={setLawyerCertificate}
              lawyerCertificate={lawyerCertificate}
              setSpecialistLawyerCertificate={setSpecialistLawyerCertificate}
              specialistLawyerCertificate={specialistLawyerCertificate}
            />
          </FormProvider>
        </Paper>
      </TextContainer>
    </Container>
  );
}

import makeStyles from "@mui/styles/makeStyles";
import doneBoxStyle from "./doneBoxStyle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import _uniqueId from "lodash/uniqueId";
import React from "react";

const useStyles = makeStyles(doneBoxStyle);

const ChildrenSection = ({ divorce }) => {
  const classes = useStyles();
  if (divorce.numberChildren === 0) {
    return null;
  }
  return (
    <div>
      <Typography variant={"h6"} className={classes.subheader}>Angaben zu Ihren Kindern</Typography>
      <div className={classes.divider} />
      <Grid container>
        {divorce.children.map((child, index) => {
          return [
            <Grid container item xs={12} key={_uniqueId()}>
              <Grid item xs={12} className={classes.label}>
                Vorname Kind {index + 1}
              </Grid>
              <Grid item xs={12}>
                {child.name}
              </Grid>
            </Grid>,
            <Grid container item xs={12} key={_uniqueId()}>
              <Grid item xs={12} className={classes.label}>
                Wohnort Kind {index + 1}
              </Grid>
              <Grid item xs={12}>
                {child.livesWith === "petitioner" ? "Lebt bei mir" : "Lebt beim Ehepartner"}
              </Grid>
            </Grid>,
          ];
        })}
      </Grid>
    </div>
  );
};

export default ChildrenSection

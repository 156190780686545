/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { getLabelTranslationPath, getQuestionTranslationPath, translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import { requiredValidator } from "../../../../services/validationRules";
import React, {useEffect} from "react";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";
import {ValidatorType} from "../../../../types/ValidatorType";

type TextFieldConfiguredProps = {
  caseData: Object;
  fieldPath: string;
  required?: boolean | undefined;
  multiline?: boolean;
  labelDependsOnField?: string | null;
  questionDependsOnField?: string | null;
};
export default function TextFieldConfigured(
  {
    caseData,
    fieldPath,
    required,
    multiline = false,
    labelDependsOnField = null,
    questionDependsOnField = null
  }: TextFieldConfiguredProps) {
  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(getQuestionTranslationPath(caseData, fieldPath, questionDependsOnField));
  const label = translate(getLabelTranslationPath(caseData, fieldPath, labelDependsOnField));
  const validators:Array<ValidatorType> = [];

  useEffect(() => {
    if (required) {
      validators.push(requiredValidator());
    }
    if (getFormDefinitions(caseData, fieldPath) && getFormDefinitions(caseData, fieldPath).validators) {
      const definedValidators: Array<ValidatorType> = getFormDefinitions(caseData, fieldPath).validators;
      definedValidators.forEach(
        (definedValidator) => {
          validators.push(definedValidator);
        }
      );
    }
  }, [])

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>{question}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorTextField
          name={fieldPath}
          label={label}
          multiline={multiline}
          rows={multiline ? 6 : 1}
          helperText={helperText}
          validators={validators}
        />
      </Grid>
    </>
  );
}

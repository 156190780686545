import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/reactQuery/reactQueryService";
import _head from "lodash/head";
import { apiPost } from "../../services/apiClient";

interface CheckoutOrderProviderValue {
  checkoutOrder: any;
  isLoading: boolean;
  isError: boolean;
  create: Function;
  clear: Function;
  isInitialized: boolean;
}

const checkoutOrderContextDefaults: CheckoutOrderProviderValue = {
  checkoutOrder: null,
  isLoading: false,
  isError: false,
  create: () => {},
  clear: () => {},
  isInitialized: false,
};
const CheckoutOrderContext = React.createContext(checkoutOrderContextDefaults);

function CheckoutOrderProvider({ children, orderId }: { children: React.ReactElement, orderId: string | null }) {
  const {
    data: checkoutOrderCollection,
    isLoading,
    isError,
  } = useQuery(queryKeys.collection("/checkout_orders", {orderIdentifier: orderId}), () => fetchCollection("/checkout_orders", {orderIdentifier: orderId}));
  const collectedCheckoutOrder = _head(checkoutOrderCollection);
  if (!!collectedCheckoutOrder) {
    localStorage.setItem("checkoutOrder", JSON.stringify(collectedCheckoutOrder));
  }
  const checkoutOrder: any = JSON.parse(localStorage.getItem("checkoutOrder") || "null");

  const create = async (data: any) => {
    const checkoutOrderResponse = await apiPost("/checkout_orders", data);
    localStorage.setItem("checkoutOrder", JSON.stringify(checkoutOrderResponse));
  }

  const clear = async () => {
    localStorage.removeItem("checkoutOrder");
  }

  const data: CheckoutOrderProviderValue = {
    checkoutOrder,
    isLoading,
    isError,
    create,
    clear,
    isInitialized: true,
  };

  return <CheckoutOrderContext.Provider value={data}>{children}</CheckoutOrderContext.Provider>;
}

const useCheckoutOrder = (): CheckoutOrderProviderValue => {
  const checkoutOrderContext = useContext(CheckoutOrderContext);
  if (!checkoutOrderContext.isInitialized) {
    throw new Error("useCheckoutOrder can only be used inside CheckoutOrderProvider");
  }
  return checkoutOrderContext;
};

export { CheckoutOrderProvider, useCheckoutOrder };

import CaseDetailsItem from "../../../Serviceworld/Common/CaseDetailsItem/CaseDetailsItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import doneBoxStyle from "./doneBoxStyle";
const useStyles = makeStyles(doneBoxStyle);

const PersonSection = ({ product, personType }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={"h6"} className={classes.subheader}>
        {personType === "partner" ? "Angaben zum Ehepartner" : "Ihre Angaben"}
      </Typography>
      <div className={classes.divider} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.givenName`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.familyName`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.nationality`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.residenceAddress.streetAddress`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.residenceAddress.postalCode`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.residenceAddress.addressLocality`} />
      <CaseDetailsItem caseData={product} fieldPath={`${personType}.givenName`} />
    </>
  );
};
export default PersonSection;

import React from "react";
import Layout from "../../Layouts/Layout";
import TeaserWithCards from "../../../components/Teaser/TeaserWithCards";
// @ts-ignore
import { Helmet } from "react-helmet-async";
import LinkedCardBox from "../../../components/LinkedCardBox/LinkedCardBox";
import varietyImage from "../../../assets/img/products/Illustration_Arbeitsrecht.svg";
import flexibilityImage from "../../../assets/img/teaser/contact/Contact.svg";
import transparencyImage from "../../../assets/img/teaser/contractLaw/Vertragsrecht_Abofalle.svg";
import GreyBackgroundWrapper from "../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import ExternalLawyerApplicationSection from "../../../components/Sections/Jobs/ExternalLawyerApplicationSection";

export default function LpExternalLawyer() {
  return (
    <Layout backgroundType={"landingpage"}>
      <Helmet>
        <title>Partneranwalt werden</title>
        <meta name="description" content="Bewerben Sie sich als Partneranwalt bei Legalbird" />
        <meta
          name="keywords"
          content="Partneranwalt, Legalbird, Vertragsrecht, Scheidung, Verkehrsrecht, Familienrecht, Arbeitsrecht"
        />
        <meta name="robots" content="noindex,nofollow" />
        <meta property="og:title" content="Partneranwalt werden" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Bewerben Sie sich als Partneranwalt bei Legalbird" />
      </Helmet>
      <TeaserWithCards
        headline={"Legalbird Partneranwalt werden"}
        subheadline={"Sie bringen Ihre Fähigkeiten, wir die Aufträge."}
        hasTeaserButton={false}
      >
        <LinkedCardBox
          linkedCards={[
            {
              image: flexibilityImage,
              heading: "Flexibilität",
              subHeading: "Sie entscheiden, wann, von wo und wie viel Sie arbeiten",
            },
            {
              image: varietyImage,
              heading: "Abwechslung",
              subHeading: "Schriftsätze, Fälle prüfen und vieles mehr",
            },
            {
              image: transparencyImage,
              heading: "Transparenz",
              subHeading: "Sie wissen immer, wie viel Sie pro Aufgabe verdienen",
            },
          ]}
        />
      </TeaserWithCards>
      <GreyBackgroundWrapper>
          <ExternalLawyerApplicationSection/>
      </GreyBackgroundWrapper>
    </Layout>
  );
}

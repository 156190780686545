import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  heading: {
    textAlign: "center",
  },
  hintBox: {
    paddingBottom: 0,
  },
}));

export default useStyles;

const alimonyPerson = {
  alimony: {
    label: "Monatlicher Betrag",
    question: "Falls Sie einen monatlichen Geldbetrag bezahlen: Wie viel Unterhalt zahlen Sie pro Monat?",
  },
  income: { label: "Monatliche Einnahmen" },
};

const vehicle = {
  value: {
    label: "Verkehrswert",
    helperText:
      "Gemeint ist dabei der Preis, der jetzt bei einem Verkauf erzielt werden kann. Nutzen Sie z.B. www.autoscout24.de um den Preis schätzen zu können.",
  },
};

const valuable = {
  value: {
    label: "Verkehrswert",
    helperText: "Gemeint ist dabei der Preis, der jetzt bei einem Verkauf erzielt werden kann. Geben Sie hier ruhig eine Schätzung ab.",
  },
};

const pensionInsurance = {
  value: {
    label: "Rückkaufswert",
    helperText:
      "Den aktuellen Rückkaufswert finden Sie in Ihrer jährlichen Übersicht, die Sie von der Versicherung erhalten, oder Sie erfragen den Wert bei Ihrem Versicherungsvertreter.",
  },
};

const otherAsset = {
  value: {
    label: "Verkehrswert",
    helperText: "Gemeint ist dabei der Preis, der jetzt bei einem Verkauf erzielt werden kann oder der Rückkaufswert. Geben Sie hier ruhig eine Schätzung ab.",
  },
};

const propertyLoan = {
  outstandingDebt: {
    label: "Aktuelle Restschuld",
  },
  repayment: {
    label: "Zinsen und Tilgung pro Monat",
  },
};

const paymentObligation = {
  outstandingDebt: {
    label: "Aktuelle Restschuld",
  },
  repayment: {
    label: "Zinsen und Tilgung pro Monat",
  },
  petitionerShare: {
    label: "Ich zahle davon",
  },
};
const specialFinancialBurden = { petitionerShare: { label: "Ich zahle" } };
const processCostSupportTranslations = {
  alimonyPersons: [alimonyPerson, alimonyPerson, alimonyPerson, alimonyPerson, alimonyPerson], // client can have up to five people they're paying alimony for
  incomeEmploymentAmount: { label: "Mtl. Bruttobetrag" },
  incomeSelfEmploymentAmount: {
    question: "Bitte geben Sie hier die durchschnittlichen monatlichen Einnahmen in brutto an.",
    label: "Mtl. Bruttobetrag",
  },
  incomeRentAmount: { label: "Mtl. Bruttobetrag" },
  incomeAssetsAmount: { label: "Mtl. Bruttobetrag" },
  incomeChildAllowanceAmount: { label: "Mtl. Bruttobetrag" },
  incomeLivingAmount: { label: "Mtl. Bruttobetrag" },
  incomeAlimonyAmount: {
    label: "Mtl. Bruttobetrag",
    question:
      "Bitte geben Sie hier Ihren monatlichen Unterhalt an. Wenn Sie Unterhalt für sich und Ihre Kinder bekommen, geben Sie hier nur den Betrag an, der für Sie bestimmt ist.",
  },
  incomePensionAmount: {
    label: "Mtl. Bruttobetrag",
    question: "Bitte geben Sie an, wie viel Rente Sie monatlich erhalten.",
  },
  incomeUnemploymentAllowanceAmount: {
    label: "Mtl. Bruttobetrag",
    question: "Bitte geben Sie an, wie viel Arbeitslosengeld I Sie monatlich erhalten.",
  },
  incomeUnemploymentAllowance2Amount: {
    label: "Mtl. Bruttobetrag",
    question: "Bitte geben Sie an, wie viel Arbeitslosengeld II Sie monatlich erhalten.",
  },
  incomeSicknessAmount: {
    label: "Mtl. Bruttobetrag",
    question: "Bitte geben Sie an, wie viel Krankengeld Sie monatlich erhalten.",
  },
  incomeParentalAllowanceAmount: {
    label: "Mtl. Bruttobetrag",
    question: "Bitte geben Sie an, wie viel Elterngeld Sie monatlich erhalten.",
  },
  costCoverageAmount: {
    label: "Betrag",
    question: "In welcher Höhe werden die Kosten übernommen?",
  },
  incomeOther1Amount: {
    label: "Bruttobetrag",
  },
  incomeOther2Amount: {
    label: "Bruttobetrag",
  },
  realEstate1Value: {
    label: "Verkehrswert",
    helperText:
      "Gemeint ist dabei der Preis, der jetzt bei einem Verkauf erzielt werden kann. Gegebenenfalls kann Ihnen Ihre kreditgebende Bank Anhaltspunkte dafür nennen.",
  },
  cashAmount: {
    label: "Betrag",
    helperText: "Wenn Sie kein Bargeld besitzen, geben Sie einfach '0' an.",
  },
  vehicles: [vehicle, vehicle, vehicle, vehicle, vehicle],
  valuables: [valuable, valuable, valuable, valuable, valuable],
  pensionInsurances: [pensionInsurance, pensionInsurance, pensionInsurance, pensionInsurance, pensionInsurance],
  otherAssets: [otherAsset, otherAsset, otherAsset, otherAsset, otherAsset],
  taxAmount: {
    label: "Mtl. Abzüge",
  },
  socialSecurityAmount: {
    label: "Mtl. Abzüge",
  },
  otherInsuranceAmount: {
    label: "Mtl. Abzüge",
  },
  mobilityCostAmount: {
    label: {
      "€": "Mtl. Kosten in €",
      km: "km pro Strecke",
    },
  },
  otherExpensesAmount: {
    label: "Kosten pro Monat im Schnitt",
  },
  heatingCosts: {
    label: "Heizungskosten",
  },
  additionalCosts: {
    label: "Übrige Nebenkosten",
  },
  basicRentOrRepayment: {
    label: {
      rent: "Miete ohne Nebenkosten",
      owner: "Zinsen und Tilgung",
    },
  },
  costPaidByPetitioner: {
    label: "Ich zahle davon",
  },
  propertyLoans: [propertyLoan, propertyLoan, propertyLoan, propertyLoan, propertyLoan],
  paymentObligations: [paymentObligation, paymentObligation, paymentObligation, paymentObligation, paymentObligation],
  specialFinancialBurdens: [specialFinancialBurden, specialFinancialBurden],
};

export default processCostSupportTranslations;

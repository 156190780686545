import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import documentCheckinStyle from "./documentCheckinStyle";
import * as loginActions from "../../../store/login/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { getLpByLocation } from "../../../services/landingPagesList";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { FormProvider } from "../../../provider/Form/FormProvider";
import DocumentCheckinForm from "./DocumentCheckinForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys } from "../../../services/reactQuery/reactQueryService";

const useStyles = makeStyles(documentCheckinStyle);
export default function DocumentCheckin({ initialProduct, partner, checkinHeading, idPrefix, buttonText, target, color }) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data)
  });

  const initialValues = {
    email: "",
    numberOfEmployees: "",
  };

  const handleSubmit = async ({ values }) => {
    setIsLoading(true);
    const { from } = location.state || { from: { pathname: target } };
    const emailCheck = await loginActions.checkEmail(values.email.toLowerCase());
    if (emailCheck.hasError) {
      setIsLoading(false);
      setEmailErrorText(emailCheck.errorText);
      return;
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };
    const data = {
      email: values.email.toLowerCase(),
      gclid: localStorage.getItem("gclid"),
      person: {
        gender: values.gender,
        givenName: values.givenName,
        familyName: values.familyName,
      },
      prelead: {
        initialProduct: initialProduct,
        landingPage: landingPage.keyword,
      },
    };

    await loginActions.register(data);
    await createMutation.mutateAsync({
      uri: "/short_time_works",
      data: {
        acquisitionPartner: partner,
      },
    });
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate(from.pathname);
  };

  return (
    <Paper elevation={4} className={classNames(classes.formPaper, classes[color])}>
      <div id={idPrefix + "-checkin"} className={classes.scrollToAnchor} />
      <Typography variant={"h4"}>{checkinHeading}</Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <DocumentCheckinForm buttonText={buttonText} isLoading={isLoading} emailErrorText={emailErrorText} setEmailErrorText={setEmailErrorText} />
      </FormProvider>
    </Paper>
  );
}

DocumentCheckin.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["default", "grey"]),
  checkinHeading: PropTypes.string.isRequired,
  target: PropTypes.string,
  priceHint: PropTypes.string,
  initialProduct: PropTypes.oneOf(["shortTimeWork"]),
  buttonText: PropTypes.string,
  partner: PropTypes.string,
};

DocumentCheckin.defaultProps = {
  color: "default",
  checkinHeading: "Hier können Sie professionell vorsorgen!",
  target: "/servicewelt",
  initialProduct: "shortTimeWork",
  buttonText: "Jetzt kostenlos starten",
};

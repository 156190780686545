import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";

const DebtCollectionAgencyData = ({ formData, formPath, title }) => {
  const classes = useStyles({});
  const section = "debtCollectionAgencyData";

  return (
    <>
      <Grid item xs={12} className={classes.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.question}>
        <div id={"name"} className={classes.scrollToAnchor} />
        Name Inkassobüro
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "name",
          },
        }}
      >
        {formData.debtCollectionAgency.name}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"debtCollectionAgencyReference"} className={classes.scrollToAnchor} />
        Aktenzeichen Inkassobüro
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "debtCollectionAgencyReference",
          },
        }}
      >
        {formData.debtCollectionAgencyReference}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"streetAddress"} className={classes.scrollToAnchor} />
        Straße und Hausnummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "streetAddress",
          },
        }}
      >
        {formData.debtCollectionAgency.address.streetAddress}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"postalCode"} className={classes.scrollToAnchor} />
        Postleitzahl
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "postalCode",
          },
        }}
      >
        {formData.debtCollectionAgency.address.postalCode}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"addressLocality"} className={classes.scrollToAnchor} />
        Ort
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "addressLocality",
          },
        }}
      >
        {formData.debtCollectionAgency.address.addressLocality}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"email"} className={classes.scrollToAnchor} />
        E-Mail-Adresse
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "email",
          },
        }}
      >
        {formData.debtCollectionAgency.email}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"telephone"} className={classes.scrollToAnchor} />
        Telefonnummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "telephone",
          },
        }}
      >
        {formData.debtCollectionAgency.telephone}
      </GridEditRow>
    </>
  );
};

DebtCollectionAgencyData.propTypes = {
  formData: PropTypes.object.isRequired,
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DebtCollectionAgencyData;

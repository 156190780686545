import Grid from "@mui/material/Grid";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import EmailCheck from "../../EmailCheck/EmailCheck";
import classNames from "classnames";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import makeStyles from "@mui/styles/makeStyles";
import documentCheckinStyle from "./documentCheckinStyle";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import { emailDefault, minStringLength, requiredValidator } from "../../../services/validationRules";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles(documentCheckinStyle);
export default function DocumentCheckinForm({ isLoading, emailErrorText, setEmailErrorText, buttonText }) {
  const { handleSubmit } = useForm();
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12} md={4} className={classes.gridSpaceMd}>
          <ValidatorSelectField
            label="Anrede"
            name={`gender`}
            validators={[requiredValidator("Bitte geben Sie Ihre Anrede an.")]}
          >
            <MenuItem value={"female"}>Frau</MenuItem>
            <MenuItem value={"male"}>Herr</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridSpace}>
          <ValidatorTextField
            name={`givenName`}
            label="Vorname"
            validators={[
              requiredValidator("Bitte geben Sie den Vornamen an"),
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name={`familyName`}
            label="Nachname"
            validators={[
              requiredValidator("Bitte geben Sie den Nachnamen an"),
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classNames(classes.submit, classes.gridTopSpaced)}>
          <ButtonLoading
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            fullWidth
            isLoading={isLoading}
          >
            {buttonText}
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}

import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import gradientPaperStyle from "./gradientPaperStyle";
import { Paper } from "@mui/material";
import classNames from "classnames";


const useStyles = makeStyles(gradientPaperStyle);

export default function GradientPaper({children, additionalClasses, ...rest}) {

  const classes = useStyles();

  return (
    <Paper className={classNames(classes.background, additionalClasses)} {...rest}>
      {children}
    </Paper>
  );
};

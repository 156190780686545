import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../Layouts/Layout";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../../../services/apiClient";
import { STATUS_DATA_COMPLETED } from "../../../../services/contractLaw/leadStatus";
import PowerOfAttorney from "../../../../components/Form/ContractLaw/PowerOfAttorney";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import { fromGender } from "../../../../services/formServiceFunctions";
import Button from "@mui/material/Button";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";

const PowerOfAttorneyPageContractLaw = () => {
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { customer } = useCustomer();
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const formService = getProductData(productClassName, "formService");
  const queryClient = useQueryClient();

  if (isLoading) {
    return (
      <Layout active={"serviceworld"}>
        <Container>
          <Container style={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  if (isComplete || product.productCategory !== "contractLaw" || product < STATUS_DATA_COMPLETED || product.powerOfAttorney !== null) {
    return (
      <Layout active={"serviceworld"}>
        <Container>
          <HintBox headline={"Vielen Dank!"}>
            <p>Wir haben Ihre Vollmacht gespeichert und bearbeiten Ihren Fall entsprechend weiter.</p>
            <Button variant={"text"} color={"primary"} component={Link} to={getCaseLink(product)} fullWidth>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  const handleSubmit = async () => {
    setIsLoadingInternal(true);
    await apiCall("PATCH", productApiUri + "/" + product.id + "/power_of_attorney", {
      body: JSON.stringify({
        powerOfAttorney: null,
      }),
    });
    setIsLoadingInternal(false);
    setIsComplete(true);
    await queryClient.invalidateQueries([productApiUri, productId]);
  };

  return (
    <FormProvider initialValues={formService.prepareFormData(product, customer)} submitAction={handleSubmit}>
      <Layout product={product.name} active={"serviceworld"}>
        <Helmet>
          <title>Legalbird | Vollmacht </title>
        </Helmet>
        <Container>
          <HintBox headline={"Bitte erteilen Sie Ihre Vollmacht"}>
            <p>
              Achtung: Auf dieser Vollmacht muss{" "}
              <strong>
                {fromGender(product.client.gender)} {product.client.givenName} {product.client.familyName}
              </strong>{" "}
              unterzeichnen damit wir weiter tätig werden können.
            </p>
          </HintBox>
          <PowerOfAttorney product={product} submitProps={{ isLoading: isLoadingInternal, isMinimal: true }} />
        </Container>
      </Layout>
    </FormProvider>
  );
};
export default PowerOfAttorneyPageContractLaw;

import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import classNames from "classnames";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import useStyles from "./contractCheckinStyle";
import { emailDefault, requiredValidator } from "../../../services/validationRules";

export default function ContracCheckinForm({ emailErrorText, setEmailErrorText, isLoading }) {
  const { handleSubmit } = useForm();
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12} md={4} className={classes.gridSpaceMd}>
          <ValidatorSelectField
            label="Art des Vertrags"
            name={`contractType`}
            validators={[requiredValidator("Bitte geben Sie die Art des Vertrages an.")]}
          >
            <MenuItem value={"purchaseContract"}>Kaufvertrag</MenuItem>
            <MenuItem value={"vehicleContract"}>Fahrzeugkauf</MenuItem>
            <MenuItem value={"serviceContract"}>Handwerker / Dienstleistung</MenuItem>
            <MenuItem value={"membershipContract"}>Mitgliedschaft</MenuItem>
            <MenuItem value={"subscriptionContract"}>Abo / Abofalle</MenuItem>
            <MenuItem value={"providerContract"}>Handy / DSL / Strom-Vertrag</MenuItem>
            <MenuItem value={"travelContract"}>Reisevertrag</MenuItem>
            <MenuItem value={"eventContract"}>Veranstaltung</MenuItem>
            <MenuItem value={"otherContract"}>Sonstiger Vertrag</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridSpace}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classNames(classes.submit, classes.gridTopSpaced)}>
          <ButtonLoading
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            fullWidth
            isLoading={isLoading}
          >
            jetzt starten
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}

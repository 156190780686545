import React, { useState } from "react";
import _last from "lodash/last";
import _head from "lodash/head";
import * as messagesActions from "../../../../store/messages/actions";
import { fileCheck, saveMediaObject } from "../../../../services/formServiceFunctions";
import FileUploadForm from "./FileUploadForm";
import _filter from "lodash/filter";
import PropTypes from "prop-types";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import { useMutation } from "@tanstack/react-query";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";
import { updateResource } from "../../../../services/reactQuery/reactQueryService";
import _ from "lodash";
import {isAllowMultiplePagesFileType} from "../../../../services/mediaObjects";

//most things connected to the message file upload are just copied components that were slightly manipulated.
//this was agreed upon since all file uploads should be refactored anyway.
//this might turn into a generic upload component later on. "FileUploadSection" should be deleted then.
//but the whole file array name thing shouldn't be a case field anymore but a new table with file combination request rows
const MessagesAttachmentUploadSection = ({
  productEntity,
  fileIndex,
  setIsBlocked,
  isBlocked,
  description,
  product,
  productEntityId,
  updateAction,
  fieldName,
  messagePath,
  withUploadNotification,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filePath, setFilePath] = useState("");

  const updateMutation = useMutation(updateResource);
  const createMediaObjectMutation = useMutation(createMediaObject);

  const mediaObjectCollection = _.get(productEntity.temporaryFilesToCombineData, `${messagePath}[${fileIndex}].files`, []);
  const resetState = () => {
    setFilePath("");
    setIsLoading(false);
    setIsBlocked(false);
  };

  const handleChange = async ({ target }) => {
    if (mediaObjectCollection.length > 0 && !isAllowMultiplePagesFileType(target.files[0].name)) {
      messagesActions.addMessage({
        type: "error",
        text: "Die Datei kann leider nicht hochgeladen werden. Sie können entweder einzelne Dateien auswählen oder alternativ mehrere Bilder (JPG/PNG), die dann zu einem PDF Dokument zusammengefügt werden.",
      });
      resetState();
      return;
    }

    const fileCheckRes = fileCheck(target, true);
    if (fileCheckRes.hasError === true) {
      messagesActions.addMessage({ type: "error", text: fileCheckRes.errorMsg });
      return;
    }

    const file = _head(target.files);
    setFilePath(file.name);
    await uploadFile(file);
  };

  const uploadFile = async (file) => {
    setIsLoading(true);
    setIsBlocked(true);

    const mediaObject = await saveMediaObject(createMediaObjectMutation, {
      file: file,
      fieldname: "temporaryFilesToCombineData",
      description: description,
      product: product,
      productId: productEntityId || productEntity?.id,
    });

    if (mediaObject === false) {
      resetState();
      return;
    }

    let files = _cloneDeep(mediaObjectCollection);
    mediaObject.thumbnail = null;
    files.push(mediaObject);
    const splitMessagePath = messagePath.split(".");

    let temporaryFilesToCombineDataClone = _cloneDeep(productEntity.temporaryFilesToCombineData);
    _set(temporaryFilesToCombineDataClone, [...splitMessagePath, fileIndex, "files"], files);
    _set(temporaryFilesToCombineDataClone, [...splitMessagePath, fileIndex, "description"], description);
    _set(temporaryFilesToCombineDataClone, [...splitMessagePath, fileIndex, "fieldname"], fieldName);

    await updateAction(productEntity.id, {
      temporaryFilesToCombineData: temporaryFilesToCombineDataClone,
    });

    resetState();
  };

  const deleteMediaObject = async (mediaObjectToDelete) => {
    let temporaryFilesToCombineDataClone = _cloneDeep(productEntity.temporaryFilesToCombineData);
    const files = _filter(mediaObjectCollection, (mediaObject) => mediaObject.id !== mediaObjectToDelete.id);
    _set(temporaryFilesToCombineDataClone, [...(messagePath.split(".")), fileIndex, "files"], files);

    await updateMutation.mutateAsync({ uri: "/media_objects", id: mediaObjectToDelete.id, data: { deleted: true } });
    await updateAction(productEntity.id, { temporaryFilesToCombineData: temporaryFilesToCombineDataClone });
  };

  return (
    <FileUploadForm
      mediaObjectCollection={mediaObjectCollection}
      isLoading={isLoading}
      deleteMediaObject={deleteMediaObject}
      handleFileChange={handleChange}
      fieldname={fieldName}
      allowMore={mediaObjectCollection.length === 0 || isAllowMultiplePagesFileType(_last(mediaObjectCollection).originalName)}
      filePath={filePath}
      isBlocked={isBlocked}
      {...rest}
    />
  );
};
MessagesAttachmentUploadSection.propTypes = {
  description: PropTypes.string,
};

MessagesAttachmentUploadSection.defaultProps = {
  description: "Hochgeladene Datei",
  fileIndex: 0,
};

export default MessagesAttachmentUploadSection;

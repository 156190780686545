import React from "react";
import useStyles from "./serviceWorldStyle";
import EmailConfirmationBox from "./Common/EmailConfirmationBox";
import ServiceWorldMyCases from "./MyCases/ServiceWorldMyCases";
import ServiceWorldNewCase from "./ServiceWorldNewCase";
import ActiveCaseInquiry from "./MyCases/ActiveCaseInquiry";
import { getCaseLink } from "../../services/productService";

const ServiceWorld = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <EmailConfirmationBox />
      <ServiceWorldMyCases NotificationComponent={ActiveCaseInquiry} listItemButtonConfig={{getLink: (minimalCase) => getCaseLink(minimalCase)}} />
      <ServiceWorldNewCase />
    </div>
  );
};

export default ServiceWorld;

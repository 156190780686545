import Iconize from "../../../../SvgIcon/Iconize";
import CheckSvg from "../../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../../assets/icons/common/active/kreuz.svg";
import Grid from "@mui/material/Grid";
import React from "react";
import Group from "../../../../Wizard/Group";
import { requiredValidator } from "../../../../../services/validationRules";

const ClientAgrees = ({ product }) => {
  if (product.extrajudicialReaction !== "fullyAgreed") {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Group
        type={"radio"}
        name={"clientAgrees"}
        question={"Ist die Reaktion der Gegenseite für Sie zufriedenstellend?"}
        options={[
          {
            labelText: "Ja",
            labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
            labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
            value: "yes",
            optionHintText:
              "Hier geht es zunächst nur darum, dass die Reaktion der Gegenseite in Ihrem Sinne ist. Sollten noch Einzelheiten zur vollständigen Abwicklung des Falls zwischen Ihnen und der Gegenseite geklärt werden müssen, können Sie dies natürlich noch im Nachgang tun.",
          },
          {
            labelText: "Nein",
            labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
            labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
            value: "no",
          },
        ]}
        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
      />
    </Grid>
  );
};
export default ClientAgrees;

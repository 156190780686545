import React from "react";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import wizardStyle from "../../wizardStyle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { requiredValidator } from "../../../../services/validationRules";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import InputAdornment from "@mui/material/InputAdornment";
import infoIcon from "../../../../assets/icons/serviceOverview/icon_info.svg";
import DialogModal from "../../../DialogModal/DialogModal";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useForm } from "../../../../provider/Form/FormProvider";
import Person from "../../Common/Person/Person";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";

const useStyles = makeStyles(wizardStyle);

export default function AlimonyPerson({ personType, submitProps, product }) {
  const classes = useStyles();
  const { values, handleSubmit } = useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const modalOpen = (modalType) => {
    navigate(location.pathname, { state: { rentModalOpen: modalType } });
  };
  const modalClose = () => {
    navigate(-1);
  };
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Typography variant={"h5"} className={classes.headline}>
            {personType === "alimonyPetitioner" ? "Ihre finanzielle Situation" : "Finanzielle Situation Ihres Ex-Partners"}
          </Typography>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            {personType === "alimonyPartner" && <Person path="alimonyPartner.person" headline="Wie heißt Ihr Ex-Partner?" />}
            <Grid item xs={12} className={classes.gridTopSpaced}>
              <FormLegend>
                {personType === "alimonyPetitioner"
                  ? "In welcher finanziellen Situation befinden Sie sich?"
                  : "In welcher finanziellen Situation befindet sich Ihr Ex-Partner?"}
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={personType === "alimonyPetitioner" ? "Sind Sie erwerbstätig?" : "Ist Ihr Ex-Partner erwerbstätig?"}
                name={`${personType}.isEmployed`}
                validators={[requiredValidator("Bitte geben Sie an, ob die Person erwerbstätig ist.")]}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value={"yes"}>Ja</MenuItem>
                <MenuItem value={"no"}>Nein</MenuItem>
              </ValidatorSelectField>
            </Grid>
            <NumberFieldConfigured
              caseData={product}
              fieldPath={`${personType}.netIncome`}
              fieldType={"money"}
              numberType={"float"}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={infoIcon} alt={"(i)"} className={classes.info} onClick={() => modalOpen("netIncome")} />
                  </InputAdornment>
                ),
              }}
            />
            <NumberFieldConfigured
              caseData={product}
              fieldPath={`${personType}.otherIncome`}
              fieldType={"money"}
              numberType={"float"}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={infoIcon} alt={"(i)"} className={classes.info} onClick={() => modalOpen("otherIncome")} />
                  </InputAdornment>
                ),
              }}
            />

            {values.basicChoice === "complete" && (
              <Grid item xs={12}>
                <ValidatorSelectField
                  label="Liegen ehebedingte Nachteile vor?"
                  name={`${personType}.hasMarriageRelatedDisadvantages`}
                  validators={[requiredValidator("Bitte geben Sie an, ob ehebedingte Nachteile vorliegen.")]}
                  helperText={'Sollten Sie dies nicht genau wissen, geben Sie "Nein" an.'}
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={infoIcon} alt={"(i)"} className={classes.info} onClick={() => modalOpen("hasMarriageRelatedDisadvantages")} />
                    </InputAdornment>
                  }
                  classes={{ icon: classes.icon }}
                >
                  <MenuItem value={"yes"}>Ja</MenuItem>
                  <MenuItem value={"no"}>Nein</MenuItem>
                </ValidatorSelectField>
              </Grid>
            )}
            <NumberFieldConfigured
              caseData={product}
              fieldPath={`${personType}.livingExpenses`}
              fieldType={"money"}
              numberType={"float"}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={infoIcon} alt={"(i)"} className={classes.info} onClick={() => modalOpen("livingExpenses")} />
                  </InputAdornment>
                ),
              }}
            />

            <NumberFieldConfigured
              caseData={product}
              fieldPath={`${personType}.otherExpenses`}
              fieldType={"money"}
              numberType={"float"}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={infoIcon} alt={"(i)"} className={classes.info} onClick={() => modalOpen("otherExpenses")} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
      <DialogModal
        open={!!(location.state && location.state.rentModalOpen === "hasMarriageRelatedDisadvantages")}
        onClose={modalClose}
        title={"Was sind ehebedingte Nachteile?"}
      >
        <p>
          Ein ehebedingter Nachteil entsteht meistens, wenn ein Ehepartner, der vor der Ehe berufstätig war, während der Ehe die Haushaltsführung und die
          Kinderbetreuung übernimmt und dadurch nicht mehr arbeiten geht. Er gibt damit seine berufliche Karriere und die damit verbundenen
          Einkommenssteigerungen auf. Wenn er nach der Ehe wieder berufstätig wird, fängt er sozusagen von vorne an. Er verdient jedenfalls deutlich weniger,
          als wenn er während der Ehe die gesamte Zeit durchgearbeitet hätte. In einem solchen Fall kann ein ehebedingter Nachteil vorliegen.
        </p>
      </DialogModal>
      <DialogModal open={!!(location.state && location.state.rentModalOpen === "netIncome")} onClose={modalClose} title={"Was bedeutet Nettoeinkommen?"}>
        <p>
          Das monatliche Nettoeinkommen können Sie z.B. der letzten Gehaltsabrechnung entnehmen. Es ist das Einkommen nach Steuern, Kranken- und
          Sozialversicherung, also der Betrag, der auch auf das Konto überwiesen wird. Wenn Sie monatlich starke Gehaltsschwankungen haben, tragen Sie bitte den
          Durchschnitt aus den letzten 12 Monaten ein. Einkommen sind z.B. auch Arbeitslosengeld, Ausbildungsvergütung, Renten, BAföG, Arbeitnehmersparzulage,
          Krankengeld, Kurzarbeitergeld, Provisionen, etc.
        </p>
      </DialogModal>
      <DialogModal
        open={!!(location.state && location.state.rentModalOpen === "otherIncome")}
        onClose={modalClose}
        title={"Was ist sonstiges (Natural)einkommen?"}
      >
        <p>
          Sonstiges Einkommen können z.B. Einnahmen aus Miete, Verpachtung, Zinsen, etc. sein. Naturaleinkommen ist dann gegeben, wenn{" "}
          {personType === "alimonyPetitioner" ? " Sie mietfrei wohnen" : " Ihr Partner mietfrei wohnt"} (z.B. im eigenen Haus). Dann tragen Sie hier bitte die
          Höhe der Miete ein, die man normalerweise für diese Wohnfläche bekommen würde, wenn man sie an jemand anderes vermieten würde.
        </p>
      </DialogModal>
      <DialogModal open={!!(location.state && location.state.rentModalOpen === "livingExpenses")} onClose={modalClose} title={"Was zählt zur Warmmiete?"}>
        <p>In die Warmmiete zählt die Kaltmiete plus umlagefähige Nebenkosten (Hausmeister, Aufzug, Winterdienst, etc.) und die Heizkosten.</p>
      </DialogModal>
      <DialogModal open={!!(location.state && location.state.rentModalOpen === "otherExpenses")} onClose={modalClose} title={"Was sind sonstige Ausgaben?"}>
        <p>
          Sonstige Ausgaben sind monatliche Ausgaben, die laut Gesetz das unterhaltsrelevante Einkommen mindern. Die gängigsten Ausgaben in diesem Bereich sind
          z.B. Rückzahlung von Schulden (z.B. für das Haus), berufsbedinge Aufwendungen (z.B. 30 Cents Entfernungspauschale vom Arbeitsplatz), Haftpflicht- und
          Arbeitslosenversicherung. Bitte tragen Sie hier die Summe aller monatlichen sonstigen Ausgaben ein.
        </p>
      </DialogModal>
    </Paper>
  );
}

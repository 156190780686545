import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../Common/Fader/FormFader";
import classNames from "classnames";
import { Grid, Hidden } from "@mui/material";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import Group from "../../Wizard/Group";
import Iconize from "../../SvgIcon/Iconize";
import PersonSvg from "../../../assets/icons/provision/inactive/person.svg";
import PersonSvgActive from "../../../assets/icons/provision/active/person.svg";
import LegalEntitySvg from "../../../assets/icons/contract/inactive/icon_firma.svg";
import LegalEntitySvgActive from "../../../assets/icons/contract/active/icon_firma_active.svg";
import Address from "../Common/Address/Address";
import Person from "../Common/Person/Person";
import { emailDefault, isPhoneNumberDefault, requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import PostOfficeBoxAddress from "../Common/Address/PostOfficeBoxAddress";

const useStyles = makeStyles(wizardStyle);
export default function ContractPartner({ submitProps }) {
  const classes = useStyles();
  const { values, handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Angaben zum Vertragspartner / gegnerische Partei</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>
                Damit wir rechtliche Schritte einleiten können, benötigen wir möglichst ausführliche Angaben zum Vertragspartner bzw. zu der gegnerischen
                Partei, gegen die Sie rechtlich vorgehen möchten.
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="contractualPartnerType"
                question="Handelt es sich um ein Unternehmen oder eine Privatperson?"
                options={[
                  {
                    labelText: "Unternehmen",
                    labelIcon: <Iconize src={LegalEntitySvg} alt={"Ja"} />,
                    labelIconActive: <Iconize src={LegalEntitySvgActive} alt={"Ja"} />,
                    value: "contractualPartnerLegalEntity",
                  },
                  {
                    labelText: "Privatperson",
                    labelIcon: <Iconize src={PersonSvg} alt={"Nein"} />,
                    labelIconActive: <Iconize src={PersonSvgActive} alt={"Nein"} />,
                    value: "contractualPartnerPerson",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            {values.contractualPartnerType === "contractualPartnerLegalEntity" && (
              <>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Wie heißt das Unternehmen, gegen das Sie rechtlich vorgehen möchten?</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerLegalEntity.legalName"
                    label="Name des Unternehmens"
                    helperText='Bitte geben Sie die vollständige Firma an, z.B. "Fitness First Germany GmbH". Diese finden Sie z.B. in Ihrem Vertrag.'
                    validators={[requiredValidator("Damit wir wissen, gegen wen wir rechtlich vorgehen müssen, benötigen wir den Namen des Vertragspartners.")]}
                  />
                </Grid>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Bitte geben Sie möglichst alle weiteren Daten an, die Ihnen vorliegen, damit wir für Sie tätig werden können.</FormLegend>
                </Grid>
                <Address path="contractualPartnerLegalEntity.address" headline={""} fieldsAreRequired={false} />
                <PostOfficeBoxAddress path="contractualPartnerLegalEntity.address" headline={""} fieldsAreRequired={false} />
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerLegalEntity.email"
                    label="E-Mail-Adresse Vertragspartner"
                    autoComplete="email"
                    validators={[emailDefault]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerLegalEntity.telephone"
                    label="Telefonnummer Vertragspartner"
                    autoComplete="tel"
                    validators={isPhoneNumberDefault}
                  />
                </Grid>
              </>
            )}
            {values.contractualPartnerType === "contractualPartnerPerson" && (
              <>
                <Person path="contractualPartnerPerson" headline="Wie heißt die Person, gegen die Sie rechtlich vorgehen möchten?" />
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Bitte geben Sie möglichst alle weiteren Daten an, die Ihnen vorliegen, damit wir für Sie tätig werden können.</FormLegend>
                </Grid>
                <Address path="contractualPartnerPerson.residenceAddress" headline="" fieldsAreRequired={false} />
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerPerson.email"
                    label="E-Mail-Adresse Vertragspartner"
                    autoComplete="email"
                    validators={[emailDefault]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="contractualPartnerPerson.telephone"
                    label="Telefonnummer Vertragspartner"
                    autoComplete="tel"
                    validators={isPhoneNumberDefault}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}

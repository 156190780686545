import { useForm } from "../../provider/Form/FormProvider";
import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { formValue } from "../../services/formServiceFunctions";
import _get from "lodash/get";

type ValidatorSelectFieldProps = {
  children: any;
  name: string;
  label: string;
  validators?: Array<any>;
  dependentValidationFields?: Array<string>
  helperText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  margin?: "normal" | "dense";
};
export default function ValidatorSelectField({
  children,
  name,
  label,
  helperText,
  validators = [],
  dependentValidationFields = [],
  fullWidth = true,
  margin = "normal",
  disabled = false,
  ...rest
}: ValidatorSelectFieldProps) {
  const { values, errors, setError, handleChange, handleBlur, registerValidators }: any = useForm();
  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    }
  }, [name]);

  return (
    <FormControl fullWidth={fullWidth} margin={margin} error={error} disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        value={formValue(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
      >
        {children}
      </Select>
      <FormHelperText>{_get(errors, name) || helperText}</FormHelperText>
    </FormControl>
  );
}

const headlineSectionStyle = (theme) => ({
  centeredText: {
    textAlign: "center",
  },
  image: {
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
    height: "auto",
    margin: "2rem auto 0 auto",
    display: "block",
  },
  smallImage: {
    width: 280,
    height: "auto",
    margin: "2rem auto 0 auto",
    display: "block",
  },
});

export default headlineSectionStyle;

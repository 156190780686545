import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./calendlyStyle";
import _get from "lodash/get";
import _includes from "lodash/includes";
import ContentBox from "../Box/ContentBox/ContentBox";
import callIcon from "../../assets/img/generic/call_clock_100px.png";
import moment from "moment";
import { Button, Link } from "@mui/material";
import { HashLink } from "react-router-hash-link";

const CalendlyAppointment = ({ calendlyData, customer }) => {
  const classes = useStyles({});

  if (
    !_get(calendlyData, "appointment") ||
    !customer ||
    _includes(["processed", "not_processed"], _get(calendlyData, "status"))
  ) {
    return null;
  }

  return (
    <ContentBox headline={"Mein Beratungstermin"} icon={callIcon}>
      <div>
        Sie haben einen Termin für den {moment(calendlyData.appointment.date).format("DD.MM.YYYY")} um{" "}
        {moment(calendlyData.appointment.date).format("HH:mm")} Uhr gebucht und werden unter der Nummer{" "}
        {customer.telephone} angerufen.
      </div>
      <Button
        component={Link}
        href={calendlyData.rescheduleUrl}
        target="_blank"
        className={classes.appointmentBoxbutton}
        color={"primary"}
        fullWidth
      >
        Termin verschieben
      </Button>
      <Button
        component={Link}
        href={calendlyData.cancelUrl}
        target="_blank"
        className={classes.appointmentBoxbutton}
        color={"primary"}
        fullWidth
      >
        Termin absagen
      </Button>
      <Button
        component={HashLink}
        to={"/servicewelt/mein-konto#personalData"}
        className={classes.appointmentBoxbutton}
        color={"primary"}
        fullWidth
      >
        Telefonnummer ändern
      </Button>
    </ContentBox>
  );
};

CalendlyAppointment.propTypes = {
  calendlyData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  customer: PropTypes.object.isRequired,
};

export default CalendlyAppointment;

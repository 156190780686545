import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import EmailCheck from "../../../EmailCheck/EmailCheck";
import classNames from "classnames";
import ButtonLoading from "../../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../../provider/Form/FormProvider";
import useStyles from "./divorcePartnerCheckinStyle";
import {
  emailDefault,
  isPhoneNumberDefault,
  minStringLength,
  requiredValidator,
} from "../../../../services/validationRules";

export default function DivorcePartnerCheckinForm({ isLoading, emailErrorText, setEmailErrorText }) {
  const { handleSubmit } = useForm();
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems={"baseline"} justifyContent={"center"}>
        <Grid item xs={12} sm={6} className={classes.gridSpaceMd}>
          <ValidatorSelectField
            label="Wie lange leben Sie getrennt?"
            name={"classification"}
            validators={[requiredValidator("Bitte geben Sie an wie lange Sie getrennt sind")]}
          >
            <MenuItem value={"easy"}>Länger als 10 Monate</MenuItem>
            <MenuItem value={"medium"}>Kürzer als 10 Monate</MenuItem>
            <MenuItem value={"hard"}>Noch nicht getrennt lebend</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorSelectField
            label={"Möchten Sie die Scheidung?"}
            name={"divorceWanted"}
            validators={[requiredValidator("Bitte geben Sie an ob Sie sich scheiden lassen möchten")]}
          >
            <MenuItem value={"yes"}>Ja</MenuItem>
            <MenuItem value={"no"}>Nein, ich habe ein anderes Anliegen</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridSpaceMd}>
          <ValidatorSelectField
            label="Anrede"
            name={`gender`}
            validators={[requiredValidator("Bitte geben Sie Ihre Anrede an.")]}
          >
            <MenuItem value={"female"}>Frau</MenuItem>
            <MenuItem value={"male"}>Herr</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridSpace}>
          <ValidatorTextField
            name={`givenName`}
            label="Vorname"
            validators={[
              requiredValidator("Bitte geben Sie den Vornamen an"),
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name={`familyName`}
            label="Nachname"
            validators={[
              requiredValidator("Bitte geben Sie den Nachnamen an"),
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridSpace}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridSpace}>
          <ValidatorTextField
            name="telephone"
            label="Ihre Telefonnummer"
            autoComplete="tel"
            validators={[requiredValidator("Bitte geben Sie Ihre Telefonnummer an"), ...isPhoneNumberDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name={`caseReferenceNumber`}
            label="Schadens-/Leistungsnummer"
            helperText={"Dies ist die Nummer, die Sie zu Ihrem aktuellen Anliegen erhalten haben."}
            validators={[requiredValidator("Bitte geben Sie Ihre Schadennummer an.")]}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classNames(classes.submit, classes.gridTopSpaced)}>
          <ButtonLoading
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            fullWidth
            isLoading={isLoading}
          >
            jetzt starten
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}

const partnerLogoSectionStyle = (theme) => ({
  divorceContainer: {
    textAlign: "center",
    paddingTop: "3rem",
  },
  settlementContainer: {
    textAlign: "center",
    paddingBottom: "2rem",
  },
  smallPaddingContainer: {
    textAlign: "center",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
  logo: {
    maxWidth: 300,
    maxHeight: 120,
  },
  logoText: {
    margin: 0,
  },
  logoGridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
  },
  logoGridItem: {
    margin: ".5rem",
  },
});

export default partnerLogoSectionStyle;

import React, { useState } from "react";
import useStyles from "./serviceWorldNewCaseStyle";
import ServiceWorldContentBox from "./ServiceWorldContentBox";
import headlineIcon from "../../assets/icons/generic/icon_antrag_erstellen_150px.png";
import { Divider, Grid, List, ListItem } from "@mui/material";
/*import patientDecreeIcon from "../../assets/icons/serviceOverview/icon_dokument_pv_centered_150px.png";
import provisionPowerOfAttorneyIcon from "../../assets/icons/serviceOverview/icon_dokument_vv_centered_150px.png";*/
import { Link } from "react-router-dom";
import { getProductData, productCategoryData } from "../../services/productService";
import DialogModal from "../DialogModal/DialogModal";
import { InfoOutlined } from "@mui/icons-material";

const ServiceWorldNewCase = () => {
  const classes = useStyles({});
  const [legalExpenseInsuranceModalOpen, setLegalExpenseInsuranceModalOpen] = useState(false);

  return (
    <div className={classes.newCaseContainer}>
      <ServiceWorldContentBox headline={"Neuen Fall anlegen"} icon={headlineIcon}>
        <List className={classes.bottomPadding}>
          <ListItem className={classes.productCategoryHeadline}>Familienrecht</ListItem>
          <ListItem button className={classes.productItem} component={Link} to={"/servicewelt/familienrecht/scheidung"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={getProductData("divorce", "productIcon")} className={classes.icon} alt={"Scheidung"} />
              </Grid>
              <Grid item>Scheidung</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img
                  src={getProductData("familyLawCase", "productIcon")}
                  className={classes.icon}
                  alt={"Erstberatung"}
                />
              </Grid>
              <Grid item>Erstberatung Familienrecht</Grid>
              <Grid item className={classes.infoIcon}>
                <InfoOutlined color={"inherit"} />
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={getProductData("alimony", "productIcon")} className={classes.icon} alt={"Unterhalt"} />
              </Grid>
              <Grid item>Erstberatung Unterhalt</Grid>
              <Grid item className={classes.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={getProductData("death", "productIcon")} className={classes.icon} alt={"Erbrecht"} />
              </Grid>
              <Grid item>Erstberatung Erbrecht/Todesfall</Grid>
              <Grid item className={classes.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.productCategoryHeadline}>Verkehrsrecht</ListItem>
          <ListItem
            button
            className={classes.productItem}
            component={Link}
            to={"/servicewelt/verkehrsrecht/ordnungswidrigkeit"}
          >
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={getProductData("traffic", "productIcon")} className={classes.icon} alt={"Verkehr"} />
              </Grid>
              <Grid item>Ordnungswidrigkeit</Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.productCategoryHeadline}>Arbeitsrecht</ListItem>
          <ListItem button className={classes.productItem} component={Link} to={"/servicewelt/arbeitsrecht/kuendigung"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={getProductData("settlement", "productIcon")} className={classes.icon} alt={"Abfindung"} />
              </Grid>
              <Grid item>Kündigungsschutzklage</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem button className={classes.productItem} component={Link} to={"/servicewelt/arbeitsrecht/kurzarbeit"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={getProductData("shortTimeWork", "productIcon")} className={classes.icon} alt={"Kurzarbeit"} />
              </Grid>
              <Grid item>Erstberatung Kurzarbeit</Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.productCategoryHeadline}>Vertragsrecht</ListItem>
          <ListItem button className={classes.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <img src={productCategoryData.contractLaw.icon} className={classes.icon} alt={"Vertragsrecht"} />
              </Grid>
              <Grid item>Beratung / Vertretung Vertragsrecht</Grid>
              <Grid item className={classes.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </ServiceWorldContentBox>
      <DialogModal
        open={legalExpenseInsuranceModalOpen}
        onClose={() => setLegalExpenseInsuranceModalOpen(false)}
        title={"Rechtsschutzversicherung?"}
      >
        <div className={classes.modalContent}>
          Diese Art des Falls steht leider nur Rechtsschutzversicherten unserer Partner zur Verfügung, bei denen bereits
          eine Deckungszusage vorliegt. Sie können diesen Fall daher leider nicht selbstständig anlegen. Melden Sie sich
          gerne per E-Mail bei uns unter <a href={"mailto:service@legalbird.de"}>service@legalbird.de</a>, um das
          weitere Vorgehen zu besprechen. Wir arbeiten bereits mit Hochdruck daran, unser Angebot allgemein zur
          Verfügung zu stellen!
        </div>
      </DialogModal>
    </div>
  );
};

export default ServiceWorldNewCase;

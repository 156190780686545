import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import UploadBox from "./UploadBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import Iconize from "../../SvgIcon/Iconize";
import CheckSvg from "../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../assets/icons/common/active/kreuz.svg";
import Pad from "../../Wizard/Pad";
import _get from "lodash/get";
import { getProductData } from "../../../services/productService";
import { getNoteStep, handleDocumentUploadDescriptionSave } from "../../../services/documentUploadFormService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import structuredClone from '@ungap/structured-clone';

const FURTHER_DOCUMENT_LIMIT = 9;

type FurtherUploadFormStepProps = {
  product: AbstractCase;
  currentStep: number;
  setCurrentStep: Function;
  steps: number;
  setSteps: Function;
  setIsLoading: Function;
};

export default function FurtherUploadFormStep({ product, currentStep, setCurrentStep, steps, setSteps, setIsLoading }: FurtherUploadFormStepProps) {
  const [hasFurtherDocument, setHasFurtherDocument] = useState(!!_get(product, ["temporaryFilesToCombine", currentStep], false));
  const [description, setDescription] = useState(_get(product, ["temporaryFilesToCombine", currentStep, "description"], ""));
  const queryClient = useQueryClient();
  const productApiUri = getProductData(product.name, "apiUri");
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  useEffect(() => {
    let temporaryFilesToCombineClone = structuredClone(product.temporaryFilesToCombine);
    if (hasFurtherDocument && !product.temporaryFilesToCombine[currentStep]) {
      temporaryFilesToCombineClone[currentStep] = { files: [], description: "", fieldName: "uploadedFile" };
      updateMutation.mutate({
        uri: productApiUri,
        id: product.id,
        data: {
          temporaryFilesToCombine: temporaryFilesToCombineClone,
        },
      });
    }
    if (!hasFurtherDocument && product.temporaryFilesToCombine[currentStep]) {
      temporaryFilesToCombineClone.splice(currentStep, 1);
      updateMutation.mutate({
        uri: productApiUri,
        id: product.id,
        data: {
          temporaryFilesToCombine: temporaryFilesToCombineClone,
        },
      });
    }
  }, [hasFurtherDocument]);

  const handleNextStepClick = async () => {
    if (hasFurtherDocument && currentStep < FURTHER_DOCUMENT_LIMIT) {
      setIsLoading(true);
      await handleDocumentUploadDescriptionSave(product, currentStep, description, updateMutation);
      setIsLoading(false);

      if (currentStep + 1 === getNoteStep(steps)) {
        //increase the general number of steps if the user presses next and the next step would be the note step
        setSteps((previousSteps: number) => previousSteps + 1);
      }
      setCurrentStep((previousStep: number) => previousStep + 1);
      return;
    }
    setCurrentStep(getNoteStep(steps));
  };

  const hasDocument = _get(product, ["temporaryFilesToCombine", currentStep, "files"], []).length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          Weitere Dateien
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          Möchten Sie uns eine weitere Datei zukommen lassen?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Ja"}
          onChange={() => setHasFurtherDocument(true)}
          type={"radio"}
          active={hasFurtherDocument}
          error={false}
          name={"yes"}
          labelIcon={<Iconize src={CheckSvg} alt={"Ja"} />}
          labelIconActive={<Iconize src={CheckSvgActive} alt={"Ja"} />}
        />
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Nein"}
          onChange={() => setHasFurtherDocument(false)}
          type={"radio"}
          active={!hasFurtherDocument}
          error={false}
          name={"no"}
          labelIcon={<Iconize src={CrossSvg} alt={"Nein"} />}
          labelIconActive={<Iconize src={CrossSvgActive} alt={"Nein"} />}
        />
      </Grid>
      {hasFurtherDocument && (
        <Grid item xs={12}>
          <UploadBox product={product} currentStep={currentStep} description={description} setDescription={setDescription} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Button fullWidth onClick={() => setCurrentStep((currentStep: number) => currentStep - 1)}>
          Zurück
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button disabled={hasFurtherDocument && (!hasDocument || description.length < 5)} fullWidth variant={"contained"} onClick={handleNextStepClick}>
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
}

import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./serviceWorldCaseItemStyle";
import Divider from "@mui/material/Divider";
import { Grid, ListItemButton } from "@mui/material";
import { Link } from "react-router-dom";
import { getProductData } from "../../../services/productService";
import Typography from "@mui/material/Typography";

const ServiceWorldCaseItem = ({ minimalCase, withDivider = true, listItemButtonConfig = {}, NotificationComponent = null }) => {
  const classes = useStyles({});

  const isLink = !!listItemButtonConfig.getLink;

  let conditionalProps = {};

  if (isLink) {
    conditionalProps.component = Link;
    conditionalProps.to = listItemButtonConfig.getLink(minimalCase);
  }

  if (!!listItemButtonConfig.getIsSelected) {
    conditionalProps.selected = listItemButtonConfig.getIsSelected(minimalCase);
  }

  if (!!listItemButtonConfig.handleClick) {
    conditionalProps.onClick = () => listItemButtonConfig.handleClick(minimalCase);
  }

  return (
    <>
      {withDivider && <Divider className={classes.divider} />}
      <ListItemButton {...conditionalProps} className={classes.caseItem}>
        <Typography className={classes.reference}>{minimalCase.reference}</Typography>
        <Grid container alignItems={"center"} className={classes.innerContainer} wrap={"nowrap"}>
          <Grid item>
            <img src={getProductData(minimalCase.name, "productCategory.icon")} className={classes.icon} alt={"Produkt Icon"} />
          </Grid>
          <Grid item className={classes.textContainer}>
            <Typography variant={"h6"}>{getProductData(minimalCase.name, "productCategory.label")}</Typography>
            <Typography>
              {getProductData(minimalCase.name, "label")}
              {minimalCase.opposingParty ? ` - ${minimalCase.opposingParty}` : ""}
            </Typography>
          </Grid>
          {NotificationComponent && <NotificationComponent minimalCase={minimalCase} />}
        </Grid>
      </ListItemButton>
    </>
  );
};

ServiceWorldCaseItem.propTypes = {
  minimalCase: PropTypes.object.isRequired,
  withDivider: PropTypes.bool,
  selected: PropTypes.bool,
};

export default ServiceWorldCaseItem;

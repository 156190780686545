import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import teaserStyle from "./teaserStyle";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "../Container/Container";

const useStyles = makeStyles(teaserStyle);

export default function Teaser({ imageLeftHandSide, image, imageAlt, headline, subText, button, variant }) {
  const classes = useStyles();

  if (variant === "compact") {
    return (
      <Container>
        <Grid container flexDirection={imageLeftHandSide ? "row" : "row-reverse"} alignItems={"center"} spacing={1}>
          <Grid item xs={12} md={3}>
            <img src={image} alt={imageAlt} className={classes.smallImage} />
          </Grid>
          <Grid item xs={12} md={9} className={classes.textContent}>
            <Typography variant={"h3"} component={"h2"}>{headline}</Typography>
            <Typography variant={"subtitle2"} className={classes.subText}>{subText}</Typography>
            {button}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container flexDirection={imageLeftHandSide ? "row" : "row-reverse"} alignItems={"center"} spacing={1}>
        <Grid item xs={12} md={5}>
          <img src={image} alt={imageAlt} />
        </Grid>
        <Grid item xs={12} md={7} className={classes.textContent}>
          <Typography variant={"h2"}>{headline}</Typography>
          <Typography className={classes.subText}>{subText}</Typography>
          {button}
        </Grid>
      </Grid>
    </Container>
  );
}

Teaser.propTypes = {
  imageLeftHandSide: PropTypes.bool,
  image: PropTypes.any.isRequired,
  imageAlt: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  button: PropTypes.element,
  variant: PropTypes.string,
};

Teaser.defaultProps = {
  imageLeftHandSide: false,
  variant: "standard",
};

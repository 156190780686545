import React from "react";
import _map from "lodash/map";
import { Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Hidden from "@mui/material/Hidden";
import { formatFilePath } from "../../../../services/mediaObjects";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import makeStyles from "@mui/styles/makeStyles";
import multipleFileUploadStyle from "./multipleFileUploadStyle";
import SingleFileUpload from "./SingleFileUpload";
import _sortBy from "lodash/sortBy";
import { useMutation } from "@tanstack/react-query";
import { deleteResource } from "../../../../services/reactQuery/reactQueryService";

const useStyles = makeStyles(multipleFileUploadStyle);
const MultipleFileUpload = ({ product, fieldname, description, mediaObjectCollection, fetchMediaObjects, electronicFileFolderPath }) => {
  const classes = useStyles();

  const deleteMutation = useMutation(deleteResource);

  const deleteMediaObject = async (mediaObject) => {
    await deleteMutation.mutateAsync({
      id: mediaObject.id,
      uri: "/media_objects",
    });
    fetchMediaObjects();
  };

  return (
    <>
      <Grid container>
        {_map(_sortBy(mediaObjectCollection, "id"), (mediaObject) => (
          <Grid key={mediaObject.id} item className={classes.file} xs={12}>
            <CheckIcon className={classes.icon} />
            <Hidden smUp>{formatFilePath(mediaObject.originalName, 20)}</Hidden>
            <Hidden smDown>{mediaObject.originalName}</Hidden>
            <DeleteIcon onClick={() => deleteMediaObject(mediaObject)} className={classes.deleteIcon} />
          </Grid>
        ))}
      </Grid>
      <SingleFileUpload
        product={product}
        description={description}
        electronicFileFolderPath={electronicFileFolderPath}
        fieldname={fieldname}
        fetchMediaObjects={fetchMediaObjects}
        buttonText={mediaObjectCollection.length ? "Weitere Datei hochladen" : "Datei hochladen"}
      />
    </>
  );
};
export default MultipleFileUpload;

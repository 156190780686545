import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import quotationCardStyle from "./quotationCardStyle";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(quotationCardStyle);

export default function QuotationCard({ text }) {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <div className={classes.textContainer}>
        <Typography variant={"body2"}>{text}</Typography>
      </div>
    </Paper>
  );
}

QuotationCard.propTypes = {
  text: PropTypes.string.isRequired,
};

QuotationCard.defaultProps = {};

const pictureTeaserCardStyle = {
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "25px 25px 0 0",
  },
  card: {
    width: 280,
    height: 350,
    margin: "0 auto",
  },
  text: {
    marginTop: ".3rem",
    textAlign: "center",
  },
};

export default pictureTeaserCardStyle;

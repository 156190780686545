import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import greyBackgroundWrapperStyle from "./greyBackgroundWrapperStyle";
import TransparentToGreyDivider from "./TransparentToGreyDivider";
import GreyToTransparentDivider from "./GreyToTransparentDivider";
import classNames from "classnames";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(greyBackgroundWrapperStyle);

export default function GreyBackgroundWrapper({ hasVerticalPadding, children }) {
  const classes = useStyles();

  return (
    <>
      <TransparentToGreyDivider />
      <div className={classNames(classes.greyBackground, hasVerticalPadding ? classes.verticalPadding : null)}>{children}</div>
      <GreyToTransparentDivider />
    </>
  );
};

GreyBackgroundWrapper.propTypes = {
  hasVerticalPadding: PropTypes.bool,
};

GreyBackgroundWrapper.defaultProps = {
  hasVerticalPadding: true,
};

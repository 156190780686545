import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headerIcon: {
    minWidth: 40,
    minHeight: 40,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  contentBoxHeadline: {
    marginBottom: 25,
  },
  imageCircleContainer: {
    alignItems: "stretch",
    maxWidth: 70,
    height: 70,
    padding: 14,
    marginRight: ".5rem",
  },
  contentBoxContainer: {
    paddingTop: 25,
  },
}));

export default useStyles;

import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { Grid, Stack, Tooltip } from "@mui/material";
import { WarningAmberOutlined } from "@mui/icons-material";
import { InquiryToCustomerStatus } from "../../../types/Entities/InquiryToCustomer";
import { MinimalCase } from "../../../types/Entities/MinimalCase";

type ActiveInquiryProps = {
  minimalCase: MinimalCase;
};

export default function ActiveCaseInquiry({minimalCase}: ActiveInquiryProps) {

  const inquiryFilters = {
    product: minimalCase.name,
    productId: minimalCase.id,
    "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
    "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
  };

  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiries } = useQuery(queryKeys.collection(inquiryApiUri, inquiryFilters), () => fetchCollection(inquiryApiUri, inquiryFilters));


  if (inquiries?.length > 0) {
    return (
      <Grid item sx={{flexGrow: 1}}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Tooltip title={"In diesem Fall ist eine Rückmeldung durch Sie nötig."} placement={"right"}>
            <WarningAmberOutlined color={"primary"} />
          </Tooltip>
        </Stack>
      </Grid>
    );
  }

  return null;
};

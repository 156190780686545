/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { apiGet } from "../../../../services/apiClient";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";

interface AccountDeletionPageProps {
  token: string;
}

export default function RequestAccountDeletion({ token }: AccountDeletionPageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [deletionRequestSuccessful, setDeletionRequestSuccessful] = useState(false);

  async function requestAccountDeletion() {
    try {
      await apiGet(`/account_deletion/request/${encodeURIComponent(token)}`);
      setDeletionRequestSuccessful(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    requestAccountDeletion();
  }, []);

  if (isLoading) {
    return <LegalbirdLoader />;
  }

  return (
    <>
      <Box textAlign={"center"}>
        <Typography variant={"h1"} sx={{ marginBottom: "1rem" }}>
          {deletionRequestSuccessful ? "Wir haben ihre Löschanfrage erhalten" : "Ihre Daten konnten nicht gelöscht werden."}
        </Typography>
        <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
          {deletionRequestSuccessful ? (
            <>
              <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
                Bitte überprüfen Sie Ihre E-Mails für eine Bestätigung der Löschung.
              </Typography>
            </>
          ) : (
            <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
              Ihre Daten konnten leider nicht gelöscht werden. Dies kann zum Beispiel daran liegen, dass Ihre Daten zuvor bereits gelöscht wurden.
            </Typography>
          )}
        </Typography>
      </Box>
    </>
  );
}

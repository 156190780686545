import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  placeholderText: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "1rem",
  },
  gridSpacing: {
    marginBottom: "1rem",
  },
}));

export default useStyles;

import { fileCheck, saveMediaObject } from "../../../../services/formServiceFunctions";
import { addMessage } from "../../../../store/messages/actions";
import _head from "lodash/head";
import React, { useState } from "react";
import UploadField from "../../ProcessCostSupport/Receipts/UploadField";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";

const SingleFileUpload = ({ product, fieldname, description, buttonText, fetchMediaObjects, electronicFileFolderPath }) => {
  const [filePath, setFilePath] = useState("");
  const createMediaObjectMutation = useMutation(createMediaObject);

  const handleChangeFile = async ({ target }) => {
    const fileCheckResult = fileCheck(target);
    if (fileCheckResult.hasError) {
      addMessage({ type: "error", text: fileCheckResult.errorMsg });
      return;
    }
    const file = _head(target.files);
    setFilePath(file.name);

    let payload = {
      file: file,
      fieldname: fieldname,
      description: description,
      caseProduct: product["@id"],
      product: product.name,
      productId: product.id,
    };

    if (electronicFileFolderPath) {
      payload.electronicFileFolderPath = electronicFileFolderPath;
    }
    await saveMediaObject(createMediaObjectMutation, payload);
    setFilePath("");
    await fetchMediaObjects();
  };

  return <UploadField fieldname={fieldname} filePath={filePath} handleChange={handleChangeFile} buttonText={buttonText} />;
};

SingleFileUpload.propTypes = {
  buttonText: PropTypes.string,
  product: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

SingleFileUpload.defaultProps = {
  buttonText: "Datei Hochladen",
};

export default SingleFileUpload;

import makeStyles from '@mui/styles/makeStyles';
import { scrollToAnchor } from "../../../theme/commonStyle";

const useStyles = makeStyles(() => ({
  scrollToAnchor,
  imageContainer: {
    alignItems: "stretch",
    width: 70,
    height: 70,
    padding: 14,
    textAlign: "center",
  },
  headerIcon: {
    minWidth: 40,
    minHeight: 40,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  headlineContainer: {
    wordBreak: "break-word",
  },
  subHeadline: {
    fontSize: "1rem",
  },
  navigationPaper: {
    padding: "25px 25px 0 25px",
  },
  listItem: {
    padding: 0,
    color: "#137f7b",
    fontWeight: 400,
  },
  headlineListItem: {
    padding: 0,
    marginBottom: 25,
  },
  navigationLink: {
    display: "block",
    textAlign: "center",
    padding: 25,
  },
}));

export default useStyles;

import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import gradientPaperStyle from "./gradientPaperStyle";
import { Card } from "@mui/material";
import classNames from "classnames";

const useStyles = makeStyles(gradientPaperStyle);

export default function GradientCard({ children, additionalClasses, ...rest }) {
  const classes = useStyles();

  return (
    <Card className={classNames(classes.background, additionalClasses)} {...rest}>
      {children}
    </Card>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../containers/Pages/Generic/HomePage/HomePage";
import Error404Page from "../containers/Pages/Generic/Error404/Error404Page";
import AdminPage from "../containers/Pages/Generic/AdminPage/AdminPage";
import LoginPage from "../containers/Pages/Generic/LoginPage/LoginPage";
import RegisterPage from "../containers/Pages/Generic/RegisterPage/RegisterPage";
import PasswordPage from "../containers/Pages/Generic/PasswordPage/PasswordPage";
import NewEmailPage from "../containers/Pages/Generic/NewEmailPage/NewEmailPage";
import ImprintPage from "../containers/Pages/Generic/ImprintPage/ImprintPage";
import TermsOfServiceLegalbirdOSPage from "../containers/Pages/Generic/TermsOfService/TermsOfServiceLegalbirdOSPage/TermsOfServiceLegalbirdOSPage";
import PrivacyLegalbirdOSPage from "../containers/Pages/Generic/PrivacyLegalbirdOSPage/PrivacyLegalbirdOSPage";
import AboutUsPage from "../containers/Pages/Generic/AboutUsPage/AboutUsPage";
import TermsOfServiceLawFirmPage from "../containers/Pages/Generic/TermsOfService/TermsOfServiceLawFirmPage/TermsOfServiceLawFirmPage";
import PrivacyLawFirmPage from "../containers/Pages/Generic/PrivacyLawFirmPage/PrivacyLawFirmPage";
import TermsOfServiceLegalbirdFinancingPage from "../containers/Pages/Generic/TermsOfService/TermsOfServiceLegalbirdFinancingPage/TermsOfServiceLegalbirdFinancingPage";
import CookiePolicyPage from "../containers/Pages/CookiePolicy/CookiePolicyPage";
import FeedbackPage from "../containers/Pages/Feedback/FeedbackPage";
import ContactPage from "../containers/Pages/Generic/ContactPage/ContactPage";
import LegalFieldPage from "../containers/Pages/LegalFieldPage/LegalFieldPage";
import useTracker from "../services/useTracker";
import LogoutExpired from "../services/LogoutExpired";
import AccountDeletionPage from "../containers/Pages/Generic/AccountDeletionPage/AccountDeletionPage";

export default function Base() {
  useTracker();
  return (
    <Routes>
      <Route path={"familienrecht"} element={<LegalFieldPage productCategory={"familyLaw"} />} />
      <Route path={"arbeitsrecht"} element={<LegalFieldPage productCategory={"labourLaw"} />} />
      <Route path={"verkehrsrecht"} element={<LegalFieldPage productCategory={"trafficLaw"} />} />
      <Route path={"vertragsrecht"} element={<LegalFieldPage productCategory={"contractLaw"} />} />
      <Route path={"papageienaufstand"} element={<AdminPage />} />
      <Route path={"impressum"} element={<ImprintPage />} />
      <Route path={"agb"} element={<TermsOfServiceLawFirmPage />} />
      <Route path={"agb-legalbird"} element={<TermsOfServiceLegalbirdOSPage />} />
      <Route path={"agb-prozessfinanzierung"} element={<TermsOfServiceLegalbirdFinancingPage />} />
      <Route path={"datenschutz"} element={<PrivacyLawFirmPage />} />
      <Route path={"datenschutz-legalbird"} element={<PrivacyLegalbirdOSPage />} />
      <Route path={"cookierichtlinie"} element={<CookiePolicyPage />} />
      <Route path={"ueber-uns"} element={<AboutUsPage />} />
      <Route path={"kontakt"} element={<ContactPage />} />
      <Route path={"login"} element={<LoginPage />} />
      <Route path={"konto-loeschen"} element={<AccountDeletionPage />} />
      <Route path={"registrierung"} element={<RegisterPage />} />
      <Route path={"neue-email/setzen"} element={<NewEmailPage />} />
      <Route
        path={"feedback"}
        element={
          <LogoutExpired token={localStorage.getItem("token")}>
            <FeedbackPage />
          </LogoutExpired>
        }
      />
      <Route path={"passwort/*"} element={<PasswordPage />} />
      <Route path={""} element={<HomePage />} />
      <Route path={"*"} element={<Error404Page />} status={404} />
    </Routes>
  );
}

import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import React from "react";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import _map from "lodash/map";
import AlimonyChild from "./AlimonyChild/AlimonyChild";
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from "../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(wizardStyle);
export default function AlimonyChildren ({submitProps, product}) {
  const classes = useStyles();
  const {handleSubmit} = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form
          className={classNames(classes.applicationForm, classes.wizardForm)}
          onSubmit={handleSubmit}
        >
          <Typography variant={"h5"} className={classes.headline}>Angaben der Kinder</Typography>
          {_map(product.children, (child, index) => (
            <AlimonyChild key={index} childIndex={index} child={child} />
          ))}
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
};

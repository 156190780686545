import React from "react";
import Iconize from "../../../../components/SvgIcon/Iconize";
import CheckSvg from "../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../assets/icons/common/active/kreuz.svg";
import Group from "../../../../components/Wizard/Group";
import { useForm } from "../../../../provider/Form/FormProvider";
import HighlightBox from "../../../../components/Box/HighlightBox/HighlightBox";
import { Typography } from "@mui/material";
import ValidatorTextField from "../../../../components/FormFields/ValidatorTextField";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import FormLegend from "../../../../components/Form/Common/FormLegend/FormLegend";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../../../components/Form/wizardStyle";

type JudicialProcessCustomerFeedbackFormProps = {
  isLoading: boolean;
};

// exception because i need the applicationform container width. ill create a ticket for the definition and refactoring of our containers.
// we can remove this styling as soon as that ticket is done and there exists a container with 550 width
// @ts-ignore
const useStyles = makeStyles(wizardStyle);

export default function JudicialProcessCustomerFeedbackForm({ isLoading }: JudicialProcessCustomerFeedbackFormProps) {
  const { values, handleSubmit } = useForm();
  const classes = useStyles();

  return (
    <div className={classes.wizardForm}>
      <form onSubmit={handleSubmit}>
        <Group
          question={
            "Sind Sie mit der Übernahme der entstehenden Kosten und der Abwicklung Ihres Fall im gerichtlichen Verfahren einverstanden?"
          }
          name={"trialWanted"}
          options={[
            {
              labelText: "Ja",
              labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
              labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
              value: "yes",
            },
            {
              labelText: "Nein",
              labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
              labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
              value: "no",
            },
          ]}
          type={"radio"}
        />
        {values.trialWanted === "yes" && (
          <HighlightBox>
            <Typography>
              Wichtig: Bei der Verhandlung vor Gericht werden <strong>weitere Kosten</strong> wie Anwalts- und Gerichtsgebühren auf Sie zukommen, welche{" "}
              <strong>Sie selbst zu tragen</strong> haben. Wenn Sie damit einverstanden sind, können Sie uns hier jetzt Ihre Rückmeldung übermitteln. Wir
              veranlassen dann das weitere Vorgehen.
            </Typography>
          </HighlightBox>
        )}
        {values.trialWanted === "no" && (
          <HighlightBox>
            <Typography>
              Wenn Sie den Fall nicht vor Gericht verhandeln wollen, dann werden wir mit Ihrer Zustimmung den Einspruch gegen Ihren Bußgeldbescheid
              zurücknehmen. Dieser wird somit rechtskräftig und ist innerhalb von 14 Tagen zu bezahlen. Für die finale Absprache wird sich unser Anwalt noch
              einmal mit Ihnen in Verbindung setzen.
            </Typography>
          </HighlightBox>
        )}
        <FormLegend>Möchten Sie uns noch etwas zu Ihrer Entscheidung mitteilen?</FormLegend>
        <ValidatorTextField
          name="trialWantedMessage"
          label="Ihr Anliegen"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={10}
          placeholder={"Ihre Nachricht an uns (optional)"}
          inputProps={{ maxLength: 750 }}
          helperText={"[" + values.trialWantedMessage.length + "/750]"}
        />
        <ButtonLoading variant={"contained"} fullWidth type={"submit"} disabled={!values.trialWanted} sx={{ marginTop: "2rem" }} isLoading={isLoading}>
          {values.trialWanted === "yes" && "Gerichtsverfahren und Kosten bestätigen"}
          {values.trialWanted === "no" && "Einspruch zurücknehmen"}
          {!values.trialWanted && "Rückmeldung übermitteln"}
        </ButtonLoading>
      </form>
    </div>
  );
}

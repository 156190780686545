import React from "react";
import * as PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import LayoutStyle from "./layoutStyle";
import NavigationBar from "../../components/Navigation/NavigationBar/NavigationBar";
import Footer from "../../components/Footer/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavigationBarMobile from "../../components/Navigation/NavigationBar/Mobile/NavigationBarMobile";
import backgroundStyling from "../../services/layoutService";
import ReleaseInfo from "../../components/ReleaseInfo/ReleaseInfo";
import ScrollTopButton from "../../components/Button/ScrollTopButton";

const useStyles = makeStyles(LayoutStyle);

export default function Layout({ minimal = false, clickableLogo = true, backgroundType, children, withScrollToTopButton = true }) {
  const classes = useStyles();

  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up("md"));

  if (isDesktopView) {
    return (
      <>
        <ReleaseInfo />
        <div className={classes.pageContainer}>
          <div className={classes.content} style={backgroundType ? backgroundStyling.desktop[backgroundType] : {}}>
            <NavigationBar minimal={minimal} clickableLogo={clickableLogo} />
            <div className={classes.childrenContainer}>{children}</div>
            <Footer className={classes.footer} />
          </div>
        </div>
        {withScrollToTopButton && <ScrollTopButton />}
      </>
    );
  }

  return (
    <>
      <ReleaseInfo />
      <div className={classes.pageContainer}>
        <div className={classes.content} style={backgroundType ? backgroundStyling.mobile[backgroundType] : {}}>
          <NavigationBarMobile minimal={minimal} clickableLogo={clickableLogo} />
          <div className={classes.childrenContainer}>{children}</div>
          <Footer className={classes.footer} />
        </div>
      </div>
      {withScrollToTopButton && <ScrollTopButton />}
    </>
  );
}

Layout.propTypes = {
  minimal: PropTypes.bool,
  clickableLogo: PropTypes.bool,
  backgroundType: PropTypes.string,
};

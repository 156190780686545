/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import { getLabelTranslationPath, translate, translateCaseValue } from "../../../../services/translations/translations";
import React from "react";
import useStyles from "./caseDetailsItemStyle";
import { convertValueDependingOnFieldType, fieldPathValueCanBeDisplayed } from "../../../../services/formatService";
import { FieldType } from "../../../../types/formatServiceTypes/FieldType";

type CaseDetailsItemProps = {
  caseData: any;
  fieldPath: string;
  fieldType?: FieldType;
  labelDependsOnField?: string | null;
  valuesDependOnField?: string | null;
  labelPostfix?: string | null;
};

const CaseDetailsItem = ({
  caseData,
  fieldPath,
  fieldType = null,
  labelDependsOnField = null,
  valuesDependOnField = null,
  labelPostfix = null,
}: CaseDetailsItemProps) => {
  const classes = useStyles();

  if (!fieldPathValueCanBeDisplayed(caseData, fieldPath)) {
    return null;
  }

  let convertedValue =
    fieldType === null ? translateCaseValue(caseData, fieldPath, valuesDependOnField) : convertValueDependingOnFieldType(caseData, fieldPath, fieldType);

  let label = translate(getLabelTranslationPath(caseData, fieldPath, labelDependsOnField));
  label = labelPostfix ? label + " " + labelPostfix : label;

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} className={classes.label}>
          {label}
        </Grid>
        <Grid item xs={12}>
          {convertedValue}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CaseDetailsItem;

import React, { useEffect, useState } from "react";
import Layout from "../../../Layouts/Layout";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import { Paper, Stack, Typography } from "@mui/material";
import judicialProcessCustomerFeedbackStyle from "./judicialProcessCustomerFeedbackStyle";
import Container from "../../../../components/Container/Container";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import JudicialProcessCustomerFeedbackForm from "./JudicialProcessCustomerFeedbackForm";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import useProductParams from "../../../../hooks/useProductParams";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { apiGet } from "../../../../services/apiClient";
import CostCalculatorTrafficResponse from "../../../../types/CostCalculatorTrafficResponse";

export default function JudicialProcessCustomerFeedback() {
  const { productId } = useProductParams();
  const productApiUri = getProductData("traffic", "apiUri");
  const queryClient = useQueryClient();
  const [isLoadingFees, setIsLoadingFees] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [costCalculatorResponse, setCostCalculatorResponse] = useState<CostCalculatorTrafficResponse | null>(null);
  const { data: traffic, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  const handleSubmit = async ({ values }: { values: Record<string, any> }) => {
    setIsSubmitting(true);
    await updateMutation.mutateAsync({
      id: traffic.id,
      uri: "/traffic",
      data: {
        trialWanted: values.trialWanted,
        trialWantedMessage: values.trialWantedMessage,
        triggerTrialWantedSubscriber: true,
      },
    });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (traffic) {
      setIsLoadingFees(true);
      apiGet("/get_fees/" + traffic.id)
        .then((result) => {
          setCostCalculatorResponse(result);
        })
        .finally(() => setIsLoadingFees(false));
    }
  }, [traffic]);

  if (isLoading || isLoadingFees) {
    return (
      <Layout backgroundType={"serviceworld"}>
        <LegalbirdLoader centered />
      </Layout>
    );
  }

  if (!costCalculatorResponse || traffic.paymentType !== "private" || traffic.authorityDecision !== "court") {
    return null;
  }

  if (traffic.trialWanted) {
    return (
      <Layout backgroundType={"serviceworld"}>
        <Container>
          <HintBox headline={"Vielen Dank für Ihre Rückmeldung!"}>
            <Typography sx={judicialProcessCustomerFeedbackStyle.centerText}>
              Wir haben Ihre Rückmeldung erhalten und werden Ihren Fall entsprechend weiter bearbeiten. Sie werden über das weitere Vorgehen wie gewohnt per
              E-Mail und Ihre Servicewelt informiert.
            </Typography>
            <Button component={Link} to={getCaseLink(traffic)} sx={{ margin: "1rem auto 0" }}>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout backgroundType={"serviceworld"}>
      <Container>
        <HintBox headline={"Ihre Rückmeldung wird benötigt"}>
          <Stack spacing={2}>
            <Typography variant={"h6"} sx={judicialProcessCustomerFeedbackStyle.centerText}>
              Nächster Schritt: Gerichtliche Verhandlung Ihres Verkehrsrechtsfalls
            </Typography>
            <Typography sx={judicialProcessCustomerFeedbackStyle.centerText}>
              Die Behörde hat Ihren Fall an das Gericht weitergegeben. Bitte geben Sie uns Rückmeldung darüber, ob Sie der weiteren Bearbeitung Ihres Falls
              zustimmen und diesen vor Gericht verhandeln wollen. <strong>Wichtig: Dabei werden weitere Kosten für Sie entstehen.</strong>
            </Typography>
            <Typography sx={judicialProcessCustomerFeedbackStyle.centerText}>
              <strong>Voraussichtliche Kosten gerichtliches Verfahren:</strong> Summe inkl. MwSt:{" "}
              {costCalculatorResponse.sumOfCostsCourtWithVat.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}{" "}
              *
            </Typography>
            <Typography sx={judicialProcessCustomerFeedbackStyle.centerText}>*Kosten können je nach Verlauf des Verfahren variieren.</Typography>
          </Stack>
        </HintBox>
        <Paper sx={{ padding: "2rem" }}>
          <FormProvider initialValues={{ trialWantedMessage: "" }} submitAction={handleSubmit}>
            <JudicialProcessCustomerFeedbackForm isLoading={isSubmitting} />
          </FormProvider>
        </Paper>
      </Container>
    </Layout>
  );
}

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  termsOfServiceText: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 10,
  },
  sslLockIcon: {
    height: 12,
  },
}));

export default useStyles;

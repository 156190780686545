import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0.25rem 1rem 1rem",
    marginBottom: 25,
  },
  headline: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: "center",
    margin: "3rem 0",
  },
  content: {
    maxWidth: 700,
    margin: "0 auto",
    textAlign: "center",
  },
  divider: {
    margin: "1rem 0",
  },
}));

export default useStyles;

const transparentToGreyDividerStyle = {
  divider: {
    width: "100%",
    overflow: "hidden",
    transform: "rotate(180deg)",
    "& > svg": {
      position: "relative",
      display: "block",
      width: "calc(129% + 1.3px)",
      height: "73px",
    },
  },
  fill: {
    fill: "#F4F4F4",
  },
};

export default transparentToGreyDividerStyle;

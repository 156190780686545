import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import pictureTeaserCardStyle from "./pictureTeaserCardStyle";
import Typography from "@mui/material/Typography";
import GradientPaper from "../../GradientComponents/GradientPaper";


const useStyles = makeStyles(pictureTeaserCardStyle);

export default function PictureTeaserCard({image, title, subTitle}) {

  const classes = useStyles();

  return (
    <GradientPaper additionalClasses={classes.card}>
      <img src={image} alt={title} className={classes.image}/>
      <Typography variant={"h4"} className={classes.text}>{title}</Typography>
      <Typography variant={"body1"} className={classes.text}>{subTitle}</Typography>
    </GradientPaper>
  );
};

PictureTeaserCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

PictureTeaserCard.defaultProps = {};

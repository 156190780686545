import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: "center",
  },
  boxesContainer: {
    paddingTop: 20,
    textAlign: "left",
  },
  boxContent: {
    textAlign: "left",
  },
}));

export default useStyles;

import React from "react";
import * as PropTypes from "prop-types";
import { default as CostBoxSettlement } from "../Settlement/CostBox";
import DivorcePlannerBox from "../Divorce/DivorcePlannerBox";
import ProvisionDocumentTeasers from "../Provision/ProvisionDocumentTeasers";
import PersonalAnalysisBox from "../ShortTimeWork/PersonalAnalysisBox/PersonalAnalysisBox";
import DivorceCostBox from "../Divorce/CostBox/DivorceCostBox";

const ServiceWorldCaseProductBoxes = ({ product }) => {
  switch (product.name) {
    case "alimony":
      return null;
    case "divorce":
      return (
        <>
          <DivorcePlannerBox product={product} />
          <DivorceCostBox divorce={product} />
        </>
      );
    case "death":
      return null;
    case "familyLawCase":
      return null;
    case "settlement":
      return (
        <>
          <CostBoxSettlement settlement={product} />
        </>
      );
    case "shortTimeWork":
      return <PersonalAnalysisBox product={product} />;
    case "traffic":
      return null;
    case "patientDecree":
    case "powerOfAttorney":
      return <ProvisionDocumentTeasers />;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      return null;
    default:
      return null;
  }
};

ServiceWorldCaseProductBoxes.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ServiceWorldCaseProductBoxes;

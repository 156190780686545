/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { getLabelTranslationPath, getQuestionTranslationPath, translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import { requiredValidator } from "../../../../services/validationRules";
import React from "react";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";
import ValidatorAutocomplete from "../../../FormFields/ValidatorAutocomplete";

type AutocompleteSelectFieldConfiguredProps = {
  caseData: any;
  fieldPath: string;
  required?: boolean | undefined;
  labelDependsOnField?: string | null;
  questionDependsOnField?: string | null;
};
export default function AutocompleteSelectFieldConfigured(
  {
    caseData,
    fieldPath,
    required,
    labelDependsOnField = null,
    questionDependsOnField = null
  }: AutocompleteSelectFieldConfiguredProps) {
  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(getQuestionTranslationPath(caseData, fieldPath, questionDependsOnField));
  const label = translate(getLabelTranslationPath(caseData, fieldPath, labelDependsOnField));
  const validators = [];
  if (required) {
    validators.push(requiredValidator("Bitte geben Sie eine der angegebenen Auswahlmöglichkeiten an"));
  }
  const options = getFormDefinitions(caseData, fieldPath);

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>{question}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorAutocomplete
          label={label}
          name={fieldPath}
          options={options}
          validators={validators}
          helperText={helperText}
        />
      </Grid>
    </>
  );
}

import React from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { isRequired, minStringLength } from "../../../../services/validationRules";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import FormLegend from "../FormLegend/FormLegend";
import {translate} from "../../../../services/translations/translations";

const useStyles = makeStyles(wizardStyle);

const Person = ({ path, headline, fieldsAreRequired }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.gridTopSpaced}>
        <FormLegend>{headline}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorSelectField
          label={translate("person.gender.label")}
          name={`${path}.gender`}
          validators={fieldsAreRequired ? [{ validator: isRequired, message: "Bitte geben Sie die Anrede an" }] : []}
          MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
        >
          <MenuItem value={"female"}>Frau</MenuItem>
          <MenuItem value={"male"}>Herr</MenuItem>
        </ValidatorSelectField>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridSpace}>
        <ValidatorTextField
          name={`${path}.givenName`}
          label={translate("person.givenName.label")}
          validators={
            fieldsAreRequired
              ? [
                  { validator: isRequired, message: "Bitte geben Sie den Vornamen an" },
                  { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
                ]
              : []
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValidatorTextField
          name={`${path}.familyName`}
          label={translate("person.familyName.label")}
          validators={
            fieldsAreRequired
              ? [
                  { validator: isRequired, message: "Bitte geben Sie den Nachnamen an" },
                  { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
                ]
              : []
          }
        />
      </Grid>
    </>
  );
};

Person.propTypes = {
  path: PropTypes.string.isRequired,
  headline: PropTypes.string,
  fieldsAreRequired: PropTypes.bool,
};

Person.defaultProps = {
  headline: "Wie heißen Sie?",
  fieldsAreRequired: true,
};

export default Person;

import React from "react";
import Layout from "../../Layouts/Layout";
import CookiePolicy from "../../../components/CookiePolicy/CookiePolicy";
import Container from "../../../components/Container/Container";

const CookiePolicyPage = () => {
  return (
    <Layout>
      <Container sectionPadding={false}>
        <CookiePolicy />
      </Container>
    </Layout>
  );
};
export default CookiePolicyPage;

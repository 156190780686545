import { maxNumber } from "../validationRules";

export const shortTimeWorkFormDefinitions = { grossSalary: grossSalary };

function grossSalary() {
  return {
    validators: [
      {
        validator: maxNumber,
        params: [50000],
        message: "Bitte überprüfen Sie Ihre Angabe",
      },
    ],
  };
}

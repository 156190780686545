import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headline: {
    textAlign: "center",
    paddingBottom: "1rem",
  },
  subheadline: {
    textAlign: "center",
    paddingBottom: "3rem",
  },
  mapIcon: {
    width: 300,
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  heading: {
    marginBottom: 50,
  },
  cardContainer: {
    [theme.breakpoints.down('lg')]: {
      width: 532,
      margin: "auto",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

export default useStyles;

import React, { useState } from "react";
import { getCaseLink, getProductData } from "../../../services/productService";
import ApiClient from "../../../services/apiClient";
import Paper from "@mui/material/Paper";
import FormFader from "../../../components/Form/Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../../components/Form/wizardStyle";
import classNames from "classnames";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import { useNavigate } from "react-router-dom";
import { requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import Iconize from "../../SvgIcon/Iconize";
import CarSvg from "../../../assets/icons/contract/inactive/icon_car.svg";
import CarSvgActive from "../../../assets/icons/contract/active/icon_car.svg";
import BuySvg from "../../../assets/icons/contract/inactive/icon_ware-einfordern.svg";
import BuySvgActive from "../../../assets/icons/contract/active/icon_ware-einfordern_active.svg";
import ServiceSvg from "../../../assets/icons/contract/inactive/icon_handwerker.svg";
import ServiceSvgActive from "../../../assets/icons/contract/active/icon_handwerker.svg";
import MembershipSvg from "../../../assets/icons/contract/inactive/icon_club.svg";
import MembershipSvgActive from "../../../assets/icons/contract/active/icon_club_active.svg";
import OtherSvg from "../../../assets/icons/contract/inactive/icon_contract.svg";
import OtherSvgActive from "../../../assets/icons/contract/active/icon_contract.svg";
import SubscriptionSvg from "../../../assets/icons/contract/inactive/icon_wiederholen.svg";
import SubscriptionSvgActive from "../../../assets/icons/contract/active/icon_wiederholen.svg";
import ProviderSvg from "../../../assets/icons/provision/inactive/sendemast.svg";
import ProviderSvgActive from "../../../assets/icons/provision/active/sendemast.svg";
import TravelSvg from "../../../assets/icons/contract/inactive/icon_reisen.svg";
import TravelSvgActive from "../../../assets/icons/contract/active/icon_reisen.svg";
import EventSvg from "../../../assets/icons/contract/inactive/icon_veranstaltung.svg";
import EventSvgActive from "../../../assets/icons/contract/active/icon_veranstaltung.svg";
import Group from "../../Wizard/Group";
import { useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";

const useStyles = makeStyles(wizardStyle);

export default function ContractLawCaseSelection({ product, submitProps }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const { values, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const handleSubmitInternal = async (e) => {
    //-- check if switch is required
    if (values.name !== product.name) {
      e.preventDefault();
      setIsLoadingInternal(true);
      const switchResponse = await ApiClient.post(`/product_switch_case/${product.id}/${values.name}`);
      const newCase = await queryClient.fetchQuery(queryKeys.item(getProductData(switchResponse.name, "apiUri"), switchResponse.id), () =>
        fetchResource(getProductData(switchResponse.name, "apiUri"), switchResponse.id)
      );
      navigate(getCaseLink(newCase) + "/antrag");
      return;
    }
    handleSubmit(e);
  };

  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmitInternal}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Vertragsart
            </Typography>
          </Hidden>
          <Group
            type="radio"
            name="name"
            question="Um welche Art von Vertrag handelt es sich?"
            options={[
              {
                labelText: "Kaufvertrag",
                labelIcon: <Iconize src={BuySvg} alt={"Keine"} />,
                labelIconActive: <Iconize src={BuySvgActive} alt={"Keine"} />,
                value: "purchaseContract",
                optionHintText: "Probleme mit einem Kaufvertrag - egal ob als Käufer oder Verkäufer.",
              },
              {
                labelText: "Fahrzeugkauf",
                labelIcon: <Iconize src={CarSvg} alt={"1 - 3 Punkte"} />,
                labelIconActive: <Iconize src={CarSvgActive} alt={"1 - 3 Punkte"} />,
                value: "vehicleContract",
                optionHintText: "Themen rund um den Kauf oder Verkauf von Fahrzeugen.",
              },
              {
                labelText: "Handwerker / Dienstleistung",
                labelIcon: <Iconize src={ServiceSvg} alt={"4 - 8 Punkte"} />,
                labelIconActive: <Iconize src={ServiceSvgActive} alt={"4 - 8 Punkte"} />,
                value: "serviceContract",
                optionHintText: "Eine (handwerkliche) Dienstleistung wurde nicht wie vereinbart erfüllt.",
              },
              {
                labelText: "Mitgliedschaft",
                labelIcon: <Iconize src={MembershipSvg} alt={"nicht sicher"} />,
                labelIconActive: <Iconize src={MembershipSvgActive} alt={"nicht sicher"} />,
                value: "membershipContract",
                optionHintText: "Uneinigkeiten mit einer Mitgliedschaft - beispielsweise Fitnessstudio oder Sportverein.",
              },
              {
                labelText: "Abo / Abofalle",
                labelIcon: <Iconize src={SubscriptionSvg} alt={"nicht sicher"} />,
                labelIconActive: <Iconize src={SubscriptionSvgActive} alt={"nicht sicher"} />,
                value: "subscriptionContract",
                optionHintText: "Alles rund um Abos oder sogenannte “Abofallen”.",
              },
              {
                labelText: "Handy / DSL / Strom / Gas -Vertrag",
                labelIcon: <Iconize src={ProviderSvg} alt={"nicht sicher"} />,
                labelIconActive: <Iconize src={ProviderSvgActive} alt={"nicht sicher"} />,
                value: "providerContract",
                optionHintText: "Probleme mit Versorgern, wie Mobilfunkanbietern oder Stromversorger.",
              },
              {
                labelText: "Reisevertrag",
                labelIcon: <Iconize src={TravelSvg} alt={"nicht sicher"} />,
                labelIconActive: <Iconize src={TravelSvgActive} alt={"nicht sicher"} />,
                value: "travelContract",
                optionHintText: "Reise hat gar nicht, oder nicht wie vereinbart stattgefunden.",
              },
              {
                labelText: "Veranstaltung",
                labelIcon: <Iconize src={EventSvg} alt={"nicht sicher"} />,
                labelIconActive: <Iconize src={EventSvgActive} alt={"nicht sicher"} />,
                value: "eventContract",
                optionHintText: "Veranstaltung ausgefallen, verschoben oder andere Probleme.",
              },
              {
                labelText: "Sonstiger Vertrag",
                labelIcon: <Iconize src={OtherSvg} alt={"nicht sicher"} />,
                labelIconActive: <Iconize src={OtherSvgActive} alt={"nicht sicher"} />,
                value: "otherContract",
                optionHintText: "Auch bei anderen Arten von Verträgen helfen wir gerne weiter.",
              },
            ]}
            validators={[requiredValidator("Bitte geben Sie die Art des Vertrags an.")]}
          />
          <FormSubmit {...submitProps} isLoading={isLoadingInternal || submitProps.isLoading} />
        </form>
      </FormFader>
    </Paper>
  );
}

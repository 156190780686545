import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import navigationBarMobileStyle from "./navigationBarMobileStyle";
import Toolbar from "@mui/material/Toolbar";
import withScroll from "../../../../services/withScroll";
import { AppBar, Grid } from "@mui/material";
import Logo from "../../Logo/Logo";
import NavigationMenuMobile from "./NavigationMenuMobile";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";
import MessageNotifications from "../MessageNotifications";

const useStyles = makeStyles(navigationBarMobileStyle);

const NavigationBarMobile = ({ scrollPosition, minimal, clickableLogo }) => {
  const classes = useStyles();
  const scrolledStyle = scrollPosition > 1;
  const { isLoggedIn } = useCustomer();

  return (
    <AppBar position={"fixed"} color={scrolledStyle ? "inherit" : "transparent"} elevation={scrolledStyle ? 4 : 0}>
      <Toolbar>
        <Grid container alignItems={"center"} justifyContent={"space-between"} className={classes.navigationContainer} columnSpacing={5}>
          <Grid item>
            <Logo target={isLoggedIn ? "/servicewelt" : "/"} isClickable={clickableLogo} />
          </Grid>
          {!minimal && (
            <Grid item>
              {isLoggedIn ? <MessageNotifications /> : null}
              <NavigationMenuMobile clickableLogo={clickableLogo} />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

NavigationBarMobile.propTypes = {};

NavigationBarMobile.defaultProps = {};

export default withScroll(NavigationBarMobile);

import makeStyles from '@mui/styles/makeStyles';

const privacyStyle = makeStyles(() => ({
  privacyPage: {
    marginTop: 97,
  },
  list: {
    listStyleType: "none",
  },
}));

export default privacyStyle;

import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";

const ContractDescription = ({ formData, formPath, title }) => {
  const classes = useStyles({});
  const section = "contractDescription";

  return (
    <>
      <Grid item xs={12} className={classes.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.question}>
        <div id={"personalTextMessage"} className={classes.scrollToAnchor} />
        Ihre Situation und Fragen
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "personalTextMessage",
          },
        }}
      >
        {formData.personalTextMessage}
      </GridEditRow>
    </>
  );
};

ContractDescription.propTypes = {
  formData: PropTypes.object.isRequired,
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContractDescription;

import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import _map from "lodash/map";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React, { useMemo } from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import {
  emailDefault,
  isPhoneNumberDefault,
  matchRegexp,
  minStringLength,
  requiredValidator
} from "../../../services/validationRules";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import makeStyles from "@mui/styles/makeStyles";
import trafficCheckinStyle from "./trafficCheckinStyle";

const useStyles = makeStyles(trafficCheckinStyle);
export default function TrafficCheckinForm({
  isLoading,
  emailErrorText,
  setEmailErrorText,
  accusation,
  partnerInsurer,
}) {
  const { handleSubmit } = useForm();
  const classes = useStyles();

  const caseReferenceNumberValidators = useMemo(() => {
    let caseReferenceNumberValidators = [requiredValidator("Bitte geben Sie Ihre Schaden-/Leistungsnummer an.")];
    switch (partnerInsurer) {
      case "advocard":
        caseReferenceNumberValidators.push({
          validator: matchRegexp,
          params: ["^\\d{2}-?(33-?0001-?|\\d)\\d{5}-?\\d$"],
          message: "Ihre Schadens-/Leistungsnummer hat das falsche Format",
        });
        break;
      default:
      case "arag":
        caseReferenceNumberValidators.push({
          validator: minStringLength,
          params: [3],
          message: "Bitte kontrollieren Sie Ihre Eingabe",
        });
        break;
    }
    return caseReferenceNumberValidators;
  }, [partnerInsurer]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorSelectField
            label={accusation.detail.label}
            name={"accusationDetail"}
            validators={[requiredValidator("Bitte geben Sie an, was Ihnen vorgeworfen wird.")]}
          >
            {_map(accusation.detail.options, (option, key) => (
              <MenuItem key={key} value={`${option.value}`}>
                {option.label}
              </MenuItem>
            ))}
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField
            name="telephone"
            label="Ihre Telefonnummer"
            autoComplete="tel"
            validators={[requiredValidator("Bitte geben Sie Ihre Telefonnummer an"), ...isPhoneNumberDefault]}
          />
        </Grid>
        {partnerInsurer && (
          <Grid item xs={12} md={4}>
            <ValidatorTextField
              name="caseReferenceNumber"
              label="Schadens-/Leistungsnummer"
              helperText={"Dies ist die Nummer, die Sie zu Ihrem aktuellen Anliegen erhalten haben."}
              validators={caseReferenceNumberValidators}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} className={classes.buttonContainer}>
          <ButtonLoading
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            fullWidth
            isLoading={isLoading}
          >
            Kostenlos prüfen lassen
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}

TrafficCheckinForm.defaultProps = {
  partnerInsurer: null,
};

import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import fileUploadSectionStyle from "./fileUploadSectionStyle";
import _map from "lodash/map";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { formatFilePath } from "../../../../services/mediaObjects";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import UploadField from "../../ProcessCostSupport/Receipts/UploadField";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(fileUploadSectionStyle);

const FileUploadForm = ({
  noSection,
  headline,
  helperText,
  mediaObjectCollection,
  deleteMediaObject,
  allowMore,
  fieldname,
  label,
  handleFileChange,
  filePath,
  isBlocked,
  uploadFileLabel,
  addPageLabel,
}) => {
  const classes = useStyles();
  return (
    <div className={!noSection ? classes.section : undefined}>
      {headline && (
        <Typography variant={"h5"} className={classes.headline}>
          {headline}
        </Typography>
      )}
      {helperText && <p>{helperText}</p>}
      <Grid container>
        {_map(mediaObjectCollection, (mediaObject, key) => (
          <Grid key={key} className={classes.file} item xs={12}>
            <Hidden smUp>{formatFilePath(mediaObject.originalName, 20)}</Hidden>
            <Hidden smDown>{mediaObject.originalName}</Hidden>
            <DeleteIcon onClick={() => deleteMediaObject(mediaObject)} className={classes.deleteIcon} />
          </Grid>
        ))}
      </Grid>
      {allowMore && (
        <UploadField
          label={label}
          handleChange={handleFileChange}
          fieldname={fieldname}
          filePath={filePath}
          disabled={isBlocked}
          buttonText={mediaObjectCollection.length === 0 ? uploadFileLabel : addPageLabel}
        />
      )}
    </div>
  );
};
FileUploadForm.defaultProps = {
  uploadFileLabel: "Datei hochladen",
  addPageLabel: "Weitere Seite hinzufügen",
};

export default FileUploadForm;

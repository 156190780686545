import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  stepHeading: {
    fontWeight: 500,
  },
  stepText: {
    margin: 0,
  },
  textBlock: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 25,
  },
  done: {
    textDecoration: "line-through",
  },
  iconContainer: {
    width: 70,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
  },
  connectorLine: {
    flexGrow: 1,
    borderLeft: "1px solid #333",
  },
  connectorLineInvisible: {
    flexGrow: 1,
  },
  progressIcons: {
    margin: "5px 0",
    "& img": {
      width: 40,
    },
  },
}));

export default useStyles;

import Grid from "@mui/material/Grid";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import feedbackFormStyle from "../feedbackFormStyle";
import { useForm } from "../../../../../provider/Form/FormProvider";

const useStyles = makeStyles(feedbackFormStyle);
const LawsuitConsequences = ({ product }) => {
  const classes = useStyles();
  const { values } = useForm();
  if (values.finalProcessFeedbackClient !== "lawsuit") {
    return null;
  }

  return (
    <Grid item xs={12}>
      <div className={classes.lawsuitPoints}>
        <p>
          {product.caseCategory === "defendClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
            <>
              Sollten Sie eine Klage riskieren wollen, so sind damit bestimmte Aufwände verbunden, auf die wir
              hier hinweisen möchten:
            </>
          )}
          {product.caseCategory === "defendClaim" && product.extrajudicialRecommendation === "lawsuit" && (
            <>
              Sollten Sie sich unserer Empfehlung anschließen und eine Klage abwarten wollen, möchten wir Sie aber der
              Vollständigkeit halber noch auf die folgenden Punkte hinweisen:
            </>
          )}
          {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation !== "lawsuit" && (
            <>
              Sollten Sie klagen wollen, so sind damit bestimmte Aufwände verbunden, auf die wir hier hinweisen
              möchten:
            </>
          )}
          {product.caseCategory === "enforceClaim" && product.extrajudicialRecommendation === "lawsuit" && (
            <>
              Sollten Sie sich unserer Empfehlung anschließen und klagen wollen, möchten wir Sie aber der
              Vollständigkeit halber noch auf die folgenden Punkte hinweisen:
            </>
          )}
        </p>
        <ul>
          {product.legalExpenseInsurance && (
            <>
              {product.extrajudicialRecommendation !== "lawsuit" && (
                <li>
                  Die entstehenden Kosten des Gerichtsverfahrens werden zwar größtenteils von Ihrer
                  Rechtssschutzversicherung getragen,{" "}
                  <strong>
                    Sie müssen jedoch den Selbstbehalt{" "}
                    {product.insurance.deductible > 0 && (
                      <>
                        in Höhe von{" "}
                        {product.insurance.deductible.toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </>
                    )}{" "}
                    selber zahlen.
                  </strong>
                </li>
              )}
              <li>
                Es können sich gegebenenfalls die{" "}
                <strong>Beitragszahlungen Ihrer Rechtsschutzversicherung erhöhen</strong>
              </li>
            </>
          )}
          {!product.legalExpenseInsurance && (
            <>
              <li>
                Die entstehenden Kosten des Gerichtsverfahrens{" "}
                <strong>müssen vollständig von Ihnen übernommen werden</strong>, sollten Sie den Prozess verlieren.
                Dieses Kostenrisiko umfasst Ihre eigenen Anwaltskosten, die Anwaltskosten der Gegenseite und die
                Gerichtskosten und kann daher erheblich sein.
              </li>
            </>
          )}
          <li>
            Ein Gerichtsverfahren kann sich unter Umständen über Jahre hinziehen, weshalb die psychische Belastung für
            Sie berücksichtigt werden muss.
          </li>
          <li>
            Im weiteren Verfahren müssen Sie in der Regel <strong>persönlich vor Gericht erscheinen</strong>, sodass Sie
            sich hierfür entsprechend Urlaub nehmen müssen.
          </li>
        </ul>
      </div>
    </Grid>
  );
};
export default LawsuitConsequences;

import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import UploadBox from "./UploadBox";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import Iconize from "../../SvgIcon/Iconize";
import CheckSvg from "../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../assets/icons/common/active/kreuz.svg";
import Pad from "../../Wizard/Pad";
import { getProductData } from "../../../services/productService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {createResource, queryKeys, updateResource} from "../../../services/reactQuery/reactQueryService";
import _ from "lodash";
import { getFinishedStep } from "../../../services/messagingService";
import {MessageCreateData} from "../../../types/Messaging/MessageCreateData";
import structuredClone from '@ungap/structured-clone';

const FURTHER_DOCUMENT_LIMIT = 9;

type AttachmentFormStepProps = {
  product: AbstractCase;
  currentStep: number;
  setCurrentStep: Function;
  steps: number;
  setSteps: Function;
  setIsLoading: Function;
  headline: string;
  subHeadline: string;
  messageCreateData: MessageCreateData | null;
};

export default function AttachmentFormStep({
  product,
  currentStep,
  setCurrentStep,
  steps,
  setSteps,
  setIsLoading,
  headline,
  messageCreateData,
  subHeadline,
}: AttachmentFormStepProps) {
  const [hasFurtherDocument, setHasFurtherDocument] = useState(false);
  const [description, setDescription] = useState("");
  const queryClient = useQueryClient();
  const productApiUri = getProductData(product.name, "apiUri");
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });
  const createMutation = useMutation(createResource);

  const messagePath = `messages.${messageCreateData!.temporaryFilesToCombineDataIdentifier}`;

  //since the first step isnt a file upload
  const temporaryFilesToCombineDataCurrentFileIndex = currentStep - 1;

  useEffect(() => {
    let temporaryFilesToCombineDataClone = structuredClone(product.temporaryFilesToCombineData);
    let currentMessageFilesToCombineData = _.get(temporaryFilesToCombineDataClone, messagePath);
    if (hasFurtherDocument && !currentMessageFilesToCombineData[temporaryFilesToCombineDataCurrentFileIndex]) {
      _.set(temporaryFilesToCombineDataClone, messagePath + "[" + temporaryFilesToCombineDataCurrentFileIndex + "]", {
        files: [],
        description: "",
        fieldName: "uploadedFile",
      });
      updateMutation.mutate({
        uri: productApiUri,
        id: product.id,
        data: {
          temporaryFilesToCombineData: temporaryFilesToCombineDataClone,
        },
      });
    }
    if (!hasFurtherDocument && currentMessageFilesToCombineData[temporaryFilesToCombineDataCurrentFileIndex]) {
      currentMessageFilesToCombineData.splice(temporaryFilesToCombineDataCurrentFileIndex, 1);
      _.set(temporaryFilesToCombineDataClone, messagePath, currentMessageFilesToCombineData);
      updateMutation.mutate({
        uri: productApiUri,
        id: product.id,
        data: {
          temporaryFilesToCombineData: temporaryFilesToCombineDataClone,
        },
      });
    }
  }, [hasFurtherDocument]);

  const handleNextStepClick = async () => {
    setIsLoading(true);
    const finishedStep = getFinishedStep(steps);
    if (hasFurtherDocument && currentStep < FURTHER_DOCUMENT_LIMIT) {
      //save description
      let temporaryFilesToCombineDataClone = structuredClone(product.temporaryFilesToCombineData);
      _.set(temporaryFilesToCombineDataClone, ["messages", messageCreateData!.temporaryFilesToCombineDataIdentifier, temporaryFilesToCombineDataCurrentFileIndex, "description"], description);
      await updateMutation.mutateAsync({
        uri: getProductData(product.name, "apiUri"),
        id: product.id,
        data: {
          temporaryFilesToCombineData: temporaryFilesToCombineDataClone,
        },
      });
      setIsLoading(false);

      if (currentStep + 1 === finishedStep) {
        //increase the general number of steps if the user presses next and the next step would be the note step
        setSteps((previousSteps: number) => previousSteps + 1);
      }
      setCurrentStep((previousStep: number) => previousStep + 1);
      return;
    }

    await createMutation.mutateAsync({
      uri: "/messages",
      data: messageCreateData!,
    });

    await queryClient.invalidateQueries(queryKeys.collection("/conversations"));
    setIsLoading(false);
    setCurrentStep(finishedStep);
  };

  const hasDocument = _.get(product.temporaryFilesToCombineData, `${messagePath}[${temporaryFilesToCombineDataCurrentFileIndex}].files`, []).length > 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          {headline}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          {subHeadline}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Ja"}
          onChange={() => setHasFurtherDocument(true)}
          type={"radio"}
          active={hasFurtherDocument}
          error={false}
          name={"yes"}
          labelIcon={<Iconize src={CheckSvg} alt={"Ja"} />}
          labelIconActive={<Iconize src={CheckSvgActive} alt={"Ja"} />}
        />
      </Grid>
      <Grid item xs={12}>
        <Pad
          labelText={"Nein"}
          onChange={() => setHasFurtherDocument(false)}
          type={"radio"}
          active={!hasFurtherDocument}
          error={false}
          name={"no"}
          labelIcon={<Iconize src={CrossSvg} alt={"Nein"} />}
          labelIconActive={<Iconize src={CrossSvgActive} alt={"Nein"} />}
        />
      </Grid>
      {hasFurtherDocument && (
        <Grid item xs={12}>
          <UploadBox
            product={product}
            currentFileIndex={temporaryFilesToCombineDataCurrentFileIndex}
            description={description}
            setDescription={setDescription}
            messagePath={messagePath}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Button fullWidth onClick={() => setCurrentStep((currentStep: number) => currentStep - 1)}>
          Zurück
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button disabled={hasFurtherDocument && (!hasDocument || description.length < 5)} fullWidth variant={"contained"} onClick={handleNextStepClick}>
          Weiter
        </Button>
      </Grid>
    </Grid>
  );
}

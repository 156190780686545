import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import useStyles from "./contractLawDetailsStyles";
import Typography from "@mui/material/Typography";
import CaseDetailsItem from "../../Common/CaseDetailsItem/CaseDetailsItem";

const GeneralContractLawCaseDetails = ({ contractLawCase }) => {
  const classes = useStyles({});
  return (
    <>
      <Typography variant={"h6"} className={classes.subheader}>
        Angaben zum Vertrag
      </Typography>

      <div className={classes.divider} />
      <Grid container>
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"name"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"caseCategory"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.gender"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.givenName"} />
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.familyName"} />
        {contractLawCase.caseCategory !== "advisory" && (
          <>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.residenceAddress.streetAddress"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.residenceAddress.postalCode"} />
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"client.residenceAddress.addressLocality"} />
          </>
        )}
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"customer.telephone"} />
        {contractLawCase.caseCategory !== "advisory" && (
          <>
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractDate"} fieldType={"date"} />
          </>
        )}
      </Grid>
    </>
  );
};

GeneralContractLawCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

GeneralContractLawCaseDetails.defaultProps = {};

export default GeneralContractLawCaseDetails;

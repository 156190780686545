import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Container from "../Container/Container";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import _find from "lodash/find";
import _split from "lodash/split";
import { getCaseLink } from "../../services/productService";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/reactQuery/reactQueryService";

const CalendlyRedirect = () => {
  const { responsibleParty, appointmentType } = useParams();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const { data: minimalCases, isLoading } = useQuery(queryKeys.collection("/collection/cases"), () => fetchCollection("/collection/cases"));

  const backofficeCaseId = atob(urlSearchParams.get("utm_source"));

  if (isLoading) {
    return (
      <Container style={{ width: 250 }}>
        <LegalbirdLoader />
      </Container>
    );
  }

  const minimalCase = _find(minimalCases, (product) => {
    const splitIri = _split(product.backofficeCase, "/");
    return splitIri[splitIri.length - 1] === backofficeCaseId;
  });

  return <Navigate to={`${getCaseLink(minimalCase)}/calendly/${responsibleParty}/${appointmentType}/${search}`} />;
};

export default CalendlyRedirect;

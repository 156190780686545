import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import legalFieldPageStyle from "./legalFieldPageStyle";
import Layout from "../../Layouts/Layout";
import Typography from "@mui/material/Typography";
import GreyBackgroundWrapper from "../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import ZipperTeaser from "../../../components/Teaser/ZipperTeaser";
import { legalFieldPageConfig } from "../../../services/landingPageService";
import ServiceworldTeaser from "../../../components/Teaser/ServiceworldTeaser";
import TrustSection from "../../../components/Sections/Generic/TrustSection/TrustSection";
import Container from "../../../components/Container/Container";

const useStyles = makeStyles(legalFieldPageStyle);

export default function LegalFieldPage({ productCategory }) {
  const classes = useStyles();

  const pageConfig = legalFieldPageConfig[productCategory];

  return (
    <Layout backgroundType={"landingpage"}>
      <Container>
        <Typography variant={"h1"} className={classes.centeredText}>
          {pageConfig.headline}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.centeredText}>
          {pageConfig.subtitle}
        </Typography>
        <img src={pageConfig.headlineImage} className={classes.image} alt={"Teaser image"} />
      </Container>
      <GreyBackgroundWrapper hasVerticalPadding>
        <Container>
          <Typography variant={"h3"} className={classes.centeredText}>
            Wir unterstützen Sie zum Beispiel bei den folgenden Themen:
          </Typography>
        </Container>
        <ZipperTeaser teaserRows={pageConfig.zipperTeaserRows} />
      </GreyBackgroundWrapper>
      <ServiceworldTeaser />
      <TrustSection />
    </Layout>
  );
}

LegalFieldPage.propTypes = {
  productCategory: PropTypes.string.isRequired,
};

import { Rating } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import FeedbackComment from "./FeedbackComment";

export default function Feedback({ feedbackEntity, saveFeedback }) {
  const [stars, setStars] = useState(parseInt(feedbackEntity.stars));
  const saveStars = (event, newStarsValue) => {
    setStars(newStarsValue);
  };

  return (
    <>
      <Rating name="stars" value={stars} disabled={!!feedbackEntity.feedbackDateTime} onChange={saveStars} />
      <FeedbackComment feedbackEntity={feedbackEntity} stars={stars} saveFeedback={saveFeedback} />
    </>
  );
}
Feedback.propTypes = {
  feedbackEntity: PropTypes.object.isRequired,
  saveFeedback: PropTypes.func.isRequired,
};

import { Grid, MenuItem, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../wizardStyle";
import { useForm } from "../../../provider/Form/FormProvider";
import classNames from "classnames";
import Hidden from "@mui/material/Hidden";
import React from "react";
import FormFader from "../Common/Fader/FormFader";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import {emailDefault, isRequired} from "../../../services/validationRules";
import _times from "lodash/times";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import _toString from "lodash/toString";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";
import { translate } from "../../../services/translations/translations";
import NumberFieldConfigured from "../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import Address from "../Common/Address/Address";

const useStyles = makeStyles(wizardStyle);
export default function Contract({ submitProps, product }) {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const { handleSubmit } = useForm();

  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Arbeitsverhältnis
            </Typography>
          </Hidden>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <FormLegend>Seit wann arbeiten Sie im Unternehmen?</FormLegend>
            </Grid>
            <Grid item xs={12} sm={6} className={classNames(classes.gridSpace, classes.gridMargin)}>
              <ValidatorSelectField
                label={translate("settlement.employedSinceMonth.label")}
                name="employedSinceMonth"
                validators={[
                  {
                    validator: isRequired,
                    message: "Bitte geben Sie den Monat an, seitdem Sie im Unternehmen arbeiten.",
                  },
                ]}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value={"01"}>{translate("settlement.employedSinceMonth.options.01.label")}</MenuItem>
                <MenuItem value={"02"}>{translate("settlement.employedSinceMonth.options.02.label")}</MenuItem>
                <MenuItem value={"03"}>{translate("settlement.employedSinceMonth.options.03.label")}</MenuItem>
                <MenuItem value={"04"}>{translate("settlement.employedSinceMonth.options.04.label")}</MenuItem>
                <MenuItem value={"05"}>{translate("settlement.employedSinceMonth.options.05.label")}</MenuItem>
                <MenuItem value={"06"}>{translate("settlement.employedSinceMonth.options.06.label")}</MenuItem>
                <MenuItem value={"07"}>{translate("settlement.employedSinceMonth.options.07.label")}</MenuItem>
                <MenuItem value={"08"}>{translate("settlement.employedSinceMonth.options.08.label")}</MenuItem>
                <MenuItem value={"09"}>{translate("settlement.employedSinceMonth.options.09.label")}</MenuItem>
                <MenuItem value={"10"}>{translate("settlement.employedSinceMonth.options.10.label")}</MenuItem>
                <MenuItem value={"11"}>{translate("settlement.employedSinceMonth.options.11.label")}</MenuItem>
                <MenuItem value={"12"}>{translate("settlement.employedSinceMonth.options.12.label")}</MenuItem>
              </ValidatorSelectField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorSelectField
                label={translate("settlement.employedSinceYear.label")}
                name="employedSinceYear"
                validators={[
                  {
                    validator: isRequired,
                    message: "Bitte geben Sie das Jahr an, seitdem Sie im Unternehmen arbeiten.",
                  },
                ]}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                {_times(currentYear - 1970 + 1, (i) => (
                  <MenuItem key={i} value={_toString(currentYear - i)}>
                    {currentYear - i}
                  </MenuItem>
                ))}
              </ValidatorSelectField>
            </Grid>
            <NumberFieldConfigured caseData={product} fieldPath={"grossSalary"} fieldType={"money"} required={true} />

            <Grid item xs={12}>
              <FormLegend>{translate("settlement.jobDescription.question")}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label={translate("settlement.jobDescription.label")}
                name="jobDescription"
                validators={[{ validator: isRequired, message: "Bitte geben Sie Ihre Berufsbezeichnung an." }]}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLegend>{translate("settlement.numberOfEmployees.question")}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate("settlement.numberOfEmployees.label")}
                name="numberOfEmployees"
                validators={[{ validator: isRequired, message: "Bitte geben Sie die Anzahl Mitarbeiter an" }]}
              >
                <MenuItem value={"10"}>{translate("settlement.numberOfEmployees.options.10.label")}</MenuItem>
                <MenuItem value={"50"}>{translate("settlement.numberOfEmployees.options.50.label")}</MenuItem>
                <MenuItem value={"500"}>{translate("settlement.numberOfEmployees.options.500.label")}</MenuItem>
                <MenuItem value={"1000"}>{translate("settlement.numberOfEmployees.options.1000.label")}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            <Grid item xs={12}>
              <FormLegend>{translate("settlement.worksCouncil.question")}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate("settlement.worksCouncil.label")}
                name="worksCouncil"
                validators={[{ validator: isRequired, message: "Bitte geben Sie an, ob ein Betriebsrat vorhanden ist." }]}
              >
                <MenuItem value={"yes"}>{translate("settlement.worksCouncil.options.yes.label")}</MenuItem>
                <MenuItem value={"no"}>{translate("settlement.worksCouncil.options.no.label")}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            <Grid item xs={12}>
              <FormLegend>
                Bitte geben Sie möglichst alle Kontaktdaten Ihres Arbeitgebers an, die Ihnen vorliegen. Sie finden die Adressdaten zum Beispiel auf Ihrem
                Arbeitsvertrag.
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField label={translate("settlement.employerOrganization.name.label")} name="employerOrganization.name" />
            </Grid>
            <Address path={"employerOrganization.address"} headline={""} fieldsAreRequired={false} />
            <Grid item xs={12}>
              <ValidatorTextField name={"employerOrganization.address.postOfficeBoxNumber"} label={translate("address.postOfficeBoxNumber.label")} />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridSpace}>
              <ValidatorTextField name={"employerOrganization.address.postOfficeBoxPostalCode"} label={translate("address.postOfficeBoxPostalCode.label")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ValidatorTextField
                name={"employerOrganization.address.postOfficeBoxAddressLocality"}
                label={translate("address.postOfficeBoxAddressLocality.label")}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name={"employerOrganization.email"}
                label={translate("settlement.employerOrganization.email.label")}
                validators={[emailDefault]}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField name={"employerOrganization.telephone"} label={translate("settlement.employerOrganization.telephone.label")} />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}

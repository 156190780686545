import React from "react";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import { Stack, Typography } from "@mui/material";
import { minStringLength, requiredValidator } from "../../../services/validationRules";
import ValidatorTextField from "../../FormFields/ValidatorTextField";

type InquiryToCustomerInformationBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
};

export default function InquiryToCustomerInformationBox({ inquiryToCustomer }: InquiryToCustomerInformationBoxProps) {
  const errorTextInquiryTextInformationCustomer =
    "Bitte teilen Sie uns die benötigten Informationen mit. Wenn Sie die Informationen nicht (vollständig) haben, dann schreiben Sie dies bitte hier kurz.";

  return (
    <Stack spacing={2}>
      <Typography variant={"h4"} sx={{ textAlign: "center" }}>
        Benötigte Informationen
      </Typography>
      <Typography sx={{whiteSpace: "pre-wrap"}}>{inquiryToCustomer.inquiryTextInformation}</Typography>
      <ValidatorTextField
        label="Ihre Antwort"
        name="inquiryTextInformationCustomer"
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        rows={5}
        validators={[
          requiredValidator(errorTextInquiryTextInformationCustomer),
          {
            validator: minStringLength,
            params: [10],
            message: errorTextInquiryTextInformationCustomer,
          },
        ]}
      />
    </Stack>
  );
}

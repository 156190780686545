import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Error404Page from "../../containers/Pages/Generic/Error404/Error404Page";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { getCaseLink, getProductData, getProductNameBy } from "../../services/productService";
import CalendlyBookingWidget from "./CalendlyBookingWidget";
import { getAppointmentDisplayType } from "../../services/serviceWorldService";
import CalendlyLimitReached from "./CalendlyLimitReached";
import Container from "../Container/Container";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../services/reactQuery/reactQueryService";
import useProductParams from "../../hooks/useProductParams";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

const Calendly = () => {
  const { responsibleParty, appointmentType } = useParams();
  const { productName, productId } = useProductParams();
  const { customer } = useCustomer();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  if (!responsibleParty || !appointmentType || !productName || !productId) {
    return <Error404Page />;
  }

  if (!customer || isLoading) {
    return (
      <Container style={{ width: 250 }}>
        <LegalbirdLoader />
      </Container>
    );
  }

  switch (getAppointmentDisplayType(product)) {
    case "appointments":
      return <Navigate to={getCaseLink(product)} />;
    case "bookingWidget":
      return (
        <CalendlyBookingWidget
          customer={customer}
          product={product}
          appointmentType={appointmentType}
          responsibleParty={responsibleParty}
          utmSource={urlSearchParams.get("utm_source")}
        />
      );
    case "limitReached":
    default:
      return <CalendlyLimitReached product={product} />;
  }
};

export default Calendly;

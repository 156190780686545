const teaserWbsStyle = (theme) => ({
  subText: {
    margin: "1rem 0",
  },
  textContent: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  button: {
    minWidth: 200,
  },
  smallImage: {
    maxWidth: "100%",
    width: "120px",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
});

export default teaserWbsStyle;

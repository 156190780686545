import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../EmailCheck/EmailCheck";
import ButtonLoading from "../../Button/ButtonLoading";
import ButtonTextTermsOfServicePrivacy from "../Common/ButtonTextTermsOfServicePrivacy/ButtonTextTermsOfServicePrivacy";
import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import { dismissalCheckinStyle } from "./dismissalCheckinStyle";
import { emailDefault, isPhoneNumberDefault, requiredValidator } from "../../../services/validationRules";
import ValidatorTextField from "../../FormFields/ValidatorTextField";

export default function DismissalCheckinForm({ isLoading, emailErrorText, setEmailErrorText }) {
  const { handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingRight: { sm: "1.5rem", md: "3rem" } }}>
          <ValidatorSelectField
            label={"Anzahl Mitarbeiter im Unternehmen"}
            name={"numberOfEmployees"}
            validators={[requiredValidator("Bitte geben Sie an, wie viele Mitarbeiter in Ihrem Unternehmen beschäftigt sind.")]}
          >
            <MenuItem value={"10"}>0 - 10</MenuItem>
            <MenuItem value={"50"}>11 - 50</MenuItem>
            <MenuItem value={"500"}>51 - 500</MenuItem>
            <MenuItem value={"1000"}>Mehr als 500</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: { sm: "1.5rem", md: 0 } }}>
          <EmailCheck
            name="email"
            label="Ihre E-Mail-Adresse"
            autoComplete="email"
            emailErrorText={emailErrorText}
            setEmailErrorText={setEmailErrorText}
            validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ paddingLeft: { md: "3rem" } }}>
          <ValidatorTextField
            name="telephone"
            autoComplete={"tel"}
            label="Ihre Telefonnummer"
            validators={[requiredValidator("Bitte geben Sie Ihre Telefonnummer an"), ...isPhoneNumberDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={dismissalCheckinStyle.buttonContainer}>
          <ButtonLoading variant="contained" color="primary" sx={dismissalCheckinStyle.button} type="submit" fullWidth isLoading={isLoading}>
            Kostenlos prüfen lassen
          </ButtonLoading>
          <ButtonTextTermsOfServicePrivacy />
        </Grid>
      </Grid>
    </form>
  );
}

import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./serviceWorldContentBoxStyle";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

const ServiceWorldContentBox = ({ icon = null, altText = "Icon", headline, children }) => {
  const classes = useStyles({});

  return (
    <Paper elevation={4} className={classes.contentBoxContainer}>
      <Grid container justifyContent={"center"} wrap={"nowrap"} className={classes.contentBoxHeadline}>
        <Grid item>
          <Grid container alignItems={"center"}>
            {icon &&
              <Grid item className={classes.imageCircleContainer}>
                <img src={icon} alt={altText} className={classes.headerIcon} />
              </Grid>
            }
            <Grid item>
              <Typography variant={"h5"} component={"h3"} align={"center"} paddingX={"2rem"}>
                {headline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
};

ServiceWorldContentBox.propTypes = {
  icon: PropTypes.string,
  altText: PropTypes.string,
  headline: PropTypes.string.isRequired,
};

export default ServiceWorldContentBox;

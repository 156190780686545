import makeStyles from '@mui/styles/makeStyles';

const useStlyles = makeStyles(() => ({
  background: {
    background: "linear-gradient(to bottom, #ffffff 25%, #acd2d0 25%, #acd2d0 85%, transparent 0)",
  },
  container: {
    textAlign: "center",
  },
  gridContainer: {
    marginTop: "1rem",
  },
  paper: {
    padding: "2rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "left",
    backgroundColor: "rgb(255,255,255,0.8)",
  },
}));

export default useStlyles;

/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { useEffect, useState } from "react";
import { apiGet } from "../../../../services/apiClient";
import { logout } from "../../../../store/login/actions";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

interface AccountDeletionPageProps {
  token: string;
}

export default function ConfirmAccountDeletion({ token }: AccountDeletionPageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [deletionSuccessful, setDeletionSuccessful] = useState(false);

  async function confirmAccountDeletion() {
    try {
      await apiGet(`/account_deletion/confirm/${encodeURIComponent(token)}`);
      setDeletionSuccessful(true);
      logout();
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    confirmAccountDeletion();
  }, []);

  if (isLoading) {
    return <LegalbirdLoader />;
  }

  return (
    <>
      <Box textAlign={"center"}>
        <Typography variant={"h1"} sx={{ marginBottom: "1rem" }}>
          {deletionSuccessful ? "Ihre Daten bei Legalbird wurden gelöscht" : "Ihre Daten konnten nicht gelöscht werden."}
        </Typography>
        <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
          {deletionSuccessful ? (
            <>
              <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
                Ihr Konto und all Ihre Daten bei Legalbird wurden wie gewünscht im Rahmen der gesetzlichen Bestimmungen gelöscht.
              </Typography>
              <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
                Wenn es bei Ihnen zukünftig doch Handlungsbedarf geben sollte oder noch Fragen aufkommen, können Sie sich gerne jederzeit wieder an uns wenden.
                Bis dahin wünschen wir Ihnen alles Gute!
              </Typography>
            </>
          ) : (
            <Typography variant={"body1"} sx={{ marginBottom: "1rem" }}>
              Ihre Daten konnten leider nicht gelöscht werden. Dies kann zum Beispiel daran liegen, dass Ihre Daten zuvor bereits gelöscht wurden.
            </Typography>
          )}
        </Typography>
      </Box>
    </>
  );
}

import makeStyles from '@mui/styles/makeStyles';
import formSubmitStyle from "../../Common/FormSubmit/formSubmitStyle";
import { scrollToAnchor } from "../../../../theme/commonStyle";

const useStyles = makeStyles((theme) => ({
  headline: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  subHeadline: {
    marginTop: "2rem",
    borderBottom: "1px solid #333",
    "& h5": {
      lineHeight: "1rem",
    },
  },
  question: {
    marginTop: "1rem",
    fontWeight: 400,
  },
  ...formSubmitStyle(theme),
  questionsTextArea: {
    margin: "2rem 0",
  },
  scrollToAnchor,
}));

export default useStyles;

import React from "react";
import { useForm } from "../../../provider/Form/FormProvider";
import { EditorState } from "draft-js";
import { Grid, Typography } from "@mui/material";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import { isRequired, maxStringLength, minStringLength } from "../../../services/validationRules";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { Editor } from "react-draft-wysiwyg";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ButtonLoading from "../../Button/ButtonLoading";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type MessageFormStepInnerProps = {
  product: AbstractCase;
  setEditorState: Function;
  isConversationAnswer: boolean;
  isLoading: boolean;
  editorState: EditorState;
  currentEditorStatePlainTextLength: number;
  editorStateLengthError: string | null;
};

export default function MessageFormStepInner({
  product,
  editorState,
  setEditorState,
  editorStateLengthError,
  currentEditorStatePlainTextLength,
  isLoading,
  isConversationAnswer,
}: MessageFormStepInnerProps) {
  const { handleSubmit } = useForm();
  const handleEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h4"} sx={{ textAlign: "center" }}>
            Nachricht verfassen
          </Typography>
        </Grid>
        {!product.isCustomerDataCompleted && (
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center", color: "red" }}>
              Achtung, Ihr Fall ist bislang nicht vollständig. Wir können Ihnen inhaltlich nur weiterhelfen, wenn Sie den Fall zunächst vervollständigen. Bitte
              stellen Sie uns daher hier nur technische Fragen zur Servicewelt.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
            Bitte geben Sie hier Ihre Nachricht ein. Sie haben im nächsten Schritt noch die Möglichkeit Ihrer Nachricht Dateien anzuhängen.
          </Typography>
        </Grid>
        {!isConversationAnswer && (
          <Grid item xs={12}>
            <ValidatorSelectField
              name={"type"}
              label={"Nachrichtenkategorie"}
              validators={[
                {
                  validator: isRequired,
                  message: "Bitte wählen Sie aus, welche Art von Nachricht Sie schreiben möchten.",
                },
              ]}
            >
              {product.isCustomerDataCompleted && <MenuItem value={"submitCaseInfo"}>Weitere Infos/ Neuigkeiten zu meinem Fall mitteilen</MenuItem>}
              {product.isCustomerDataCompleted && <MenuItem value={"questionNextSteps"}>Rückfrage zum weiteren Vorgehen</MenuItem>}
              {product.isCustomerDataCompleted && <MenuItem value={"legalQuestion"}>Rechtliche Frage an den Anwalt</MenuItem>}
              <MenuItem value={"technicalQuestion"}>Frage zur Servicewelt</MenuItem>
              {product.isCustomerDataCompleted && <MenuItem value={"other"}>Allgemeine Nachricht</MenuItem>}
            </ValidatorSelectField>
          </Grid>
        )}
        {!isConversationAnswer && (
          <Grid item xs={12}>
            <ValidatorTextField
              name={"subject"}
              label={"Nachrichtenbetreff"}
              validators={[
                {
                  validator: isRequired,
                  message: "Bitte geben Sie Ihrer Nachricht einen sprechenden Betreff, der den Inhalt kurz zusammenfasst.",
                },
                { validator: minStringLength, params: [5], message: "Bitte geben Sie mindestens 5 Zeichen ein" },
                { validator: maxStringLength, params: [80], message: "Bitte geben Sie maximal 80 Zeichen ein" },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Editor
            toolbar={{
              options: ["inline", "list"],
              inline: {
                options: ["bold", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={editorState}
            editorStyle={{ minHeight: 250 }}
            wrapperStyle={{ border: "1px solid #D3D3D3", borderRadius: "4px", padding: "1rem" }}
            onEditorStateChange={handleEditorStateChange}
            localization={{ locale: "de" }}
          />
          <Typography sx={currentEditorStatePlainTextLength > 5000 ? { color: "red" } : {}}>[{currentEditorStatePlainTextLength} / 5000]</Typography>
          {editorStateLengthError && (
            <Typography variant={"body2"} align={"center"} color={"red"}>
              {editorStateLengthError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Button fullWidth component={Link} to={"/servicewelt/nachrichten"}>
            Zurück
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonLoading fullWidth variant={"contained"} type={"submit"} isLoading={isLoading}>
            Weiter
          </ButtonLoading>
        </Grid>
      </Grid>
    </form>
  );
}

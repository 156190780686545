import React, { useMemo, useState } from "react";
import _last from "lodash/last";
import _head from "lodash/head";
import * as messagesActions from "../../../../store/messages/actions";
import { fileCheck, saveMediaObject } from "../../../../services/formServiceFunctions";
import _isFunction from "lodash/isFunction";
import FileUploadForm from "./FileUploadForm";
import _filter from "lodash/filter";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import { useMutation } from "@tanstack/react-query";
import { createMediaObject } from "../../../../services/reactQuery/reactQueryMediaObjectService";
import { updateResource } from "../../../../services/reactQuery/reactQueryService";
import { isAllowMultiplePagesFileType } from "../../../../services/mediaObjects";

const FileUploadSection = ({
  productEntity,
  fileArrayName,
  fileIndex,
  setIsBlocked,
  isBlocked,
  description,
  product,
  productEntityId,
  postSubmitCallback,
  updateAction,
  fieldName,
  withUploadNotification,
  allowAudioAndVideo,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filePath, setFilePath] = useState("");

  const updateMutation = useMutation(updateResource);
  const createMediaObjectMutation = useMutation(createMediaObject);

  const mediaObjectCollection = useMemo(() => _get(productEntity, [fileArrayName, fileIndex, "files"], []), [productEntity, fileArrayName, fileIndex]);
  const resetState = () => {
    setFilePath("");
    setIsLoading(false);
    setIsBlocked(false);
  };

  const handleChange = async ({ target }) => {
    if (mediaObjectCollection.length > 0 && !isAllowMultiplePagesFileType(target.files[0].name)) {
      messagesActions.addMessage({
        type: "error",
        text: "Die Datei kann leider nicht hochgeladen werden. Sie können entweder einzelne Dateien auswählen oder alternativ mehrere Bilder (JPG/PNG), die dann zu einem PDF Dokument zusammengefügt werden.",
      });
      resetState();
      return;
    }

    const fileCheckRes = fileCheck(target, allowAudioAndVideo);
    if (fileCheckRes.hasError === true) {
      messagesActions.addMessage({ type: "error", text: fileCheckRes.errorMsg });
      return;
    }

    const file = _head(target.files);
    setFilePath(file.name);
    await uploadFile(file);
  };

  const uploadFile = async (file) => {
    setIsLoading(true);
    setIsBlocked(true);

    const mediaObject = await saveMediaObject(createMediaObjectMutation, {
      file: file,
      fieldname: fileArrayName,
      description: description,
      product: product,
      productId: productEntityId || productEntity?.id,
    });

    if (mediaObject === false) {
      resetState();
      return;
    }

    let files = _cloneDeep(mediaObjectCollection);
    mediaObject.thumbnail = null;
    files.push(mediaObject);

    let fileArray = _cloneDeep(_get(productEntity, fileArrayName, []));
    _set(fileArray, [fileIndex, "files"], files);
    _set(fileArray, [fileIndex, "description"], description);
    _set(fileArray, [fileIndex, "fieldname"], fieldName);

    await updateAction(productEntity.id, {
      [fileArrayName]: fileArray,
    });

    _isFunction(postSubmitCallback) && postSubmitCallback();
    resetState();
  };

  const deleteMediaObject = async (mediaObjectToDelete) => {
    let fileArray = _cloneDeep(_get(productEntity, fileArrayName, []));
    const files = _filter(mediaObjectCollection, (mediaObject) => mediaObject.id !== mediaObjectToDelete.id);
    _set(fileArray, [fileIndex, "files"], files);

    await updateMutation.mutateAsync({ uri: "/media_objects", id: mediaObjectToDelete.id, data: { deleted: true } });
    await updateAction(productEntity.id, { [fileArrayName]: fileArray });
    _isFunction(postSubmitCallback) && postSubmitCallback();
  };

  const isPdfUploaded = () => {
    return mediaObjectCollection.length > 0 && _head(mediaObjectCollection).mimeType === "application/pdf";
  };

  return (
    <FileUploadForm
      mediaObjectCollection={mediaObjectCollection}
      isLoading={isLoading}
      deleteMediaObject={deleteMediaObject}
      handleFileChange={handleChange}
      fieldname={fieldName}
      allowMore={allowAudioAndVideo ? mediaObjectCollection.length === 0 || isAllowMultiplePagesFileType(_last(mediaObjectCollection).originalName) : !isPdfUploaded()}
      filePath={filePath}
      isBlocked={isBlocked}
      {...rest}
    />
  );
};
FileUploadSection.propTypes = {
  description: PropTypes.string,
};

FileUploadSection.defaultProps = {
  description: "Hochgeladene Datei",
  fileIndex: 0,
};

export default FileUploadSection;

import { Helmet } from "react-helmet-async";
import Container from "../../../../components/Container/Container";
import Layout from "../../../Layouts/Layout";
import React, { useEffect, useState } from "react";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import FileUploadSection from "../../../../components/Form/Common/FileUploadSection/FileUploadSection";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import TextContainer from "../../../../components/Container/TextContainer";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import requestDocumentsPageStyle from "./requestDocumentsPageStyle";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import ApiClient from "../../../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";

const useStyles = makeStyles(requestDocumentsPageStyle);

const RequestDocumentsPage = () => {
  const classes = useStyles();

  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternalInternal] = useState(false);

  const { productId } = useProductParams();
  const productApiUri = getProductData("divorce", "apiUri");
  const { data: divorce, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });
  const refreshDivorce = () => {
    queryClient.invalidateQueries([productApiUri, productId]);
  };

  const triggerUpdate = async () => {
    setIsLoadingInternalInternal(true);
    await ApiClient.post(`/generate_requested_mediaobjects/divorce/${divorce.id}`);
    navigate(getCaseLink(divorce));
  };

  useEffect(() => {
    if (divorce) {
      let hasRequestedDocument = divorce.marriageCertificate === null;
      _forEach(divorce.children, (child) => {
        hasRequestedDocument = hasRequestedDocument || child.birthCertificate === null;
      });
      if (!hasRequestedDocument) {
        navigate(getCaseLink(divorce));
      }
    }
  }, [divorce, navigate]);

  if (isLoading) {
    return (
      <Container style={{ width: 250 }}>
        <LegalbirdLoader />
      </Container>
    );
  }

  return (
    <Layout active="serviceworld" product={"divorce"}>
      <Helmet>
        <title>Legalbird - Fehlende Dokumente</title>
      </Helmet>
      <TextContainer>
        <HintBox headline={"Fehlende Dokumente ergänzen"}>Um Ihren Fall weiter bearbeiten zu können, benötigen wir noch folgende Unterlagen von Ihnen.</HintBox>
        <Paper elevation={4} className={classes.box}>
          {divorce.marriageCertificate === null && (
            <FileUploadSection
              fileArrayName={"marriageCertificateFiles"}
              headline={"Heiratsurkunde"}
              helperText={
                "Bitte laden Sie hier Ihre Heiratsurkunde hoch. Sollte Ihnen keine deutsche Heiratsurkunde vorliegen, benötigen Sie eine Übersetzung."
              }
              productEntity={divorce}
              productEntityId={divorce.id}
              product={"divorce"}
              updateAction={async (id, data) =>
                await updateMutation.mutateAsync({
                  uri: "/divorces",
                  id: id,
                  data: data,
                })
              }
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              limitPdfUpload={true}
              fieldName={"marriageCertificate"}
            />
          )}
          {_map(divorce.children, (child) => {
            if (child.birthCertificate !== null) {
              return null;
            }
            return (
              <FileUploadSection
                key={child.id}
                fileArrayName={"birthCertificateFiles"}
                headline={"Geburtsurkunde " + child.name}
                helperText={"Bitte laden Sie hier die Geburtsurkunde hoch."}
                productEntity={child}
                productEntityId={divorce.id}
                product={"divorce"}
                updateAction={async (id, data) =>
                  await updateMutation.mutateAsync({
                    uri: "/children",
                    id: id,
                    data: data,
                  })
                }
                setIsBlocked={setIsBlocked}
                isBlocked={isBlocked}
                postSubmitCallback={refreshDivorce}
                limitPdfUpload={true}
                fieldName={"birthCertificate"}
              />
            );
          })}
          <br />
          <br />
          <ButtonLoading
            onClick={triggerUpdate}
            color="primary"
            variant="contained"
            disabled={false}
            fullWidth={true}
            type={"submit"}
            isLoading={isLoadingInternal}
          >
            Dokumente Hochladen
          </ButtonLoading>
        </Paper>
      </TextContainer>
    </Layout>
  );
};

export default RequestDocumentsPage;

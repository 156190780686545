import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import Typography from "@mui/material/Typography";

const ContractualPartnerData = ({ formData, formPath, title }) => {
  const classes = useStyles({});
  const section = "contractualPartnerData";

  const contractualPartnerType = formData.contractualPartnerType;
  const contractualPartner =
    contractualPartnerType === "contractualPartnerLegalEntity"
      ? formData.contractualPartnerLegalEntity
      : formData.contractualPartnerPerson;
  const contractualPartnerAddress =
    contractualPartnerType === "contractualPartnerLegalEntity"
      ? formData.contractualPartnerLegalEntity.address
      : formData.contractualPartnerPerson.residenceAddress;

  return (
    <>
      <Grid item xs={12} className={classes.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      {contractualPartnerType === "contractualPartnerLegalEntity" && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"name"} className={classes.scrollToAnchor} />
            Name Unternehmen
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "name",
              },
            }}
          >
            {formData.contractualPartnerLegalEntity.legalName}
          </GridEditRow>
        </>
      )}
      {contractualPartnerType === "contractualPartnerPerson" && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"gender"} className={classes.scrollToAnchor} />
            Anrede
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "gender",
              },
            }}
          >
            {formData.contractualPartnerPerson.gender === "male" ? "Herr" : "Frau"}
          </GridEditRow>
          <Grid item xs={12} className={classes.question}>
            <div id={"givenName"} className={classes.scrollToAnchor} />
            Vorname
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "givenName",
              },
            }}
          >
            {formData.contractualPartnerPerson.givenName}
          </GridEditRow>

          <Grid item xs={12} className={classes.question}>
            <div id={"familyName"} className={classes.scrollToAnchor} />
            Nachname
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "familyName",
              },
            }}
          >
            {formData.contractualPartnerPerson.familyName}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.streetAddress && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"streetAddress"} className={classes.scrollToAnchor} />
            Straße und Hausnummer Vertragspartner
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "streetAddress",
              },
            }}
          >
            {contractualPartnerAddress.streetAddress}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postalCode && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"postalCode"} className={classes.scrollToAnchor} />
            Postleitzahl Vertragspartner
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postalCode",
              },
            }}
          >
            {contractualPartnerAddress.postalCode}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.addressLocality && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"addressLocality"} className={classes.scrollToAnchor} />
            Ort Vertragspartner
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "addressLocality",
              },
            }}
          >
            {contractualPartnerAddress.addressLocality}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postOfficeBoxNumber && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"postOfficeBoxNumber"} className={classes.scrollToAnchor} />
            Postfach
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postOfficeBoxNumber",
              },
            }}
          >
            {contractualPartnerAddress.postOfficeBoxNumber}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postOfficeBoxPostalCode && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"postOfficeBoxPostalCode"} className={classes.scrollToAnchor} />
            Postfach PLZ
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postOfficeBoxPostalCode",
              },
            }}
          >
            {contractualPartnerAddress.postOfficeBoxPostalCode}
          </GridEditRow>
        </>
      )}
      {contractualPartnerAddress.postOfficeBoxAddressLocality && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"postOfficeBoxAddressLocality"} className={classes.scrollToAnchor} />
            Postfach Ort
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "postOfficeBoxAddressLocality",
              },
            }}
          >
            {contractualPartnerAddress.postOfficeBoxAddressLocality}
          </GridEditRow>
        </>
      )}
      {contractualPartner.email && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"email"} className={classes.scrollToAnchor} />
            E-Mail-Adresse Vertragspartner
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "email",
              },
            }}
          >
            {contractualPartner.email}
          </GridEditRow>
        </>
      )}
      {contractualPartner.telephone && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"telephone"} className={classes.scrollToAnchor} />
            Telefonnummer Vertragspartner
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "telephone",
              },
            }}
          >
            {contractualPartner.telephone}
          </GridEditRow>
        </>
      )}
    </>
  );
};

ContractualPartnerData.propTypes = {
  formData: PropTypes.object.isRequired,
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContractualPartnerData;

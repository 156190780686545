import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  newCaseContainer: {
    margin: "25px 0",
  },
  bottomPadding: {
    paddingBottom: "1.6rem",
  },
  productCategoryHeadline: {
    display: "block",
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.background.light,
  },
  icon: {
    width: 30,
    height: 30,
    marginTop: 5,
  },
  modalContent: {
    padding: "2rem 0",
  },
  infoIcon: {
    color: "#999999",
    margin: "0 0 0 auto",
  },
  productItem: {
    padding: "15px 25px",
    "&:hover": {
      backgroundColor: "#d0e7e6",
    },
  },
}));

export default useStyles;

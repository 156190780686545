import React from "react";
import MainUploadFormStep from "./MainUploadFormStep";
import FurtherUploadFormStep from "./FurtherUploadFormStep";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import NoteFormStep from "./NoteFormStep";
import UploadCompleted from "./UploadCompleted";
import { getFinishedStep, getMainDocumentStep, getNoteStep } from "../../../services/documentUploadFormService";

type StepSwitchProps = {
  currentStep: number;
  setCurrentStep: Function;
  steps: number;
  setSteps: Function;
  setIsLoading: Function;
  product: AbstractCase;
};

export default function StepSwitch({ currentStep, product, setCurrentStep, steps, setSteps, setIsLoading }: StepSwitchProps) {
  switch (currentStep) {
    case getMainDocumentStep():
      return <MainUploadFormStep product={product} setCurrentStep={setCurrentStep} setIsLoading={setIsLoading} />;
    case getNoteStep(steps):
      return <NoteFormStep setCurrentStep={setCurrentStep} product={product} />;
    case getFinishedStep(steps):
      return <UploadCompleted product={product} />;
    case steps:
      return null;
    default:
      return (
        <FurtherUploadFormStep
          key={currentStep}
          product={product}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          steps={steps}
          setSteps={setSteps}
          setIsLoading={setIsLoading}
        />
      );
  }
}

/*
 * Copyright © 2023. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { AbstractCase } from "../../types/Entities/AbstractCase";
import React, { useMemo } from "react";
import { translate } from "../translations/translations";
import Iconize from "../../components/SvgIcon/Iconize";
import PriceIncreaseSvg from "../../assets/icons/provider/inactive/price_increase_inactive.svg";
import PriceIncreaseActiveSvg from "../../assets/icons/provider/active/price_increase_active.svg";
import CrossSvg from "../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../assets/icons/common/active/kreuz.svg";
import ProviderCancellationSvg from "../../assets/icons/provider/inactive/provider_cancellation_inactive.svg";
import ProviderCancellationActiveSvg from "../../assets/icons/provider/active/provider_cancellation_active.svg";
import IconCancellationSvgRefused from "../../assets/icons/contract/inactive/icon_wehren.svg";
import IconCancellationSvgRefusedActive from "../../assets/icons/contract/active/icon_wehren_active.svg";
import WrongInvoiceSvg from "../../assets/icons/provider/inactive/provider_wrong_invoice_inactive.svg";
import WrongInvoiceActiveSvg from "../../assets/icons/provider/active/provider_wrong_invoice_active.svg";
import QuestionMarkSvg from "../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionMarkSvgActive from "../../assets/icons/common/active/fragezeichen.svg";
import GymSvg from "../../assets/icons/contract/inactive/icon_gym.svg";
import GymSvgActive from "../../assets/icons/contract/active/icon_gym_active.svg";
import ClubSvg from "../../assets/icons/contract/inactive/icon_club.svg";
import ClubSvgActive from "../../assets/icons/contract/active/icon_club_active.svg";
import CalendarSvg from "../../assets/icons/common/inactive/icon_calendar.svg";
import CalendarSvgActive from "../../assets/icons/common/active/icon_calendar_active.svg";

export const membershipContractFormDefinitions = {
  reasonForDispute: reasonForDispute,
  membershipType: membershipType,
  feePaymentFrequency: feePaymentFrequency,
  opponentReactionToCancellation: opponentReactionToCancellation,
};

function feePaymentFrequency() {
  return {
    options: ["monthly", "quarterly", "yearly"],
  };
}

function opponentReactionToCancellation(values: AbstractCase) {
  return [
    {
      labelText: translate(`${values.name}.opponentReactionToCancellation.options.noReaction.label`),
      labelIcon: <Iconize src={CrossSvg} alt={translate(`${values.name}.opponentReactionToCancellation.options.noReaction.label`)} />,
      labelIconActive: <Iconize src={CrossSvgActive} alt={translate(`${values.name}.opponentReactionToCancellation.options.noReaction.label`)} />,
      value: "noReaction",
      optionHintText: translate(`${values.name}.opponentReactionToCancellation.options.noReaction.optionHintText`),
    },
    {
      labelText: translate(`${values.name}.opponentReactionToCancellation.options.cancellationRefused.label`),
      labelIcon: <Iconize src={CrossSvg} alt={translate(`${values.name}.opponentReactionToCancellation.options.cancellationRefused.label`)} />,
      labelIconActive: <Iconize src={CrossSvgActive} alt={translate(`${values.name}.opponentReactionToCancellation.options.cancellationRefused.label`)} />,
      value: "cancellationRefused",
      optionHintText: translate(`${values.name}.opponentReactionToCancellation.options.cancellationRefused.optionHintText`),
    },
    {
      labelText: translate(`${values.name}.opponentReactionToCancellation.options.differentDate.label`),
      labelIcon: <Iconize src={CalendarSvg} alt={translate(`${values.name}.opponentReactionToCancellation.options.differentDate.label`)} />,
      labelIconActive: <Iconize src={CalendarSvgActive} alt={translate(`${values.name}.opponentReactionToCancellation.options.differentDate.label`)} />,
      value: "differentDate",
      optionHintText: translate(`${values.name}.opponentReactionToCancellation.options.differentDate.optionHintText`),
    },
  ];
}

function membershipType(values: AbstractCase) {
  return useMemo(() => {
    return [
      {
        labelText: translate(`${values.name}.membershipType.options.gym.label`),
        labelIcon: <Iconize src={GymSvg} alt={translate(`${values.name}.membershipType.options.gym.label`)} />,
        labelIconActive: <Iconize src={GymSvgActive} alt={translate(`${values.name}.membershipType.options.gym.label`)} />,
        value: "gym",
      },
      {
        labelText: translate(`${values.name}.membershipType.options.club.label`),
        labelIcon: <Iconize src={ClubSvg} alt={translate(`${values.name}.membershipType.options.club.label`)} />,
        labelIconActive: <Iconize src={ClubSvgActive} alt={translate(`${values.name}.membershipType.options.club.label`)} />,
        value: "club",
      },
      {
        labelText: translate(`${values.name}.membershipType.options.other.label`),
        labelIcon: <Iconize src={QuestionMarkSvg} alt={translate(`${values.name}.membershipType.options.other.label`)} />,
        labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={translate(`${values.name}.membershipType.options.other.label`)} />,
        value: "other",
      },
    ];
  }, [values.providerType]);
}

function reasonForDispute(values: AbstractCase) {
  return useMemo(() => {
    return [
      {
        labelText: translate(`${values.name}.reasonForDispute.options.priceIncrease.label`),
        labelIcon: <Iconize src={PriceIncreaseSvg} alt={translate(`${values.name}.reasonForDispute.options.priceIncrease.label`)} />,
        labelIconActive: <Iconize src={PriceIncreaseActiveSvg} alt={translate(`${values.name}.reasonForDispute.options.priceIncrease.label`)} />,
        value: "priceIncrease",
        optionHintText: translate(`${values.name}.reasonForDispute.options.priceIncrease.optionHintText`),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.cancellationByContractPartner.label`),
        labelIcon: <Iconize src={ProviderCancellationSvg} alt={translate(`${values.name}.reasonForDispute.options.cancellationByContractPartner.label`)} />,
        labelIconActive: (
          <Iconize src={ProviderCancellationActiveSvg} alt={translate(`${values.name}.reasonForDispute.options.cancellationByContractPartner.label`)} />
        ),
        value: "cancellationByContractPartner",
        optionHintText: translate(`${values.name}.reasonForDispute.options.cancellationByContractPartner.optionHintText`),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.cancellationRefused.label`),
        labelIcon: <Iconize src={IconCancellationSvgRefused} alt={translate(`${values.name}.reasonForDispute.options.cancellationRefused.label`)} />,
        labelIconActive: <Iconize src={IconCancellationSvgRefusedActive} alt={translate(`${values.name}.reasonForDispute.options.cancellationRefused.label`)} />,
        value: "cancellationRefused",
        optionHintText: translate(`${values.name}.reasonForDispute.options.cancellationRefused.optionHintText`),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.invoiceIncorrect.label`),
        labelIcon: <Iconize src={WrongInvoiceSvg} alt={translate(`${values.name}.reasonForDispute.options.invoiceIncorrect.label`)} />,
        labelIconActive: <Iconize src={WrongInvoiceActiveSvg} alt={translate(`${values.name}.reasonForDispute.options.invoiceIncorrect.label`)} />,
        value: "invoiceIncorrect",
        optionHintText: translate(`${values.name}.reasonForDispute.options.invoiceIncorrect.optionHintText`),
      },
      {
        labelText: translate(`${values.name}.reasonForDispute.options.other.label`),
        labelIcon: <Iconize src={QuestionMarkSvg} alt={translate(`${values.name}.reasonForDispute.options.other.label`)} />,
        labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={translate(`${values.name}.reasonForDispute.options.other.label`)} />,
        value: "other",
        optionHintText: translate(`${values.name}.reasonForDispute.options.other.optionHintText`),
      },
    ];
  }, [values.providerType]);
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import ContentBox from "../../Box/ContentBox/ContentBox";
import { Rating } from "@mui/material";
import { handleChangeObject } from "../../Checkin/Common/checkinCommonForHooks";
import makeStyles from "@mui/styles/makeStyles";
import customerFeedbackBoxStyle from "./customerFeedbackBoxStyle";
import TextField from "@mui/material/TextField";
import ButtonLoading from "../../Button/ButtonLoading";
import _head from "lodash/head";
import _filter from "lodash/filter";
import iconReview from "../../../assets/icons/serviceOverview/icon_review_200px.png";
import CustomerFeedbackProvenExpertBox from "./CustomerFeedbackProvenExpertBox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";

const useStyles = makeStyles(customerFeedbackBoxStyle);

export default function CustomerFeedbackBox({ product }) {
  const classes = useStyles();
  const urlParams = {
    source: "fullReview",
    caseId: product.id,
  };
  const { data: feedbackCollection, isLoading: queryIsLoading } = useQuery(queryKeys.collection("/customer_feedbacks", urlParams), () =>
    fetchCollection("/customer_feedbacks", urlParams)
  );
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    stars: 0,
    comment: "",
  });

  if (queryIsLoading || !feedbackCollection) {
    return null;
  }

  const filteredFeedbackCollection = _filter(
    feedbackCollection,
    (feedbackEntity) => !feedbackEntity.feedbackDateTime || feedbackEntity.showProvenExpertRatingRequest
  );

  if (filteredFeedbackCollection.length === 0) {
    return null;
  }

  const currentFeedback = _head(filteredFeedbackCollection);
  const handleSubmit = async () => {
    setIsLoading(true);

    let payload = {
      stars: parseInt(values.stars),
      comment: values.comment,
    };

    if (parseInt(values.stars) === 5 && currentFeedback.isFinalReview) {
      payload.showProvenExpertRatingRequest = true;
    }

    await updateMutation.mutateAsync({
      uri: "/customer_feedbacks",
      id: currentFeedback.id,
      data: payload,
    });
    await queryClient.invalidateQueries(queryKeys.collection("/customer_feedbacks", urlParams));
    setIsLoading(false);
  };

  if (currentFeedback.showProvenExpertRatingRequest) {
    return <CustomerFeedbackProvenExpertBox product={product} customerFeedback={currentFeedback} />;
  }

  return (
    <ContentBox icon={iconReview} headline={"Bitte bewerten Sie uns"}>
      <p>Wie zufrieden waren Sie bisher mit dem durch uns erbrachten Service? Wir freuen uns über Ihre Bewertung.</p>
      <div className={classes.ratingContainer}>
        <Rating name="stars" value={parseInt(values.stars)} onChange={(e) => handleChangeObject(e, setValues)} size="large" />
      </div>
      <TextField
        label="Ihr Kommentar"
        name="comment"
        value={values.comment}
        onChange={(e) => handleChangeObject(e, setValues)}
        multiline
        rows="4"
        maxRows="4"
        margin="normal"
        fullWidth
      />
      <ButtonLoading fullWidth color="primary" variant="contained" isLoading={isLoading} disabled={values.stars < 1} onClick={handleSubmit}>
        Bewertung abgeben
      </ButtonLoading>
    </ContentBox>
  );
}
CustomerFeedbackBox.propTypes = {
  product: PropTypes.object.isRequired,
};

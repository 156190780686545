import React from "react";
import { Route, Routes } from "react-router-dom";
import PaidPage from "../../containers/Pages/Payment/PaidPage/PaidPage";
import CheckoutPage from "../../containers/Pages/Checkout/CheckoutPage";
import Error404Page from "../../containers/Pages/Generic/Error404/Error404Page";
import StripeChargeResult from "../../components/Payment/PaymentCheck/StripeChargeResult";
import StripeCreditCardCheckoutForm from "../../components/Payment/StripeCreditCardCheckoutForm/StripeCreditCardCheckoutForm";
import PayPalChargeResult from "../../components/Payment/PaymentCheck/PayPalChargeResult";
import PaymentFailedPage from "../../containers/Pages/Payment/PaymentFailedPage/PaymentFailedPage";
import GeneralCheck from "../../components/Payment/PaymentCheck/GeneralCheck";
import DocumentGenerationFailed from "../../components/Payment/DocumentGenerationFailed/DocumentGenerationFailed";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../config/_enviroment";
import useTracker from "../../services/useTracker";
import { getQueryParam } from "../../services/urlParamService";
import { CheckoutOrderProvider } from "../../provider/CheckoutOrder/CheckoutOrderProvider";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function Checkout() {
  const orderId = getQueryParam(location.search, "orderId");
  useTracker();
  return (
    <CheckoutOrderProvider orderId={orderId}>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path={`paypal/abbruch`} element={<PaymentFailedPage />} />
          <Route path={`paypal/bezahlt`} element={<PaidPage />} />
          <Route path={`paypal/pruefung`} element={<PayPalChargeResult />} />
          <Route path={`paypal`} element={<CheckoutPage />} />

          <Route path={`kreditkarte/abbruch`} element={<PaymentFailedPage />} />
          <Route path={`kreditkarte/bezahlt`} element={<PaidPage />} />
          <Route path={`kreditkarte`} element={<StripeCreditCardCheckoutForm />} />

          <Route path={`sofort/abbruch`} element={<PaymentFailedPage />} />
          <Route path={`sofort/pruefung/:orderId/:source`} element={<StripeChargeResult />} />
          <Route path={`sofort/bezahlt`} element={<PaidPage />} />
          <Route path={`sofort`} element={<CheckoutPage />} />

          <Route path={`dokument/erstellen`} element={<GeneralCheck />} />

          <Route path={`erfolgreich`} element={<PaidPage />} />
          <Route path={`dokumentenfehler`} element={<DocumentGenerationFailed />} />

          <Route path={``} element={<CheckoutPage />} />
          <Route path={`*`} element={<Error404Page />} status={404} />
        </Routes>
      </Elements>
    </CheckoutOrderProvider>
  );
}

import teaserImage from "../assets/img/teaser/Legalbird_Teaser_Main_500px.jpg";

import _find from "lodash/find";

// Key of list entry has to be unique and match the last part of the url of the respective page.
// Url defines (via router) which component will serve as lp template, see component Landingpage. It also serves landing page crawler list

export const landingPagesList = {
  "internet-scheidung": {
    keyword: "Internet Scheidung",
    headline: "Internet Scheidung einfach & günstig",
    url: "/lp/internet-scheidung",
    backgroundImage: teaserImage,
  },
  "online-scheidung": {
    keyword: "Online Scheidung",
    headline: "Online Scheidung einfach & günstig",
    url: "/lp/online-scheidung",
    backgroundImage: teaserImage,
  },
  scheidungsfolgenvereinbarung: {
    keyword: "Scheidungsfolgenvereinbarung",
    headline: "Scheidungsfolgenvereinbarung",
    url: "/lp/scheidungsfolgenvereinbarung",
    backgroundImage: teaserImage,
  },
  scheidungsantrag: {
    keyword: "Scheidungsantrag",
    headline: "Scheidungsantrag einreichen",
    url: "/lp/scheidungsantrag",
    backgroundImage: teaserImage,
  },
  scheidung: {
    keyword: "Scheidung",
    headline: "Scheidung einfach & günstig",
    url: "/lp/scheidung",
    backgroundImage: teaserImage,
  },
  kuendigung: {
    keyword: "Kündigung",
    headline: "Kündigung erhalten? Jetzt Klage einreichen!",
    url: "/lp/kuendigung",
    backgroundImage: teaserImage,
  },
  abfindung: {
    keyword: "Abfindung",
    headline: "Abfindung bei Kündigung? Jetzt berechnen & klagen!",
    url: "/lp/abfindung",
    backgroundImage: teaserImage,
  },
  "bussgeld-fahrverbot": {
    keyword: "Bußgeldbescheid & Fahrverbot",
    headline: "Jetzt Einspruch einlegen!",
    url: "/lp/bussgeld-fahrverbot",
  },
  "rote-ampel": {
    keyword: "Rotlichtverstoß",
    headline: "Rote Ampel überfahren?",
    url: "/lp/rote-ampel",
  },
  abstandsverstoss: {
    keyword: "Abstandsverstoß Bußgeld",
    headline: "Zu nah aufgefahren?",
    url: "/lp/abstandsverstoss",
  },
  geschwindigkeit: {
    keyword: "Überhöhte Geschwindigkeit Bußgeld",
    headline: "Zu schnell gefahren?",
    url: "/lp/geschwindigkeit",
  },
  "verkehr-sonstiges": {
    keyword: "Weitere Verkehrsverstöße",
    headline: "Bußgeldbescheid kostenlos prüfen lassen!",
    url: "/lp/verkehr-sonstiges",
  },
  kurzarbeit: {
    keyword: "Analyse Kurzarbeit",
    headline: "Kurzarbeit? Jetzt Analyse erstellen!",
    url: "/lp/kurzarbeit",
  },
  kurzarbeitergeld: {
    keyword: "Kurzarbeitergeld berechnen",
    headline: "Kurzarbeit? Jetzt neues Gehalt berechnen!",
    url: "/lp/kurzarbeitergeld",
  },

  //partnerpages, keys not in use, keyword only for lp list
  //advocard
  "bussgeld-fahrverbot-advocard": {
    keyword: "Bußgeldbescheid & Fahrverbot Advocard",
    url: "/lp/advocard/bussgeld-fahrverbot",
  },
  "unterhalt-advocard": {
    keyword: "Unterhalt Advocard",
    url: "/lp/advocard/unterhalt",
  },
  "familienrecht-advocard": {
    keyword: "Familienrecht Advocard",
    url: "/lp/advocard/familienrecht",
  },
  "scheidung-advocard": {
    keyword: "Scheidung Advocard",
    url: "/lp/advocard/scheidung",
  },
  "todesfall-advocard": {
    keyword: "Todesfall Advocard",
    url: "/lp/advocard/todesfall",
  },
  "familienrecht-beratung-advocard": {
    keyword: "Familienrecht Beratung Advocard",
    url: "/lp/advocard/familienrecht-beratung",
  },
  "vertragsrecht-advocard": {
    keyword: "Vertragsrecht Advocard",
    url: "/lp/advocard/vertragsrecht",
  },
  "kurzarbeit-advocard": {
    keyword: "Kurzarbeit Advocard",
    url: "/lp/advocard/kurzarbeit",
  },
  "energie-hilfe-advocard": {
    keyword: "Advocard Energie Hilfe",
    url: "/lp/advocard/energie-hilfe",
  },

  //arag
  "bussgeld-fahrverbot-arag": {
    keyword: "Bußgeldbescheid & Fahrverbot Arag",
    url: "/lp/arag/bussgeld-fahrverbot",
  },
  "unterhalt-arag": {
    keyword: "Unterhalt Arag",
    url: "/lp/arag/unterhalt",
  },
  "familienrecht-arag": {
    keyword: "Familienrecht Arag",
    url: "/lp/arag/familienrecht",
  },
  "scheidung-arag": {
    keyword: "Scheidung Arag",
    url: "/lp/arag/scheidung",
  },
  "todesfall-arag": {
    keyword: "Todesfall Arag",
    url: "/lp/arag/todesfall",
  },
  "familienrecht-beratung-arag": {
    keyword: "Familienrecht Beratung Arag",
    url: "/lp/arag/familienrecht-beratung",
  },
  "kuendigung-arag": {
    keyword: "Kuendigung Arag",
    url: "/lp/arag/kuendigung",
  },

  //roland
  "unterhalt-roland": {
    keyword: "Unterhalt Roland",
    url: "/lp/roland/unterhalt",
  },
  "familienrecht-roland": {
    keyword: "Familienrecht Roland",
    url: "/lp/roland/familienrecht",
  },
  "scheidung-roland": {
    keyword: "Scheidung Roland",
    url: "/lp/roland/scheidung",
  },
  "todesfall-roland": {
    keyword: "Todesfall Roland",
    url: "/lp/roland/todesfall",
  },
  "familienrecht-beratung-roland": {
    keyword: "Familienrecht Beratung Roland",
    url: "/lp/roland/familienrecht-beratung",
  },

  //check24
  "kuendigung-check24": {
    keyword: "Kuendigung Check24",
    url: "/lp/check24/kuendigung",
  },
  "scheidung-check24": {
    keyword: "Scheidung Check24",
    url: "/lp/check24/scheidung",
  },
  "bussgeld-fahrverbot-check24": {
    keyword: "Bußgeldbescheid & Fahrverbot Check24",
    url: "/lp/check24/bussgeld-fahrverbot",
  },

  //wbs
  "bussgeld-fahrverbot-wbs": {
    keyword: "Bußgeldbescheid & Fahrverbot WBS",
    url: "/lp/wbs/bussgeld-fahrverbot",
  },
  "kuendigung-wbs": {
    keyword: "Kündigungsschutzklage WBS",
    url: "/lp/wbs/kuendigung",
  },
  "schufa-wbs": {
    keyword: "Schufa WBS",
    url: "/lp/wbs/schufa",
  },

  //blitzereinspruch
  "bussgeld-fahrverbot-blitzereinspruch": {
    keyword: "Bußgeldbescheid & Fahrverbot Blitzereinspruch",
    url: "/lp/blitzereinspruch/bussgeld-fahrverbot",
  },
};

const stripTrailingSlash = (str) => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const getLpByLocation = (location) => {
  return _find(landingPagesList, ["url", stripTrailingSlash(location)]);
};

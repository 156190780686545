import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import { Grid, Hidden, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import classNames from "classnames";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Iconize from "../../../SvgIcon/Iconize";
import MoneySvg from "../../../../assets/icons/contract/inactive/icon_geld-einfordern.svg";
import MoneySvgActive from "../../../../assets/icons/contract/active/icon_geld-einfordern_active.svg";
import IncompleteSvg from "../../../../assets/icons/common/inactive/icon_incomplete.svg";
import IncompleteActiveSvg from "../../../../assets/icons/common/active/icon_incomplete_active.svg";
import BrokenSvg from "../../../../assets/icons/contract/inactive/icon_broken.svg";
import BrokenActiveSvg from "../../../../assets/icons/contract/active/icon_broken_active.svg";
import AlarmSvg from "../../../../assets/icons/contract/inactive/icon_alarm.svg";
import AlarmActiveSvg from "../../../../assets/icons/contract/active/icon_alarm_active.svg";
import QuestionMarkSvg from "../../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionMarkSvgActive from "../../../../assets/icons/common/active/fragezeichen.svg";
import CheckSvg from "../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../assets/icons/common/inactive/haken.svg";
import CrossSvg from "../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../assets/icons/common/inactive/kreuz.svg";
import { translate } from "../../../../services/translations/translations";
import _includes from "lodash/includes";
import { dateFieldDefault, requiredValidator } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import Group from "../../../Wizard/Group";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";
import NumberFieldConfigured from "../../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";

const useStyles = makeStyles(wizardStyle);
export default function ServiceContractDetails({ submitProps, product }) {
  const classes = useStyles();
  const { values, handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="reasonForDispute"
                question={translate(`${product.name}.reasonForDispute.question`)}
                options={[
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.costEstimateExceeded.label`),
                    labelIcon: <Iconize src={MoneySvg} alt={translate(`${product.name}.reasonForDispute.options.costEstimateExceeded.label`)} />,
                    labelIconActive: <Iconize src={MoneySvgActive} alt={translate(`${product.name}.reasonForDispute.options.costEstimateExceeded.label`)} />,
                    value: "costEstimateExceeded",
                  },
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.unfinishedWork.label`),
                    labelIcon: <Iconize src={IncompleteSvg} alt={translate(`${product.name}.reasonForDispute.options.unfinishedWork.label`)} />,
                    labelIconActive: <Iconize src={IncompleteActiveSvg} alt={translate(`${product.name}.reasonForDispute.options.unfinishedWork.label`)} />,
                    value: "unfinishedWork",
                  },
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.hasDefects.label`),
                    labelIcon: <Iconize src={BrokenSvg} alt={translate(`${product.name}.reasonForDispute.options.hasDefects.label`)} />,
                    labelIconActive: <Iconize src={BrokenActiveSvg} alt={translate(`${product.name}.reasonForDispute.options.hasDefects.label`)} />,
                    value: "hasDefects",
                  },
                  {
                    labelText: translate(`${product.name}.reasonForDispute.options.other.label`),
                    labelIcon: <Iconize src={QuestionMarkSvg} alt={translate(`${product.name}.reasonForDispute.options.other.label`)} />,
                    labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={translate(`${product.name}.reasonForDispute.options.other.label`)} />,
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>Welche Dienstleistung haben Sie beauftragt?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                id="serviceTitle"
                name="serviceTitle"
                label="Beschreibung der Dienstleistung"
                helperText="Zum Beispiel: Aufbau einer Küche"
                validators={[requiredValidator("Bitte schildern Sie uns hier kurz die Dienstleistung.")]}
              />
            </Grid>
            {values.reasonForDispute === "costEstimateExceeded" && (
              <>
                <NumberFieldConfigured caseData={product} fieldPath={"costEstimate"} required numberType={"float"} fieldType={"money"} />

                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="costEstimateAdjustment"
                    question={translate(`${product.name}.costEstimateAdjustment.question`)}
                    options={[
                      {
                        labelText: translate(`${product.name}.costEstimateAdjustment.options.asap.label`),
                        labelIcon: <Iconize src={CheckSvg} alt={translate(`${product.name}.costEstimateAdjustment.options.asap.label`)} />,
                        labelIconActive: <Iconize src={CheckSvgActive} alt={translate(`${product.name}.costEstimateAdjustment.options.asap.label`)} />,
                        value: "asap",
                      },
                      {
                        labelText: translate(`${product.name}.costEstimateAdjustment.options.tooLate.label`),
                        labelIcon: <Iconize src={AlarmSvg} alt={translate(`${product.name}.costEstimateAdjustment.options.tooLate.label`)} />,
                        labelIconActive: <Iconize src={AlarmActiveSvg} alt={translate(`${product.name}.costEstimateAdjustment.options.tooLate.label`)} />,
                        value: "tooLate",
                      },
                      {
                        labelText: translate(`${product.name}.costEstimateAdjustment.options.notAtAll.label`),
                        labelIcon: <Iconize src={CrossSvg} alt={translate(`${product.name}.costEstimateAdjustment.options.notAtAll.label`)} />,
                        labelIconActive: <Iconize src={CrossSvgActive} alt={translate(`${product.name}.costEstimateAdjustment.options.notAtAll.label`)} />,
                        value: "notAtAll",
                      },
                    ]}
                    validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                  />
                </Grid>
                {_includes(["asap", "tooLate"], values.costEstimateAdjustment) && (
                  <>
                    <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                      <FormLegend>Wann hat Ihnen der Vertragspartner die Kostenerhöhung mitgeteilt?</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorDateField
                        label="Datum Information Erhöhung"
                        name="costEstimateAdjustmentDate"
                        validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            <NumberFieldConfigured caseData={product} fieldPath={"costReal"} required numberType={"float"} fieldType={"money"} />

            {_includes(["hasDefects", "unfinishedWork"], values.reasonForDispute) && (
              <>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Bitte beschreiben Sie hier die Mängel so genau wie möglich.</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label="Beschreibung Mängel"
                    name="defectsDescriptionClient"
                    validators={[requiredValidator("Bitte geben Sie die Mängel an.")]}
                    multiline
                    rows={6}
                    helperText={
                      "Beschreiben Sie möglichst detailliert, damit wir eine gute Grundlage für das rechtliche Vorgehen haben und führen Sie mehrere Mängel in einer Liste mit Spiegelstrichen auf."
                    }
                  />
                </Grid>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Wurde vom Vertragspartner bereits versucht die Mängel zu beheben und falls ja, wie oft?</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${product.name}.numberRepairAttempts.label`)}
                    name="numberRepairAttempts"
                    validators={[requiredValidator("Bitte geben Sie die Anzahl der Ausbesserungsversuche an")]}
                  >
                    <MenuItem value="0">{translate(`${product.name}.numberRepairAttempts.options.0.label`)}</MenuItem>
                    <MenuItem value="1">{translate(`${product.name}.numberRepairAttempts.options.1.label`)}</MenuItem>
                    <MenuItem value="2">{translate(`${product.name}.numberRepairAttempts.options.2.label`)}</MenuItem>
                    <MenuItem value="3">{translate(`${product.name}.numberRepairAttempts.options.3.label`)}</MenuItem>
                  </ValidatorSelectField>
                </Grid>
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import Error404PageService from "../../containers/Pages/Generic/Error404/Error404PageService";
import ServiceWorldCasePage from "../../containers/Pages/ServiceWorld/ServiceWorldCasePage";
import CalendlyPage from "../../containers/Pages/ServiceWorld/CalendlyPage";
import ApplicationFormPage from "../../containers/Pages/ApplicationForm/ApplicationFormPage";
import CaseCompleteLoader from "../../components/Serviceworld/CaseCompleteLoader/CaseCompleteLoader";
import DocumentUploadPage from "../../containers/Pages/ServiceWorld/DocumentUploadPage";
import InquiryToCustomerPage from "../../containers/Pages/ServiceWorld/InquiryToCustomerPage";
import InquiryToCustomerAnsweredPage from "../../containers/Pages/ServiceWorld/InquiryToCustomerAnsweredPage";

export default function ServiceworldCase() {
  return (
    <Routes>
      <Route path={"fall-vollstaendig"} element={<CaseCompleteLoader />} />
      <Route path={"antrag/*"} element={<ApplicationFormPage />} />
      <Route path={"calendly/:responsibleParty/:appointmentType/*"} element={<CalendlyPage />} />
      <Route path={"dokumente-hochladen"} element={<DocumentUploadPage />} />
      <Route path={"anfrage"} element={<InquiryToCustomerPage />} />
      <Route path={"anfrage/beantwortet"} element={<InquiryToCustomerAnsweredPage />} />
      <Route path={""} element={<ServiceWorldCasePage />} />
      <Route path={"*"} element={<Error404PageService />} status={404} />
    </Routes>
  );
}

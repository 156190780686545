import React, { useState } from "react";
import { Typography } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { FormProvider } from "../../../provider/Form/FormProvider";
import { EditorState } from "draft-js";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { editorStateToHTML } from "../../../services/editorService";
import { getProductData } from "../../../services/productService";
import { getTemporaryFilesToCombineDataWithNewMessageEntry } from "../../../services/messagingService";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { Channel } from "../../../types/Entities/Messaging/Channel";
import MessageFormStepInner from "./MessageFormStepInner";

type NoteFormStepProps = {
  product: AbstractCase;
  setCurrentStep: Function;
  setMessageCreateData: Function;
};

export default function MessageFormStep({ setCurrentStep, product, setMessageCreateData }: NoteFormStepProps) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateLengthError, setEditorStateLengthError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const currentEditorStatePlainTextLength = editorState.getCurrentContent().getPlainText("").length;
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  const channelFilter = {
    case: product.id,
  };

  const { data: caseChannels, isLoading: isLoadingChannels } = useQuery(queryKeys.collection("/channels", channelFilter), () =>
    fetchCollection("/channels", channelFilter)
  );

  if (isLoadingChannels) {
    return <LegalbirdLoader />;
  }

  if (!caseChannels) {
    return <Typography align={"center"}>Es ist ein technischer Fehler aufgetreten, bitte melden Sie sich bei unserem Kundendienst.</Typography>;
  }
  const conversationId = searchParams.get("conversationId");
  const isConversationAnswer = !!conversationId;
  const onSubmit = async ({ values }: Record<string, any>) => {
    setEditorStateLengthError("");
    if (currentEditorStatePlainTextLength < 40) {
      setEditorStateLengthError("Bitte geben Sie mindestens 40 Zeichen ein");
      return;
    }
    if (currentEditorStatePlainTextLength > 5000) {
      setEditorStateLengthError("Bitte geben Sie maximal 5000 Zeichen ein");
      return;
    }
    setIsLoading(true);

    const mainChannel = caseChannels.find((channel: Channel) => channel.type === "all");

    if (!isConversationAnswer && !mainChannel) {
      return;
    }

    const conversationPayload = isConversationAnswer
      ? "/conversations/" + conversationId
      : {
          subject: values.subject,
          type: values.type,
          channel: "/channels/" + mainChannel.id,
        };

    const identifier = Date.now();

    const createMessageData = {
      messageText: editorStateToHTML(editorState),
      conversation: conversationPayload,
      temporaryFilesToCombineDataIdentifier: identifier,
    };

    setMessageCreateData(createMessageData);

    await updateMutation.mutateAsync({
      uri: getProductData(product.name, "apiUri"),
      id: product.id,
      data: {
        temporaryFilesToCombineData: getTemporaryFilesToCombineDataWithNewMessageEntry(product, identifier),
      },
    });
    setIsLoading(false);
    setCurrentStep((currentStep: number) => currentStep + 1);
  };

  const initialValues = {
    type: product.isCustomerDataCompleted ? "" : "technicalQuestion",
    subject: "",
  };

  return (
    <FormProvider initialValues={initialValues} submitAction={onSubmit}>
      <MessageFormStepInner
        product={product}
        setEditorState={setEditorState}
        editorState={editorState}
        editorStateLengthError={editorStateLengthError}
        isConversationAnswer={isConversationAnswer}
        currentEditorStatePlainTextLength={currentEditorStatePlainTextLength}
        isLoading={isLoading}
      />
    </FormProvider>
  );
}

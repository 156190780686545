import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import headlineSectionStyle from "./headlineSectionStyle";
import Typography from "@mui/material/Typography";
import Container from "../../../Container/Container";


const useStyles = makeStyles(headlineSectionStyle);

export default function HeadlineSection({headline, typographyVariant, typographyComponent, image, smallImage}) {

  const classes = useStyles();

  return (
    <Container>
      <Typography variant={typographyVariant} component={typographyComponent || typographyVariant} className={classes.centeredText}>
        {headline}
      </Typography>
      {image && <img src={image} alt={"teaser"} className={smallImage ? classes.smallImage : classes.image}/>}
    </Container>
  );
};

HeadlineSection.propTypes = {
  headline: PropTypes.string.isRequired,
  typographyVariant: PropTypes.string,
  typographyComponent: PropTypes.string,
  image: PropTypes.string,
  smallImage: PropTypes.bool,
};

HeadlineSection.defaultProps = {
  typographyVariant: "h1",
  smallImage: true,
};

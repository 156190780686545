import React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { isRequired, postalCodeDefault, requiredValidator } from "../../../../services/validationRules";
import FormLegend from "../FormLegend/FormLegend";

const useStyles = makeStyles(wizardStyle);

const PostOfficeBoxAddress = ({ path, headline, fieldsAreRequired, disabled }) => {
  const classes = useStyles();
  return (
    <>
      {headline && (
        <Grid item xs={12} className={classes.gridTopSpaced}>
          <FormLegend>{headline}</FormLegend>
        </Grid>
      )}
      <Grid item xs={12}>
        <ValidatorTextField
          name={`${path}.postOfficeBoxNumber`}
          label="Postfach"
          disabled={disabled}
          validators={fieldsAreRequired ? [requiredValidator("Bitte geben Sie das Postfach an")] : []}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridSpace}>
        <ValidatorTextField
          name={`${path}.postOfficeBoxPostalCode`}
          label="Postfach PLZ"
          disabled={disabled}
          validators={
            fieldsAreRequired
              ? [requiredValidator("Bitte geben Sie die Postleitzahl an"), postalCodeDefault]
              : [postalCodeDefault]
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ValidatorTextField
          name={`${path}.postOfficeBoxAddressLocality`}
          label="Postfach Ort"
          disabled={disabled}
          validators={fieldsAreRequired ? [{ validator: isRequired, message: "Bitte geben Sie den Ort an" }] : []}
        />
      </Grid>
    </>
  );
};

PostOfficeBoxAddress.propTypes = {
  path: PropTypes.string.isRequired,
  headline: PropTypes.string,
  fieldsAreRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};

PostOfficeBoxAddress.defaultProps = {
  headline: "Wo wohnen Sie?",
  fieldsAreRequired: true,
  disabled: false,
};

export default PostOfficeBoxAddress;

import React from "react";
import useStyles from "./processBoxStyle";
import { Grid } from "@mui/material";
import _map from "lodash/map";
import classNames from "classnames";
import { getProcessConfig } from "../../../services/serviceWorldService";
import iconCheck from "../../../assets/icons/serviceOverview/icon_check_150px.png";
import iconProgress from "../../../assets/icons/serviceOverview/icon_progress_128px.png";
import { ProcessBoxLoader } from "../../ContentLoader/ProcessBoxLoader";
import ContentBox from "../../Box/ContentBox/ContentBox";
import PropTypes from "prop-types";

const ProcessBox = ({ product }) => {
  const classes = useStyles({});

  if (!product) {
    return (
      <ContentBox icon={iconProgress} headline={"Prozessablauf"} alignContent={"stretch"} loading={!product}>
        <ProcessBoxLoader />
      </ContentBox>
    );
  }

  if (product.isCustomerDataCompleted) {
    return null;
  }

  const processConfig = getProcessConfig(product);

  if (!processConfig) {
    return null;
  }

  return (
    <ContentBox icon={iconProgress} headline={processConfig.headline} alignContent={"stretch"} loading={!product}>
      <Grid container>
        {_map(processConfig.steps, (step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Grid container className={classNames(classes.iconContainer)} direction="column" alignItems="center">
                {index === 0 ? (
                  <Grid item className={classes.connectorLineInvisible} />
                ) : (
                  <Grid item className={classes.connectorLine} />
                )}
                <Grid item className={classes.progressIcons}>
                  <img src={step.done ? iconCheck : step.icon} alt="Step Icon" />
                </Grid>
                {index === processConfig.steps.length - 1 ? (
                  <Grid item className={classes.connectorLineInvisible} />
                ) : (
                  <Grid item className={classes.connectorLine} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={10} className={classNames(classes.textBlock, step.done ? classes.done : null)}>
              <span className={classes.stepHeading}>{step.headline}</span>
              <p className={classes.stepText}>{step.content}</p>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </ContentBox>
  );
};

ProcessBox.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProcessBox;

import React, { useEffect } from "react";
import useStyles from "./summaryStyle";
import { Paper } from "@mui/material";
import TextContainer from "../../../Container/TextContainer";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Grid from "@mui/material/Grid";
import ContractData from "./ContractData";
import ContractualPartnerData from "./ContractualPartnerData";
import ContractDescription from "./ContractDescription";
import InsuranceData from "./InsuranceData";
import { useLocation } from "react-router-dom";
import SummaryContractDetails from "./SummaryContractDetails";
import DebtCollectionAgencyData from "./DebtCollectionAgencyData";
import { getCaseLink } from "../../../../services/productService";
import { useForm } from "../../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import EmailVerificationModal from "../../../FormInfoModal/EmailVerificationModal";

const Summary = ({ product, submitProps }) => {
  const classes = useStyles();
  const location = useLocation();
  const stateField = location.state?.field;
  const { values, handleSubmit } = useForm();

  useEffect(() => {
    const el = document.getElementById(stateField);
    el && el.scrollIntoView();
  }, [stateField]);

  const basePath = getCaseLink(product) + "/antrag";

  return (
    <Paper elevation={4}>
      <TextContainer>
        <Typography variant={"h4"} className={classes.headline}>
          Ihre Angaben
        </Typography>
        <Grid container>
          <div id={"contractData"} className={classes.scrollToAnchor} />
          <ContractData caseData={product} formUrl={basePath + "/vertrag"} />
          {product.caseCategory !== "advisory" && (
            <>
              <SummaryContractDetails caseData={product} formUrl={basePath + "/vertragsdetails"} />
              {product.reasonForDispute !== "schufaPositiveData" && (
                <>
                  <div id={"contractualPartnerData"} className={classes.scrollToAnchor} />
                  <ContractualPartnerData title={"Angaben zum Vertragspartner"} formPath={basePath + "/vertragspartner"} formData={values} />
                </>
              )}
            </>
          )}
          {product.caseCategory === "defendClaim" && product.defenseProcessStatus === "debtCollection" && (
            <>
              <div id={"debtCollectionAgencyData"} className={classes.scrollToAnchor} />
              <DebtCollectionAgencyData title={"Angaben zum Inkassobüro"} formPath={basePath + "/inkasso"} formData={values} />
            </>
          )}
          <div id={"contractDescription"} className={classes.scrollToAnchor} />
          {product.reasonForDispute !== "schufaPositiveData" && <ContractDescription title={"Beschreibung der Situation"} formPath={basePath + "/beschreibung"} formData={values} />}
          <div id={"insuranceData"} className={classes.scrollToAnchor} />
          <InsuranceData title={"Versicherung"} formPath={basePath + "/versicherung"} formData={values} product={product} />
        </Grid>
        <form onSubmit={handleSubmit}>
          <FormSubmit {...submitProps} hideBackButton={false} backLink={basePath + "/vertrag"} backText={"Alles bearbeiten"} nextText={"Angaben speichern"} />
        </form>
      </TextContainer>
      <EmailVerificationModal />
    </Paper>
  );
};

export default Summary;

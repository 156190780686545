import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { getProductData } from "../productService";
import { getCurrentLink, getDocumentParams, getMaxApplicationStatus } from "../applicationStatus";
import {
  STATUS_CHECKED,
  STATUS_COMPLETED,
  STATUS_CREATED,
  STATUS_DATA_COMPLETED,
  STATUS_ORDERED,
  STATUS_PAID,
} from "../document/leadStatus";

const getContent = (shortTimeWork) => {
  let nextStepContent = {
    header: "",
    text: "",
  };

  switch (shortTimeWork.leadStatus) {
    default:
    case STATUS_CREATED:
      nextStepContent.header = "Jetzt " + getDocumentParams(shortTimeWork.name).titlePossessive + " fertig stellen!";
      nextStepContent.text =
        "Es fehlen nur noch wenige Schritte, bis Sie " +
        getDocumentParams(shortTimeWork.name).titlePossessive +
        " herunterladen können.";
      break;
    case STATUS_DATA_COMPLETED:
      nextStepContent.header =
        "Jetzt " + getDocumentParams(shortTimeWork.name).titlePossessive + " prüfen und fertig stellen!";
      nextStepContent.text =
        "Es fehlen nur noch wenige Schritte, bis Sie " +
        getDocumentParams(shortTimeWork.name).titlePossessive +
        " herunterladen können.";
      break;

    case STATUS_CHECKED:
    case STATUS_ORDERED:
      nextStepContent.header = "Jetzt Zahlung durchführen und Dokument herunterladen";
      nextStepContent.text =
        "Es fehlt nur noch ein Schritt, damit Sie " +
        getDocumentParams(shortTimeWork.name).titlePossessive +
        " herunterladen können.";
      break;
    case STATUS_PAID:
    case STATUS_COMPLETED:
      nextStepContent.header = "Ihre Analyse wurde erstellt";
      nextStepContent.text = "";
      break;
  }

  return nextStepContent;
};

const getButton = (shortTimeWork) => {
  let text = "Fertigstellen";

  switch (shortTimeWork.leadStatus) {
    case 10:
    case 20:
      text = "Fertigstellen";
      break;

    case 30:
    case 40:
      text = "Zur Bezahlung";
      break;

    default:
      return null;
  }

  return (
    <Button component={Link} to={getCurrentLink(shortTimeWork)} variant="contained" color="primary" fullWidth>
      {text}
    </Button>
  );
};

const getPercentage = (shortTimeWork) => {
  if (shortTimeWork.leadStatus === STATUS_COMPLETED) {
    return 100;
  }
  return (Math.max(1, shortTimeWork.applicationStatus) / (getMaxApplicationStatus(shortTimeWork.name) + 10)) * 100;
};

export const getStatusBoxConfigShortTimeWork = (shortTimeWork) => {
  let statusBoxConfig = {
    header: "Es ist ein Fehler aufgetreten",
    text: "",
    ctaButton: null,
    productName: "",
    percentage: 100,
  };

  const ctaButton = getButton(shortTimeWork);
  const content = getContent(shortTimeWork);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(shortTimeWork.name, "label");
  statusBoxConfig.percentage = getPercentage(shortTimeWork);

  return statusBoxConfig;
};

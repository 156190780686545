import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  backButton: {
    marginTop: "2rem",
  },
  appointmentBoxbutton: {
    margin: ".5rem 0",
  },
  contentContainer: {
    padding: 25,
    maxWidth: 500,
    margin: "0 auto",
  },
}));

export default useStyles;

import { Conversation } from "../types/Entities/Messaging/Conversation";
import { AbstractCase } from "../types/Entities/AbstractCase";
import _ from "lodash";
import {UseMutationResult} from "@tanstack/react-query";
import {UpdateResourceParams} from "./reactQuery/reactQueryService";
import {getProductData} from "./productService";
import {apiPost} from "./apiClient";
import {Message} from "../types/Entities/Messaging/Message";
import structuredClone from '@ungap/structured-clone';

export const caseHasUnreadMessage = (caseId: number, conversations: Array<Conversation>) => {
  const caseConversations = conversations.filter((conversation) => conversation.channel.caseId === caseId);
  return !!caseConversations.find((conversation) => conversationHasUnreadMessage(conversation));
};

export const conversationHasUnreadMessage = (conversation: Conversation) => {
  return !!conversation.containedMessages.find(message => !message.readByMe);
}

export const markMessagesAsRead = async (messages: Array<Message>) => {
  let promises: Array<Promise<any>> = [];
  messages.forEach(message => {
    if (!message.readByMe) {
      promises.push(
        apiPost("/message_user_read_statuses", {
          message: "/messages/" + message.id
        })
      );
    }
  });
  await Promise.all(promises);
};

export const getNewMessageLink = (productName: string, productId: string|number, conversationId : number|null = null) => {
  const baseLink = `/servicewelt/nachrichten/neu?product=${productName}&productId=${productId}`;
  return conversationId ? `${baseLink}&conversationId=${conversationId}` : baseLink;
};

export const getTemporaryFilesToCombineDataWithNewMessageEntry = (product: AbstractCase, identifier: number) => {
  let temporaryFilesToCombineDataClone = structuredClone(product.temporaryFilesToCombineData);

  if (!temporaryFilesToCombineDataClone) {
    return {
      messages: {
        [identifier]: [],
      },
    };
  }

  if (!temporaryFilesToCombineDataClone.messages) {
    temporaryFilesToCombineDataClone.messages = {
      [identifier]: [],
    };
    return temporaryFilesToCombineDataClone;
  }

  _.set(temporaryFilesToCombineDataClone, "messages." + identifier, []);
  return temporaryFilesToCombineDataClone;
};

export const handleAttachmentDescriptionSave = async (
  product: AbstractCase,
  fileIndex: number,
  messageId: string,
  description: string,
  updateMutation: UseMutationResult<any, unknown, UpdateResourceParams>
) => {
  let temporaryFilesToCombineDataClone = structuredClone(product.temporaryFilesToCombineData);
  _.set(temporaryFilesToCombineDataClone, ["messages", messageId, fileIndex, "description"], description);

  await updateMutation.mutateAsync({
    uri: getProductData(product.name, "apiUri"),
    id: product.id,
    data: {
      temporaryFilesToCombineData: temporaryFilesToCombineDataClone,
    },
  });
};

export const getMessageStep = () => {
  return 0;
};

export const getFinishedStep = (steps: number) => {
  return steps - 1;
};

export const getMainAttachmentStep = () => {
  return 1;
};

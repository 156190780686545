import makeStyles from '@mui/styles/makeStyles';
import { scrollToAnchor } from "../../theme/commonStyle";

const useStyles = makeStyles(() => ({
  contentContainer: {
    padding: 25,
    maxWidth: 500,
    margin: "0 auto",
    "& > div": {
      marginBottom: 25,
    },
  },
  loaderContainer: {
    marginTop: 50,
  },
  scrollToAnchor,
}));

export default useStyles;

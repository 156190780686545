/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { useForm } from "../../provider/Form/FormProvider";
import React, { useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { FormControl, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formValue } from "../../services/formServiceFunctions";
import FormHelperText from "@mui/material/FormHelperText";
import _get from "lodash/get";
import validatorStyle from "./validatorStyle";
import _find from "lodash/find";
import { FormDataTypes } from "../../types/FormDataTypes";

const useStyles = makeStyles(validatorStyle);

type ValidatorAutocompleteProps = {
  options: Array<any>;
  valueOptionAttribute?: string;
  noOptionsText?: string;
  name: string;
  label: string;
  helperText?: string;
  validators?: Array<any>;
  dependentValidationFields?: Array<any>;
  fullWidth?: boolean;
  margin?: "dense" | "normal" | "none";
};

export default function ValidatorAutocomplete({
  options,
  valueOptionAttribute = "label",
  noOptionsText = "Nicht gefunden",
  name,
  label,
  helperText,
  validators = [],
  dependentValidationFields = [],
  fullWidth = true,
  margin = "normal",
  ...rest
}: ValidatorAutocompleteProps) {
  const classes = useStyles();
  const { values, errors, setError, handleChange, registerValidators }: FormDataTypes = useForm();
  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, [name]);

  const handleAutoCompleteValueChange = (e: any, value: { [x: string]: any }) => {
    handleChange({
      target: {
        //@ts-ignore
        type: "text",
        name: name,
        value: value[valueOptionAttribute],
      },
    });
    setError(name, undefined);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} error={error}>
      <Autocomplete
        key={name}
        renderInput={(params) => <TextField {...params} label={label} className={classes.textField} />}
        options={options}
        disableClearable
        value={_find(options, (option) => option[valueOptionAttribute] === formValue(values, name)) || null}
        onChange={handleAutoCompleteValueChange}
        noOptionsText={noOptionsText}
        {...rest}
      />
      <FormHelperText>{_get(errors, name) || helperText}</FormHelperText>
    </FormControl>
  );
}

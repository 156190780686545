import React from "react";
import { Route, Routes } from "react-router-dom";
import LpOnlineDivorce from "../../containers/Pages/Divorce/LandingPagesDivorce/LpOnlineDivorce/LpOnlineDivorce";
import LpDivorcePetition from "../../containers/Pages/Divorce/LandingPagesDivorce/LpDivorcePetition/LpDivorcePetition";
import LpLinksOverviewPage from "../../containers/Pages/Generic/LpLinksOverviewPage/LpLinksOverviewPage";
import LpDivorceAgreement from "../../containers/Pages/Divorce/LandingPagesDivorce/LpDivorceAgreement/LpDivorceAgreement";
import LpCalculateSettlement from "../../containers/Pages/Settlement/LandingsPagesSettlement/LpCalculateSettlement/LpCalculateSettlement";
import LpDivorceCheck24 from "../../containers/Pages/Divorce/LandingPagesDivorce/LpDivorceCheck24/LpDivorceCheck24";
import LpDismissalCheck24 from "../../containers/Pages/Settlement/LandingsPagesSettlement/LpDismissalCheck24/LpDismissalCheck24";
import LpModules from "../../containers/Pages/Divorce/LandingPagesDivorce/LpModules/LpModules";
import LpDivorce from "../../containers/Pages/Divorce/LandingPagesDivorce/LpDivorce/LpDivorce";
import LpFineDrivingBan from "../../containers/Pages/Traffic/LandingPagesTraffic/LpFineDrivingBan/LpFineDrivingBan";
import LpRedLight from "../../containers/Pages/Traffic/LandingPagesTraffic/LpRedLight/LpRedLight";
import LpSpeeding from "../../containers/Pages/Traffic/LandingPagesTraffic/LpSpeeding/LpSpeeding";
import LpTrafficOther from "../../containers/Pages/Traffic/LandingPagesTraffic/LpTrafficOther/LpTrafficOther";
import LpDistance from "../../containers/Pages/Traffic/LandingPagesTraffic/LpDistance/LpDistance";
import LpShortTimeWorkAdvocard from "../../containers/Pages/ShortTimeWork/LandingPagesShortTimeWork/LpShortTimeWorkAdvocard/LpShortTimeWorkAdvocard";
import LpShortTimeWork from "../../containers/Pages/ShortTimeWork/LandingPagesShortTimeWork/LpShortTimeWork/LpShortTimeWork";
import LpShortTimeWorkAllowance from "../../containers/Pages/ShortTimeWork/LandingPagesShortTimeWork/LpShortTimeWorkAllowance/LpShortTimeWorkAllowance";
import LpDismissal from "../../containers/Pages/Settlement/LandingsPagesSettlement/LpDismissal/LpDismissal";
import LpContractAdvocard from "../../containers/Pages/ContractLaw/LandingPagesContractLaw/AdvoCard/LpContractAdvocard";
import DismissalCheckAragPage from "../../containers/Pages/Settlement/LandingsPagesSettlement/LpDismissalArag/DismissalCheckAragPage";
import LpFineDrivingBanPartner from "../../containers/Pages/Traffic/LandingPagesTraffic/LpFineDrivingBanPartner/LpFineDrivingBanPartner";
import LpAlimonyPartner from "../../containers/Pages/FamilyLaw/LandingPagesFamilyLaw/LpAlimonyPartner";
import LpFamilyLawPartner from "../../containers/Pages/FamilyLaw/LandingPagesFamilyLaw/LpFamilyLawPartner";
import LpDivorcePartner from "../../containers/Pages/FamilyLaw/LandingPagesFamilyLaw/LpDivorcePartner";
import LpDeathPartner from "../../containers/Pages/FamilyLaw/LandingPagesFamilyLaw/LpDeathPartner";
import LpFamilyLawConsultingPartner from "../../containers/Pages/FamilyLaw/LandingPagesFamilyLaw/LpFamilyLawConsultingPartner";
import useTracker from "../../services/useTracker";
import HomePage from "../../containers/Pages/Generic/HomePage/HomePage";
import LpExternalLawyer from "../../containers/Pages/LpExternalLawyer/LpExternalLawyer";
import LpSpecialEnergyCasesAdvocard from "../../containers/Pages/ContractLaw/LandingPagesContractLaw/AdvoCard/LpSpecialEnergyCasesAdvocard";
import DismissalCheckWbsPage from "../../containers/Pages/Settlement/LandingsPagesSettlement/LpDismissalWbs/DismissalCheckWbsPage";
import SchufaWbsPage from "../../containers/Pages/ContractLaw/LandingPagesContractLaw/Wbs/SchufaWbsPage";

export default function LandingPage() {
  useTracker({ withGclid: true });
  return (
    <Routes>
      <Route path={`links-overview`} element={<LpLinksOverviewPage />} />
      <Route path={`internet-scheidung`} element={<LpOnlineDivorce />} />
      <Route path={`online-scheidung`} element={<LpOnlineDivorce />} />
      <Route path={`scheidungsfolgenvereinbarung`} element={<LpDivorceAgreement />} />
      <Route path={`scheidungsantrag`} element={<LpDivorcePetition />} />
      <Route path={`scheidung`} element={<LpDivorce />} />
      <Route path={`kuendigung`} element={<LpDismissal />} />
      <Route path={`abfindung`} element={<LpCalculateSettlement />} />
      <Route path={`bussgeld-fahrverbot`} element={<LpFineDrivingBan />} />
      <Route path={`rote-ampel`} element={<LpRedLight />} />
      <Route path={`abstandsverstoss`} element={<LpDistance />} />
      <Route path={`geschwindigkeit`} element={<LpSpeeding />} />
      <Route path={`verkehr-sonstiges`} element={<LpTrafficOther />} />
      <Route path={`kurzarbeit`} element={<LpShortTimeWork />} />
      <Route path={`kurzarbeitergeld`} element={<LpShortTimeWorkAllowance />} />
      <Route path={`modules`} element={<LpModules />} />

      <Route path={`advocard/bussgeld-fahrverbot`} element={<LpFineDrivingBanPartner partnerName={"advocard"} />} />
      <Route path={`advocard/unterhalt`} element={<LpAlimonyPartner partner={"advocard"} />} />
      <Route path={`advocard/familienrecht`} element={<LpFamilyLawPartner partner={"advocard"} />} />
      <Route path={`advocard/scheidung`} element={<LpDivorcePartner partner={"advocard"} />} />
      <Route path={`advocard/todesfall`} element={<LpDeathPartner partner={"advocard"} />} />
      <Route path={`advocard/familienrecht-beratung`} element={<LpFamilyLawConsultingPartner partner={"advocard"} />} />
      <Route path={`advocard/vertragsrecht`} element={<LpContractAdvocard />} />
      <Route path={`advocard/kurzarbeit`} element={<LpShortTimeWorkAdvocard />} />
      <Route path={`advocard/energie-hilfe`} element={<LpSpecialEnergyCasesAdvocard />} />

      <Route path={`arag/bussgeld-fahrverbot`} element={<LpFineDrivingBanPartner partnerName={"arag"} />} />
      <Route path={`arag/unterhalt`} element={<LpAlimonyPartner partner={"arag"} />} />
      <Route path={`arag/familienrecht`} element={<LpFamilyLawPartner partner={"arag"} />} />
      <Route path={`arag/scheidung`} element={<LpDivorcePartner partner={"arag"} />} />
      <Route path={`arag/todesfall`} element={<LpDeathPartner partner={"arag"} />} />
      <Route path={`arag/familienrecht-beratung`} element={<LpFamilyLawConsultingPartner partner={"arag"} />} />
      <Route path={`arag/kuendigung`} element={<DismissalCheckAragPage />} />

      <Route path={`roland/unterhalt`} element={<LpAlimonyPartner partner={"roland"} />} />
      <Route path={`roland/familienrecht`} element={<LpFamilyLawPartner partner={"roland"} />} />
      <Route path={`roland/scheidung`} element={<LpDivorcePartner partner={"roland"} />} />
      <Route path={`roland/todesfall`} element={<LpDeathPartner partner={"roland"} />} />
      <Route path={`roland/familienrecht-beratung`} element={<LpFamilyLawConsultingPartner partner={"roland"} />} />

      <Route path={`check24/kuendigung`} element={<LpDismissalCheck24 />} />
      <Route path={`check24/scheidung`} element={<LpDivorceCheck24 />} />
      <Route path={`check24/bussgeld-fahrverbot`} element={<LpFineDrivingBanPartner partnerName={"check24"} />} />

      <Route path={`wbs/bussgeld-fahrverbot`} element={<LpFineDrivingBanPartner partnerName={"wbs"} />} />
      <Route path={`wbs/kuendigung`} element={<DismissalCheckWbsPage />} />
      <Route path={`wbs/schufa`} element={<SchufaWbsPage />} />

      <Route path={`blitzereinspruch/bussgeld-fahrverbot`} element={<LpFineDrivingBanPartner partnerName={"blitzereinspruch"} />} />
      <Route path={`partneranwalt-werden`} element={<LpExternalLawyer />} />
      <Route path={"*"} element={<HomePage />} />
    </Routes>
  );
}

import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import ButtonLoading from "../../../Button/ButtonLoading";
import feedbackFormStyle from "./feedbackFormStyle";
import ClientReceivedReaction from "./Modules/ClientReceivedReaction";
import ClientAgrees from "./Modules/ClientAgrees";
import Recommendation from "./Modules/Recommendation";
import LawsuitConsequences from "./Modules/LawsuitConsequences";
import FinalClaimStatusClient from "./Modules/FinalClaimStatusClient";
import FinalProcessFeedbackClient from "./Modules/FinalProcessFeedbackClient";
import { useForm } from "../../../../provider/Form/FormProvider";
import AdditionalFeedbackMessage from "./Modules/AdditionalFeedbackMessage";

const useStyles = makeStyles(feedbackFormStyle);
const FeedbackForm = ({ product, isLoading }) => {
  const classes = useStyles();
  const { handleSubmit, values } = useForm();
  const [hasClaimStatusClient, setHasClaimStatusClient] = useState(false);

  const { extrajudicialReaction } = product;
  const { clientAgrees, reactionReceived } = values;
  useEffect(() => {
    setHasClaimStatusClient(
      !(
        (extrajudicialReaction === "fullyAgreed" && clientAgrees !== "no") ||
        (extrajudicialReaction === "noReaction" && reactionReceived !== "yes")
      )
    );
  }, [setHasClaimStatusClient, extrajudicialReaction, clientAgrees, reactionReceived]);

  return (
    <Paper className={classes.paper} elevation={4}>
      <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
        <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
          <ClientReceivedReaction product={product} />
          <ClientAgrees product={product} />
          <FinalClaimStatusClient product={product} hasClaimStatusClient={hasClaimStatusClient} />
          <Recommendation product={product} hasClaimStatusClient={hasClaimStatusClient} />
          <FinalProcessFeedbackClient product={product} hasClaimStatusClient={hasClaimStatusClient} />
          <LawsuitConsequences product={product} />
          <AdditionalFeedbackMessage />
        </Grid>
        <ButtonLoading
          className={classes.button}
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          isLoading={isLoading}
        >
          Meine Rückmeldung übermitteln
        </ButtonLoading>
      </form>
    </Paper>
  );
};
export default FeedbackForm;

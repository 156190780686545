import lineHorizontal from "../../assets/img/teaser/contact/Linie_gestrichelt.svg";
import lineVertical from "../../assets/img/teaser/contact/Linie_gestrichelt_mobile.svg";

const tripleImageProgressionTeaserStyle = theme => ({
  stepsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      marginLeft: 40,
    },
  },
  backgroundContainer: {
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundImage: "url(" + lineVertical + ")",
      width: 360,
      height: 880,
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: "url(" + lineHorizontal + ")",
      width: 700,
      height: 260,
    },
  },
  image: {
    width: 200,
    height: "auto",
  },
  spaceBetween: {
    [theme.breakpoints.down('md')]: {
      height: 50,
    },
    [theme.breakpoints.up("md")]: {
      width: 50,
    },
  },
  step: {
    margin: "0 auto",
    width: 200,
    height: 260,
    textAlign: "center",
  },
});

export default tripleImageProgressionTeaserStyle;

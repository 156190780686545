import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  insuranceBox: {
    textAlign: "center",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "5px",
    padding: "0.5rem",
    marginTop: "1.5rem",
  },
}));

export default useStyles;

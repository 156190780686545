import homePageBackgroundDesktop from "../assets/img/background/background_homepage_desktop.svg";
import serviceworldBackgroundDesktop from "../assets/img/background/background_serviceworld_desktop.svg";
import landingpageBackgroundDesktop from "../assets/img/background/background_landingpage_desktop.svg";
import homePageBackgroundMobile from "../assets/img/background/mobile/background_homepage_mobile.svg";
import serviceworldBackgroundMobile from "../assets/img/background/mobile/background_serviceworld_mobile.svg";
import landingpageBackgroundMobile from "../assets/img/background/mobile/background_landingpage_mobile.svg";

// --------------- NO FURTHER OPTIONS ALLOWED (ONLY 3 PER DEVICE) --------------------
const backgroundStyling = {
  desktop: {
    homepage: {
      backgroundImage: `url(${homePageBackgroundDesktop})`,
      backgroundPosition: "right top",
      backgroundSize: "3840px",
      backgroundRepeat: "no-repeat",
    },
    serviceworld: {
      backgroundImage: `url(${serviceworldBackgroundDesktop})`,
      backgroundPosition: "right top",
      backgroundSize: "3840px",
      backgroundRepeat: "no-repeat",
    },
    landingpage: {
      backgroundImage: `url(${landingpageBackgroundDesktop})`,
      backgroundPosition: "right top",
      backgroundSize: "3840px",
      backgroundRepeat: "no-repeat",
    },
  },
  mobile: {
    homepage: {
      backgroundImage: `url(${homePageBackgroundMobile})`,
      backgroundPosition: "right top",
      backgroundSize: "960px",
      backgroundRepeat: "no-repeat",
    },
    serviceworld: {
      backgroundImage: `url(${serviceworldBackgroundMobile})`,
      backgroundPosition: "right top",
      backgroundSize: "960px",
      backgroundRepeat: "no-repeat",
    },
    landingpage: {
      backgroundImage: `url(${landingpageBackgroundMobile})`,
      backgroundPosition: "right top",
      backgroundSize: "960px",
      backgroundRepeat: "no-repeat",
    },
  },
};

export const navigationLinks = {
  areas: [
    {
      label: "Arbeitsrecht",
      to: "/arbeitsrecht",
    },
    {
      label: "Familienrecht",
      to: "/familienrecht",
    },
    {
      label: "Verkehrsrecht",
      to: "/verkehrsrecht",
    },
    {
      label: "Vertragsrecht",
      to: "/vertragsrecht",
    },
  ],
  guidebooks: [
    {
      label: "Arbeitsrecht",
      to: "/ratgeber/arbeitsrecht",
    },
    {
      label: "Familienrecht",
      to: "/ratgeber/familienrecht",
    },
    {
      label: "Verkehrsrecht",
      to: "/ratgeber/verkehrsrecht",
    },
  ],
  aboutUs: [
    {
      label: "Über uns",
      to: "/ueber-uns",
    },
    {
      label: "Kontakt",
      to: "/kontakt",
    },
    {
      label: "Karriere",
      to: "/jobs",
    },
    {
      label: "Partneranwalt werden",
      to: "/lp/partneranwalt-werden",
    },
  ],
};

export default backgroundStyling;

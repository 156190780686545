import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import tripleImageProgressionTeaserStyle from "./tripleImageProgressionTeaserStyle";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(tripleImageProgressionTeaserStyle);

//no map because we decided it should be exactly 3 steps
export default function TripleImageProgressionTeaser({ step1, step2, step3 }) {
  const classes = useStyles();

  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.stepsContainer}>
        <div className={classes.step}>
          <img src={step1.image} className={classes.image} alt={"Schritt 1"} />
          <Typography variant={"subtitle2"}>
            <strong>{step1.headline}</strong>
          </Typography>
          <Typography variant={"body1"}>{step1.subHeadline}</Typography>
        </div>
        <div className={classes.spaceBetween} />
        <div className={classes.step}>
          <img src={step2.image} className={classes.image} alt={"Schritt 1"} />
          <Typography variant={"subtitle2"}>
            <strong>{step2.headline}</strong>
          </Typography>
          <Typography variant={"body1"}>{step2.subHeadline}</Typography>
        </div>
        <div className={classes.spaceBetween} />
        <div className={classes.step}>
          <img src={step3.image} className={classes.image} alt={"Schritt 1"} />
          <Typography variant={"subtitle2"}>
            <strong>{step3.headline}</strong>
          </Typography>
          <Typography variant={"body1"}>{step3.subHeadline}</Typography>
        </div>
      </div>
    </div>
  );
}

TripleImageProgressionTeaser.propTypes = {
  step1: PropTypes.object.isRequired,
  step2: PropTypes.object.isRequired,
  step3: PropTypes.object.isRequired,
};

import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../FormFields/ValidatorSelectField";
import { emailDefault, isPhoneNumberDefault, requiredValidator } from "../../services/validationRules";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../FormFields/ValidatorTextField";
import ButtonLoading from "../Button/ButtonLoading";
import DialogModal from "../DialogModal/DialogModal";
import EmailChange from "../LoginForm/EmailChange";
import React from "react";
import { useForm } from "../../provider/Form/FormProvider";
import { myAccountBoxStyle } from "./myAccountBoxStyle";

export default function PersonalDataForm({ isEmailModalOpen, setIsEmailModalOpen, isLoading }) {
  const { handleSubmit, values } = useForm();
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={0} justifyContent={"center"}>
          <Grid item xs={12}>
            <ValidatorSelectField
              label={"Anrede"}
              name={"gender"}
              validators={[requiredValidator("Bitte geben Sie die Anrede an")]}
              errorMessages={[]}
              MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
            >
              <MenuItem value={"female"}>Frau</MenuItem>
              <MenuItem value={"male"}>Herr</MenuItem>
            </ValidatorSelectField>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField name="givenName" label="Vorname" validators={[requiredValidator("Bitte geben Sie Ihren Vornamen an")]} />
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField name="familyName" label="Nachname" validators={[requiredValidator("Bitte geben Sie Ihren Nachnamen an")]} />
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              name="telephone"
              label="Ihre Telefonnummer"
              autoComplete="tel"
              validators={[requiredValidator("Bitte geben Sie Ihre Telefonnummer an"), ...isPhoneNumberDefault]}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              name="email"
              label="Ihre E-Mail-Adresse"
              validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
            />
          </Grid>
          <Grid item xs={12} sx={myAccountBoxStyle.buttonSpace}>
            <ButtonLoading type="submit" fullWidth variant={"contained"} color={"primary"} isLoading={isLoading}>
              Speichern
            </ButtonLoading>
          </Grid>
        </Grid>
      </form>
      <DialogModal open={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)} title={"E-Mail-Adresse ändern"} responsive={true}>
        <EmailChange newEmail={values.email} handleClose={() => setIsEmailModalOpen(false)} />
      </DialogModal>
    </>
  );
}

import iconAttorneys from "../assets/img/jobs/anwaelte_150px.png";
import wbsIconAttorneys from "../assets/img/landingpage/wbs/anwaelte_wbs.png";
import costsSecureIcon from "../assets/img/generic/costs_secure_200px.png";
import wbsCostsSecureIcon from "../assets/img/landingpage/wbs/icon_cost_security_wbs.png";
import ReactGA from "react-ga4";
import iconAttorneyCheck from "../assets/icons/settlement/anwalt-pruefen_250px.jpg";
import iconCalculateSettlement from "../assets/icons/settlement/money_250px.png";
import iconInformation from "../assets/icons/serviceOverview/icon_guidebook_128px.png";
import iconDocument from "../assets/icons/familyLaw/icon_antrag_erstellen_150px.png";
import iconShield from "../assets/img/generic/costs_secure_200px.png";
import divorceTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Scheidung_v2.svg";
import alimonyTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Unterhalt_v2.svg";
import deathTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Tod_Erbe.svg";
import familyLawTeaser from "../assets/img/teaser/familyLaw/Familienrecht.svg";
import labourLawTeaser from "../assets/img/products/Illustration_Arbeitsrecht.svg";
import shortTimeWorkTeaser from "../assets/img/teaser/labourLaw/Arbeitsrecht_Kurzarbeit.svg";
import settlementTeaser from "../assets/img/teaser/labourLaw/Arbeitsrecht_Kuendigungsschutz.svg";
import trafficTeaser from "../assets/img/products/Illustration_Verkehrsrecht.svg";
import redLightTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Rotlichtverstoss.svg";
import distanceTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Abstandsverstoss.svg";
import speedingTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Geschwindigkeitsverstoss_v2.svg";
import trafficOtherTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Sonstiges.svg";
import contractLawTeaser from "../assets/img/products/Illustration_Vertragsrecht.svg";
import purchaseContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Kaufvertrag.svg";
import vehiclePurchaseContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Fahrzeugkauf.svg";
import serviceContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Handwerker.svg";
import membershipContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Mitgliedschaft.svg";
import subscriptionContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Abofalle.svg";
import providerContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Handy_etc.svg";
import travelContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Reise.svg";
import eventContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Veranstaltung.svg";
import otherContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Sonstiges.svg";

export const legalFieldPageConfig = {
  familyLaw: {
    headline: "Familienrecht",
    subtitle: "Ob Scheidung, Unterhalt oder alle anderen Themen im Familienrecht - unsere Anwälte sind jederzeit für Sie da!",
    headlineImage: familyLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Scheidung",
        subText: "Wir begleiten Sie professionell und persönlich bei Ihrer Scheidung.",
        image: divorceTeaser,
      },
      {
        headline: "Unterhalt",
        subText: "Ehegatten-, Kindesunterhalt oder andere Fragen rund um das Thema Unterhalt.",
        image: alimonyTeaser,
      },
      {
        headline: "Todesfall & Erbrecht",
        subText: "Beratung bei allen Angelegenheiten rund um das Thema Erbrecht.",
        image: deathTeaser,
      },
    ],
  },
  trafficLaw: {
    headline: "Verkehrsrecht",
    subtitle: "Zu schnell gefahren oder rote Ampel übersehen? Wir tun alles, damit Sie Ihren Führerschein behalten können!",
    headlineImage: trafficTeaser,
    zipperTeaserRows: [
      {
        headline: "Tempoverstoß",
        subText: "Bußgeld und Punkte wegen Geschwindkeitsverstoß vermeiden oder reduzieren.",
        image: speedingTeaser,
      },
      {
        headline: "Rotlichtverstoß",
        subText: "Professionell gegen Bußgeld oder Fahrverbot wegen überfahrenen Rotlichts vorgehen.",
        image: redLightTeaser,
      },
      {
        headline: "Abstandsverstoß",
        subText: "Zu nah aufgefahren? Wir prüfen das vermeintliche Vergehen und setzen uns für Sie ein.",
        image: distanceTeaser,
      },
      {
        headline: "Handy/ Drogen/ Parken/ Sonstiges",
        subText: "Hilfestellung bei allen sonstigen Arten von Bußgeldern im Bereich Verkehr.",
        image: trafficOtherTeaser,
      },
    ],
  },
  labourLaw: {
    headline: "Arbeitsrecht",
    subtitle: "Ärger mit dem Arbeitgeber? Wir helfen bei allen Problemen rund um Ihrem Arbeitsplatz.",
    headlineImage: labourLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Abfindung bei Kündigung",
        subText: "Wir gehen gegen Ihre Kündigung vor und Erwirken - wenn gewünscht - eine Abfindung für Sie.",
        image: settlementTeaser,
      },
      {
        headline: "Kurzarbeit",
        subText: "Informationen erhalten und Gehalt während der Kurzarbeit berechnen lassen.",
        image: shortTimeWorkTeaser,
      },
    ],
  },
  contractLaw: {
    headline: "Vertragsrecht",
    subtitle: "Ob Ärger mit dem Fitnessstudio, dem Stromanbieter oder mit anderen Verträgen - wir setzen uns für Sie ein.",
    headlineImage: contractLawTeaser,
    zipperTeaserRows: [
      {
        headline: "Kaufvertrag",
        subText: "Unterstützung bei Problemen mit Ihrem Kaufvertrag - egal ob als Käufer oder Verkäufer.",
        image: purchaseContractTeaser,
      },
      {
        headline: "Fahrzeugkauf",
        subText: "Hilfe bei allen Vertragsthemen rund um den Kauf oder Verkauf von Fahrzeugen.",
        image: vehiclePurchaseContractTeaser,
      },
      {
        headline: "Handwerker / Dienstleistung",
        subText: "Wir setzen uns für Sie ein, wenn zum Beispiel Ihr Handwerker die Leistung nicht wie vereinbart erfüllt.",
        image: serviceContractTeaser,
      },
      {
        headline: "Mitgliedschaft",
        subText: "Uneinigkeiten mit Ihren Mitgliedschaften, beispielsweise Ihrem Fitnessstudio oder Sportverein.",
        image: membershipContractTeaser,
      },
      {
        headline: "Abo / Abofalle",
        subText: "Sie sind in eine Abofalle geraten? Wir wehren die Forderung für Sie ab.",
        image: subscriptionContractTeaser,
      },
      {
        headline: "Handy / DSL / Strom / Gas-Vertrag",
        subText: "Für alle Probleme mit Versorgern, wie Mobilfunkanbietern oder Stromversorger.",
        image: providerContractTeaser,
      },
      {
        headline: "Reisevertrag",
        subText: "Reisen sollten entspannend sein, daher helfen wir bei allen Problemen rund um dem Reisevertrag.",
        image: travelContractTeaser,
      },
      {
        headline: "Veranstaltung",
        subText: "Veranstaltung ausgefallen, verschoben oder sonstige Probleme? Wir unterstützen Sie!",
        image: eventContractTeaser,
      },
      {
        headline: "Sonstiger Vertrag",
        subText: "Auch bei allen anderen Verträgen helfen wir Ihnen weiter! Eine kurze Schilderung genügt!",
        image: otherContractTeaser,
      },
    ],
  },
};

export const defaultProvenExpertTeaserCard = {
  image: "https://images.provenexpert.com/ca/70/17a7e460654ab3270025208dddab/widget_square_200_de_0.png",
  heading: "Persönlich & schnell",
  link: "https://www.provenexpert.com/legalbird/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget",
  linkType: "external",
  hideMobileButton: true,
};

export const getProvenExpertTeaserCardWithHeading = (heading) => {
  let provenExpertTeaserCard = { ...defaultProvenExpertTeaserCard };
  provenExpertTeaserCard.heading = heading;
  return provenExpertTeaserCard;
};

const handleClickGA = (actionName) => {
  ReactGA.event({
    category: "dismissal action choice",
    action: actionName,
  });
};

export const divorceTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

export const wbsTeaserCards = [
  {
    image: wbsCostsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
  {
    image: wbsIconAttorneys,
    heading: "Professionelle Beratung",
  },
  defaultProvenExpertTeaserCard,
];

export const settlementChoiceCards = [
  {
    image: iconAttorneyCheck,
    heading: "Kostenlos prüfen",
    link: "/lp/abfindung",
    onClick: () => handleClickGA("dismissal check"),
  },
  {
    image: iconCalculateSettlement,
    heading: "Abfindung berechnen",
    link: "/lp/abfindung",
    onClick: () => handleClickGA("calculate settlement"),
  },
  {
    image: iconInformation,
    heading: "Allgemein informieren",
    link: "/ratgeber/arbeitsrecht",
    onClick: () => handleClickGA("more information"),
  },
];

export const settlementTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

export const contractTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: iconDocument,
    heading: "Unkomplizierte Abwicklung",
  },
];

export const alimonyTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Unterhalt berechnen",
  },
];

export const shortTimeWorkTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Kostenlose & schnelle Hilfe"),
  {
    image: iconAttorneys,
    heading: "Persönliche Analyse",
  },
  {
    image: iconShield,
    heading: "Berechnung Kurzarbeitergeld",
  },
];

export const documentTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: iconDocument,
    heading: "Persönliches Dokument",
  },
];

export const trafficTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    image: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    image: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

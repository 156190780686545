import Grid from "@mui/material/Grid";
import ValidatorSelectField from "../../../../../../components/FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import EmailCheck from "../../../../../../components/EmailCheck/EmailCheck";
import ButtonLoading from "../../../../../../components/Button/ButtonLoading";
import React, { useEffect } from "react";
import { useForm } from "../../../../../../provider/Form/FormProvider";
import { schufaCheckinStyles } from "./schufaCheckinStyles";
import { dateFieldDefault, isPhoneNumberDefault, pastDateDefault, requiredValidator } from "../../../../../../services/validationRules";
import { FormDataTypes } from "../../../../../../types/FormDataTypes";
import { Box, Stack } from "@mui/material";
import ValidatorTextField from "../../../../../../components/FormFields/ValidatorTextField";
import ButtonTextTermsOfServicePrivacyWbs from "../LpWbsComponents/ButtonTextTermsOfServicePrivacyWbs";
import MobileProvidersAutocomplete from "../../../../../../components/Form/MobileProvidersAutoComplete/MobileProvidersAutocomplete";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { emailDefaultInformal } from "../wbsSchufaValidators";
import { useCustomer } from "../../../../../../provider/Customer/CustomerProvider";
import ValidatorDateField from "../../../../../../components/FormFields/ValidatorDateField";
import CheckInPowerOfAttorney from "./CheckInPowerOfAttorney";
import { CheckCircleOutlined } from "@mui/icons-material";

export interface SchufaCheckinFormProps {
  isLoading: boolean;
  emailErrorText: string;
  externalError: string;
  setEmailErrorText: (text: string) => void;
  signaturePadRef: any;
}

const NotInsuredMessage = () => (
  <Typography variant={"body1"} sx={schufaCheckinStyles.notInsuredMessage}>
    <InfoOutlined sx={{ height: "1.2rem", verticalAlign: "text-top" }} />
    Vielen Dank für deine Angaben! Wir können diesen Service aktuell nur für Rechtsschutzversicherte anbieten, aber wir arbeiten bereits mit Hochdruck daran,
    diesen allen Betroffenen anbieten zu können. Möchtest du informiert werden, sobald wir dir den Service anbieten können? Die Daten werden nur benutzt, um
    dich zu informieren und dir dann das Vorgehen zu erklären.
  </Typography>
);

export default function SchufaCheckinForm({ isLoading, externalError, emailErrorText, setEmailErrorText, signaturePadRef }: SchufaCheckinFormProps) {
  const { handleSubmit, values, registerValidators }: FormDataTypes = useForm();
  const { isLoggedIn } = useCustomer();
  useEffect(() => {
    if (values.legalExpenseInsurance === "false") {
      registerValidators("telephone", isPhoneNumberDefault);
    } else {
      registerValidators("telephone", [requiredValidator("Bitte gib die betroffene Mobilfunknummer an"), ...isPhoneNumberDefault]);
    }
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {values.legalExpenseInsurance === "false" ? (
            <ValidatorTextField
              label={"Deine E-Mail-Adresse"}
              name={"email"}
              validators={[requiredValidator("Bitte gib deine E-Mail-Adresse an"), emailDefaultInformal]}
              disabled={isLoggedIn}
            />
          ) : (
            <EmailCheck
              name="email"
              label="Deine E-Mail-Adresse"
              autoComplete="email"
              emailErrorText={emailErrorText}
              setEmailErrorText={setEmailErrorText}
              validators={[requiredValidator("Bitte gib deine E-Mail-Adresse an"), emailDefaultInformal]}
              disabled={isLoggedIn}
              redirectProps={{ pathname: "", search: "" }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ValidatorTextField name="telephone" autoComplete={"tel"} label="Betroffene Mobilfunknummer" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MobileProvidersAutocomplete name={"mobileProviderOrganizationId"} requiredMessage={"Bitte gib deinen aktuellen Mobilfunkanbieter an"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorSelectField
            label={"Rechtsschutzversichert?"}
            name={"legalExpenseInsurance"}
            validators={[requiredValidator("Bitte gib deinen Versicherungsstatus an")]}
          >
            <MenuItem value={"true"}>Ja</MenuItem>
            <MenuItem value={"false"}>Nein</MenuItem>
          </ValidatorSelectField>
        </Grid>
        {values.legalExpenseInsurance === "false" && (
          <Grid item xs={12}>
            <NotInsuredMessage />
          </Grid>
        )}

        {values.legalExpenseInsurance === "true" && (
          <>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"} sx={{ margin: "2rem 0 1rem" }}>
                <CheckCircleOutlined color={"primary"} />
                <Typography variant={"subtitle2"} sx={schufaCheckinStyles.heading}>
                  Vielen Dank! Für die SCHUFA-Anfrage brauchen wir nun noch folgende Daten von dir
                </Typography>
              </Stack>
              <Typography sx={schufaCheckinStyles.wbsInfo}>
                Bitte achten Sie auf eine richtige Schreibweise und darauf, dass Sie Ihre aktuelle Adresse angeben, damit die SCHUFA-Anfrage zu einem Ergebnis führt.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorSelectField label="Anrede" name={`gender`} validators={[requiredValidator("Bitte gib deine Anrede an.")]}>
                <MenuItem value={"female"}>Frau</MenuItem>
                <MenuItem value={"male"}>Herr</MenuItem>
              </ValidatorSelectField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorTextField name="givenName" label="Vorname" validators={[requiredValidator("Bitte gib deinen Vornamen an.")]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorTextField name="familyName" label="Nachname" validators={[requiredValidator("Bitte gib deinen Nachnamen an.")]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorTextField name="streetAddress" label="Straße und Hausnummer" validators={[requiredValidator("Bitte gib deine Adresse an.")]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorTextField name="postalCode" label="Postleitzahl" validators={[requiredValidator("Bitte gib deine Postleitzahl an.")]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorTextField name="addressLocality" label="Ort" validators={[requiredValidator("Bitte gib deinen Wohnort an.")]} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ValidatorDateField
                name={"birthDate"}
                label={"Geburtsdatum"}
                validators={[requiredValidator("Bitte gib deinen Geburtstag an."), dateFieldDefault, pastDateDefault]}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckInPowerOfAttorney signaturePadRef={signaturePadRef} signatureClearIsDisabled={isLoading} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4} sx={schufaCheckinStyles.buttonContainer}>
          <Box marginTop={"25px"}>
            {externalError && <Typography sx={{ textAlign: "center", margin: 1, color: "red" }}>{externalError}</Typography>}
            <ButtonLoading variant="contained" type="submit" fullWidth isLoading={isLoading} sx={schufaCheckinStyles.button}>
              {values.legalExpenseInsurance === "false" ? "Benachrichtigen lassen" : "Kostenlos prüfen"}
            </ButtonLoading>
          </Box>
          <ButtonTextTermsOfServicePrivacyWbs />
        </Grid>
      </Grid>
    </form>
  );
}
